import React from 'react';
import IconArrow from "@material-ui/icons/ArrowForwardIos"

const InboxSideNavContent = ({ children, currentTabIndex, setTabIndex, notifications, pms }) => {
    return (
        <>
            <>
                <div className={currentTabIndex === 0 ? "control_text_selected" : "control_text"} onClick={() => { setTabIndex(0) }}><p>Notifications {notifications} <IconArrow></IconArrow></p></div>
                <div className={currentTabIndex === 1 ? "control_text_selected" : "control_text"} onClick={() => { setTabIndex(1) }}><p>Messages {pms} <IconArrow></IconArrow></p></div>
                <div className={currentTabIndex === 2 ? "control_text_selected" : "control_text"} onClick={() => { setTabIndex(2) }}><p>Read<IconArrow></IconArrow></p></div></>
            <></>
            {children}
        </>
    );
};

export default InboxSideNavContent;