import React, { useEffect, useState } from 'react';
import { usePagination } from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';
import "./Paginator.css";
import { TablePagination } from '@material-ui/core';
const useStyles = makeStyles({
    ul: {
        listStyle: 'none',
        padding: 0,
        margin: 0,
        display: 'flex',
    },
});

const UsePagination = ({ pages, selectedPage, functions: { updateSelectedPage } }) => {
    const [goToPage, setGoToPage] = useState(0);
    const [isEditingStartEllipsis, setIsEditingStartEllipsis] = useState(false);
    const [isEditingEndEllipsis, setIsEditingEndEllipsis] = useState(false);
    const [startEllipsisValue, setStartEllipsisValue] = useState('');
    const [endEllipsisValue, setEndEllipsisValue] = useState('');

    const classes = useStyles();
    const { items } = usePagination({
        count: pages,
        page: selectedPage,
        showFirstButton: true,
        showLastButton: true,
        onChange: (e, i) => {
            if (i) {
                updateSelectedPage(i)
            }
        }
    });

    const handleGoToPage = () => {
        const newPage = goToPage - 1; // Adjust for zero-based indexing
        if (newPage >= 0 && newPage <= pages) {
            updateSelectedPage(newPage + 1);
        }
    };

    useEffect(() => {
        setGoToPage(selectedPage)

        if (!selectedPage || selectedPage === 0) {
            updateSelectedPage(1)
        }
    }, [selectedPage]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleEllipsisClick = (direction) => {
        if (direction === 'start') {
            setIsEditingStartEllipsis(true);
        } else if (direction === 'end') {
            setIsEditingEndEllipsis(true);
        }
    };

    const handleEllipsisBlur = (direction) => {
        if (direction === 'start') {
            setIsEditingStartEllipsis(false);
        } else if (direction === 'end') {
            setIsEditingEndEllipsis(false);
        }
    };

    const handleEllipsisPage = (direction) => {
        if (direction === 'start') {
            const newPage = parseInt(startEllipsisValue, 10);
            if (!isNaN(newPage) && newPage >= 1 && newPage <= pages) {
                updateSelectedPage(newPage);
            }
            setIsEditingStartEllipsis(false);
        } else if (direction === 'end') {
            const newPage = parseInt(endEllipsisValue, 10);
            if (!isNaN(newPage) && newPage >= 1 && newPage <= pages) {
                updateSelectedPage(newPage);
            }
            setIsEditingEndEllipsis(false);
        }
    };


    return (
        <div className="paginator">
            <div className="navigator">
                <nav>
                    <ul className={classes.ul}>
                        {items.map(({ page, type, selected, ...item }, index) => {
                            let children = null;

                            if (type === 'start-ellipsis') {
                                if (isEditingStartEllipsis) {
                                    children = (
                                        <div className='ellipsis-input'>
                                            <input
                                                className="hidden-input"
                                                type="text"
                                                value={startEllipsisValue}
                                                onChange={(e) => setStartEllipsisValue(e.target.value)}
                                                onBlur={() => handleEllipsisBlur('start')}
                                                onKeyDown={(e) => e.key === "Enter" ? handleEllipsisPage('start') : null}
                                                autoFocus
                                            />
                                        </div>
                                    );
                                } else {
                                    children = (
                                        <button type="button" style={{ fontWeight: selected ? '900' : undefined, color: selected ? "white" : undefined }}
                                            {...item}
                                            onClick={() => handleEllipsisClick('start')}>
                                            ...
                                        </button>
                                    );
                                }
                            } else if (type === 'end-ellipsis') {
                                if (isEditingEndEllipsis) {
                                    children = (
                                        <div className='ellipsis-input'>
                                            <input
                                                className="hidden-input"
                                                type="text"
                                                value={endEllipsisValue}
                                                onChange={(e) => setEndEllipsisValue(e.target.value)}
                                                onBlur={() => handleEllipsisBlur('end')}
                                                onKeyDown={(e) => e.key === "Enter" ? handleEllipsisPage('end') : null}
                                                autoFocus
                                            />
                                        </div>
                                    );
                                } else {
                                    children = (
                                        <button type="button" style={{ fontWeight: selected ? '900' : undefined, color: selected ? "white" : undefined }}
                                            {...item}
                                            onClick={() => handleEllipsisClick('end')}>
                                            ...
                                        </button>
                                    );
                                }
                            } else if (type === 'page') {
                                children = (
                                    <button type="button" style={{ fontWeight: selected ? '900' : undefined, color: selected ? "white" : undefined }} {...item} >
                                        {page}
                                    </button>
                                );
                            } else {
                                children = (
                                    <button type="button" {...item}>
                                        {type === "next" ? ">" : type === "previous" ? "<" : type === "first" ? "First" : type === "last" ? "Last" : type}
                                    </button>
                                );
                            }

                            return <li key={index} style={{ backgroundColor: selected ? "#7f7f7f" : null }}>{children}</li>;
                        })}
                    </ul>
                </nav>
            </div>

            <div className='tablePagination-div'>

                <TablePagination
                    component="div"
                    count={pages * 20}
                    page={selectedPage}
                    onPageChange={(e, i) => { if (i > 0) updateSelectedPage(i) }}
                    rowsPerPage={20}
                    rowsPerPageOptions={[]}
                    labelDisplayedRows={({ from, to, count }) => <span className='tablePagination-manual'
                    >
                        Page:
                        <input
                            className="hidden-input"
                            type="number"
                            label="Go to Page"
                            value={goToPage}
                            onChange={(e) => setGoToPage(e.target.value)}
                            onKeyDown={(e) => e.key === "Enter" ? handleGoToPage() : null}
                        />
                        of
                        <span style={{ margin: "auto", padding: "auto", textAlign: "center" }}>
                            {pages}
                        </span> pages
                    </span>}
                    onRowsPerPageChange={() => { console.log("changed") }}
                />
            </div>
        </div>
    );
}

export default UsePagination;
