import React, { useState } from "react";
import { useHistory } from "react-router-dom"
import FancyButton from "../Shared/FancyButton";
import LoginFieldText from "./LoginFieldText";
import { setIdToken } from "../../util/auth";
import internal_fetch from "../../util/local-api";
import isEmail from "isemail";
import { useAlert } from 'react-alert'

const LoginBox = ({ methods, updatePanelShown }) => {

  const [currentUser, updateCurrentUser] = useState("");
  const [currentPass, updateCurrentPass] = useState("");
  const [currentStatus, updateCurrentStatus] = useState("");
  const [currentEmail, updateCurrentEmail] = useState("");
  const [showResend, updateShowResend] = useState(false);
  const [showImportButton, updateShowImportButton] = useState(false);

  const history = useHistory();
  const alert = useAlert();

  const login = async (email, password) => {
    updateCurrentStatus("");
    if (!isEmail.validate(email))
      return alert.error("Invalid Email")
    if (password.length < 1)
      return alert.error("Please enter a password")

    const response = await internal_fetch.login(email, password);
    if (response && response.login) {
      if (response.login.isBanned)
        updateCurrentStatus(
          `You are banned until ${new Date(
            +response.login.user.banned_until
          ).toLocaleString()}`
        );
      else {
        setIdToken(response.login.token);
        methods.updateUser({ isLoggedIn: true, data: { ...response.login.user, unreadMessages: response.login.unreadMessages } });
        history.push("/");
      }
    }
    else if (response && response.errors === "Not verified.") {
      updateCurrentStatus("Account not verified, resend email?");
      updateCurrentEmail(currentUser);
      updateShowResend(true);
    }
    else if (response && response.errors === "Finish Import Process") {
      updateCurrentStatus("Account with this email has been imported, Send email to finish import process?")
      updateCurrentEmail(currentUser);
      updateShowImportButton(true);
      updateShowResend(true);
    }
    else if (response && response.errors) {
      updateCurrentStatus(response.errors);
    }
  };

  const resendToken = async () => {
    const response = await internal_fetch.resend_token(currentEmail);
    if (response && response.resendToken)
      updateCurrentStatus(response.resendToken);
    else if (response && response.errors)
      updateCurrentStatus(response.errors)
  };

  const sendImportEmail = async () => {
    const response = await internal_fetch.send_import_email(currentEmail);
    if (response && response.sendImportEmail)
      updateCurrentStatus(response.sendImportEmail);
    else if (response && response.errors)
      updateCurrentStatus(response.errors)
  };

  return (
    <>
      <div className="login_form">

        <LoginFieldText placeholder="email" currentValue={currentUser} onChangeValue={updateCurrentUser} type="text" />

        <LoginFieldText placeholder="password" currentValue={currentPass} onChangeValue={updateCurrentPass} type="password" />

        <div className="login_button_text" onClick={() => updatePanelShown(2)}>
          Forgot your password?
        </div>
        <FancyButton className="login_button"
          onClick={() => login(currentUser, currentPass)}
          text="Login"
        />
        <div className="login_button_text"
          onClick={() => updatePanelShown(1)}
        >
          Need an account?
        </div>
        {currentStatus ? <div className="status">{currentStatus}</div> : null}
        {showResend ? (
        <>
            <LoginFieldText placeholder="email" style={{ width: "100%" }}
              currentValue={currentEmail}
              onChangeValue={updateCurrentEmail} type="text" />

            {showImportButton ?
              <FancyButton className="login_button"
                onClick={sendImportEmail}
                text="Send"
              /> :
              <FancyButton className="login_button"
                onClick={resendToken}
                text="Resend" />}
        </>
      ) : null}
      </div>

    </>
  );
};

export default LoginBox;
