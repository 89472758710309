import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import FancyButton from "../Shared/FancyButton";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import Draggable from 'react-draggable';
import "./CommentModal.css"
import logo_window from './../../assets/window_icon.svg'
import LogoClose from '@material-ui/icons/Close'
import PostPageRichContent from './PostPageRichContent';
import RichTextEditor from '../Shared/PostEditor/RichTextEditor';

const CommentModal = ({ modalShown, updateModal, commentPanelText, isSubmitting, autoFavourite, isFetching, functions: { postComment, updateCommentPanelText, updateCommentPanelOpen, updateAutoFavourite } }) => {

    const [previewMode, updatePreviewMode] = useState(false);


    useEffect(() => {
        updatePreviewMode(false)
    }, [modalShown]);

    return (<Modal
        ariaHideApp={false}
        isOpen={modalShown}
        onRequestClose={() => updateModal(false)}
        style={{
            content: {
                width: "100%",
                height: "100%",
                paddingBottom: "0",
                filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#969392', endColorstr='#8a8685', GradientType=1) ",
                backgroundColor: "transparent",
                position: "unset",
                border: "none",
                borderRadius: 0,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            },
            overlay: {
                zIndex: "2",
                backgroundColor: "#000000ab",
            }
        }}
        contentLabel="Example Modal"
    >

        <Draggable bounds="parent" cancel='.comment_panel_descriptor_textarea, .logo_close, .ql-editor, .comment_buttons, .post_comment_text, .comment_panel_rich_content'>
            <div className="commment_window_pop_up" >
                <div className="comment_window_pop_up_title">
                    <img className="logo_window" src={logo_window} alt="logo" />
                    <p className='title_comment_pop_up' >Your comment to this post</p>
                    <div className='logo_close_div' onClick={() => updateCommentPanelOpen(false)}>
                        <LogoClose className='logo_close' />
                    </div>
                </div>
                <div className="comment_content">
                    <div className="comment_panel">
                        <div>
                            <div className="comment_panel_descriptor_div">
                                <p className="comment_panel_descriptor_p">Please use english for all questions, answers and comments)</p>
                            </div>
                            {!previewMode ?
                                <div>
                                    <RichTextEditor
                                        placeholder='Add your comment...'
                                        postContents={commentPanelText}
                                        updatePostContents={updateCommentPanelText}
                                        windowState={{ windowWidth: 600 }} />
                                </div>
                                : <div className="post_comment_text comment_panel_rich_content"><PostPageRichContent post={{ contents: commentPanelText }} /></div>}

                            <div className="comment_bottom">
                                <div className="comment_buttons">
                                    <FancyButton
                                        onClick={!isSubmitting ? postComment : null}
                                        text="Post comment"
                                    />
                                    <FancyButton
                                        onClick={() => updatePreviewMode(!previewMode)}
                                        text="Toggle Preview"
                                    />
                                    <FancyButton
                                        onClick={() => updateCommentPanelOpen(false)}
                                        text="Cancel"
                                    />
                                </div>

                                <FormControlLabel
                                    className='comment_panel_descriptor_formcontrol'
                                    control={
                                        <Checkbox checked={autoFavourite} onChange={(e) => {
                                            updateAutoFavourite(e.target.checked)
                                        }} value={autoFavourite} disabled={isFetching} />
                                    }
                                    label="Subscribe on comment"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Draggable>
    </Modal>);
}

export default CommentModal;