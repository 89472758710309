import React, { useState, useEffect } from "react";
import AdminUsers from "./OldSite/AdminUsers";
import AdminUsernames from "./OldSite/AdminUsernames";
import AdminStatuses from "./OldSite/AdminStatuses";
import AdminVersionNumbers from "./OldSite/AdminVersionNumbers";
import AdminFlags from "./OldSite/AdminFlags";
import AdminAudits from "./OldSite/AdminAudits";
import AdminAutoTag from "./OldSite/AdminAutoTag";
import AdminBanner from "./OldSite/AdminBanner";
import AdminBannerNewPost from "./OldSite/AdminBannerNewPost";
import AdminCategories from "./OldSite/AdminCategories";
import AdminEmailWhitelist from "./OldSite/AdminEmailWhitelist";
import { isAdmin, isClosedBeta, isModerator } from "../../util/misc"
import "./AdminPanel.css";
import { useHistory } from "react-router-dom";
import { checkIfLoggedIn, clearIdToken } from "../../util/auth";
import internal_fetch from "../../util/local-api";
import AdminPanelControls from "./AdminPanelControls";
import { mobileRes } from "./../Nav/SideNav";
import { Tabs, Tab, TabList, TabPanel } from "react-tabs";
import AdminOtherControls from "./AdminOtherControls";
import Spinner from "../Shared/Spinner";

const AdminPanel = ({ user, updateUser, windowState, functions: { updateLeftBar } }) => {
  const history = useHistory();
  const admin_panel_tab_index = JSON.parse(sessionStorage.getItem("admin_panel_tab_index"))
  const localIndex = (!isClosedBeta() && admin_panel_tab_index === 0 ? 1 : admin_panel_tab_index);
  const [currentTabIndex, setTabIndex] = useState(localIndex ? localIndex : (!isClosedBeta() ? 1 : 0));
  const [didLoad, updateLoad] = useState(false);
  const [oldSite, setOldSite] = useState(false);

  useEffect(() => {
    sessionStorage.setItem("admin_panel_tab_index", JSON.stringify(currentTabIndex))
  }, [currentTabIndex])

  //On Mount
  useEffect(() => {
    updateLeftBar(true)
    setOldSite(false)
    const menuPopper = document.querySelector('.menu_popper');
    if (menuPopper) {
      menuPopper.style.display = 'unset'; // or any other style you want
    }
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  //On Unmount
  useEffect(() => () => {
    updateLeftBar(false)
    setOldSite(true)
    const menuPopper = document.querySelector('.menu_popper');
    if (menuPopper) {
      menuPopper.style.display = 'none'; // or any other style you want
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!windowState.windowWidth) return;
    if (windowState.windowWidth >= mobileRes) {
      updateLeftBar(true)
      setOldSite(false)
    }
    else {
      updateLeftBar(false)
      setOldSite(true)
    }
  }, [windowState]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    (async function iife() {
      if (checkIfLoggedIn()) {
        updateLoad(false);
        const response = await internal_fetch.me();
        updateLoad(true);
        if (response.me) {
          if (response.me.isBanned) clearIdToken();
          else updateUser({ isLoggedIn: true, data: { ...response.me.user, unreadMessages: response.me.unreadMessages } });
        } else clearIdToken();
      }
      else updateUser({ isLoggedIn: false, data: undefined })
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!didLoad) return;

    if (!user.data || !(isAdmin(user.data.role) || isModerator(user.data.role))) {
      history.push("/");
    }
  }, [user]);  // eslint-disable-line react-hooks/exhaustive-deps

  if (!user.data) {
    return <Spinner loading={true} />
  }

  if (user.data && isModerator(user.data.role)) {
    return <div className="admin_panel">
      <div className="admin_panel_left_bar">
        <AdminPanelControls isAdmin={false} user={user} updateUser={updateUser} currentTabIndex={currentTabIndex} setTabIndex={setTabIndex}></AdminPanelControls>
      </div>
      <div className="admin_panel_right">
        <div className="admin_panel_right_content">
          <div className="controls_big">

            <h1 className="nice_h1">ADMIN PANEL</h1>
            <div className="controls_big_content">
              <AdminFlags isAdmin={false} defaultExpanded={true} />
            </div>
          </div>
        </div>

        {oldSite ? <div className="controls_small">
          <h1 className="nice_h1">ADMIN PANEL</h1>
          <AdminFlags defaultExpanded={false} />
        </div> : <></>}
      </div>
    </div>
  }

  return (
    <div className="admin_panel">
      <div className="admin_panel_left_bar">
        <AdminPanelControls isAdmin={true} user={user} updateUser={updateUser} currentTabIndex={currentTabIndex} setTabIndex={setTabIndex}></AdminPanelControls>
      </div>
      <div className="admin_panel_right">
        <div className="admin_panel_right_content">
          <div className="controls_big">

            <h1 className="nice_h1">ADMIN PANEL</h1>
            <div className="controls_big_content">
              <Tabs selectedIndex={currentTabIndex} onSelect={(tabIndex) => { setTabIndex(tabIndex) }}>
              <TabList style={{ display: "none" }}> {/* This is set only by requirement */}
                  {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index) => { return <Tab key={index}>Tab {index}</Tab> })}
              </TabList>
              <TabPanel>
                  {currentTabIndex === 0 ? isClosedBeta() ? <AdminEmailWhitelist defaultExpanded={true} /> : <></> : <></>}
              </TabPanel>
              <TabPanel>
                  {currentTabIndex === 1 ? <AdminUsers defaultExpanded={true} /> : <></>}
              </TabPanel>
              <TabPanel>
                  {currentTabIndex === 2 ? <AdminUsernames defaultExpanded={true} /> : <></>}
              </TabPanel>
              <TabPanel>
                  {currentTabIndex === 3 ? <AdminStatuses defaultExpanded={true} /> : <></>}
              </TabPanel>
              <TabPanel>
                  {currentTabIndex === 4 ? <AdminVersionNumbers defaultExpanded={true} /> : <></>}
              </TabPanel>
              <TabPanel>
                  {currentTabIndex === 5 ? <AdminAutoTag defaultExpanded={true} /> : <></>}
              </TabPanel>
              <TabPanel>
                  {currentTabIndex === 6 ? <AdminFlags isAdmin={true} defaultExpanded={true} /> : <></>}
              </TabPanel>
              <TabPanel>
                  {currentTabIndex === 7 ? <AdminAudits defaultExpanded={true} /> : <></>}
              </TabPanel>
              <TabPanel>
                  {currentTabIndex === 8 ? <div>
                    <AdminBanner defaultExpanded={true} />
                    <AdminBannerNewPost defaultExpanded={true} />
                  </div> : <></>}
              </TabPanel>
              <TabPanel>
                  {currentTabIndex === 9 ? <AdminCategories defaultExpanded={true} /> : <></>}
              </TabPanel>
                <TabPanel>
                  {currentTabIndex === 10 ? <AdminOtherControls defaultExpanded={true} /> : <></>}
                </TabPanel>
            </Tabs>
            </div>
          </div>
        </div>

        {oldSite ? <div className="controls_small">
          <h1 className="nice_h1">ADMIN PANEL</h1>

          {isClosedBeta() ? <AdminEmailWhitelist defaultExpanded={false} /> : <></>}
          <AdminUsers defaultExpanded={false} />
          <AdminUsernames defaultExpanded={false} />
          <AdminStatuses defaultExpanded={false} />
          <AdminVersionNumbers defaultExpanded={false} />
          <AdminAutoTag defaultExpanded={false} />
          <AdminFlags defaultExpanded={false} />
          <AdminAudits defaultExpanded={false} />
          <AdminBanner defaultExpanded={false} />
          <AdminBannerNewPost defaultExpanded={false} />
          <AdminCategories defaultExpanded={false} />
          <AdminOtherControls defaultExpanded={false} />
        </div> : <></>}
      </div>
    </div>
  );
};

export default AdminPanel;
