import React, { useState, } from 'react';
import RichTextEditor from '../Shared/PostEditor/RichTextEditor';
import FancyButton from '../Shared/FancyButton';
import { useAlert } from 'react-alert'
import logo_window from './../../assets/window_icon.svg'
import LogoClose from '@material-ui/icons/Close'
import { truncateText } from '../../util/misc';

const MessageEditor = ({ onSubmit, defaultData, username, windowState, updateModal }) => {

    const [messageTitle, updateMessageTitle] = useState(defaultData.title || "");
    const [messageBody, updateMessageBody] = useState(defaultData.body || "");
    const alert = useAlert()

    const sendMessage = async () => {
        let alertString = ""
        if (messageTitle.trim() === "")
            alertString += ("\n need title")
        if (messageBody.trim() === "")
            alertString += "\n need body"
        if (alertString)
            return alert.error(alertString);

        onSubmit({ messageTitle, messageBody, messageRecipient: defaultData.recipient })
    }

    return (
        <div>
            <div className="comment_window_pop_up_title">
                <img className="logo_window" src={logo_window} alt="logo" />
                <p className='title_comment_pop_up' >Send a message to {truncateText(username, 30)}</p>
                <div className='logo_close_div' onClick={() => updateModal(false)}>
                    <LogoClose className='logo_close' />
                </div>
            </div>
            <div className="post_dashboard_main_modal">
                <div className='post_second_row' style={{ display: "flex", flexDirection: "row", height: "100%" }}>
                    <div className="post_main_question">
                    <div>
                            <input className="nice_textbox_post"
                                placeholder='Message Title'
                                value={messageTitle}
                                onChange={(e) => updateMessageTitle(e.target.value)}
                                type="text" maxLength={100} />
                    </div>
                </div>
                </div>

                <div className="post_main_content">
                    <RichTextEditor
                        windowState={windowState}
                        postContents={messageBody}
                        updatePostContents={updateMessageBody} />
                </div>
                <div className="submit_post_editor_modal">
                <FancyButton onClick={sendMessage} text="Send Message" />
                    <FancyButton onClick={() => updateModal(false)} text="Cancel" />


                </div>
            </div>
        </div >
    );
}

export default MessageEditor;