import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import CancelSortIcon from '@material-ui/icons/CancelRounded';
import InputBase from '@material-ui/core/InputBase';
import SaveChangesIcon from '@material-ui/icons/Save';
import CancelChangesIcon from '@material-ui/icons/Cancel';
import { makeStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';
import FirstPageIcon from '@material-ui/icons/SkipPrevious';
import LastPageIcon from '@material-ui/icons/SkipNext';
import PrevPageIcon from '@material-ui/icons/ChevronLeft';
import NextPageIcon from '@material-ui/icons/ChevronRight';
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator, searchTerm, sortEnabled) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    if (sortEnabled)
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });

    const filteredArray = searchTerm
        ? stabilizedThis.filter((row) => row[0].id.toLowerCase().includes(searchTerm.toLowerCase()))
        : stabilizedThis;

    return filteredArray.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, rowlabel, sortEnabled } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    const headCells = [
        { id: 'id', numeric: false, disablePadding: true, label: rowlabel }
    ];

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {(sortEnabled ? <>
                            <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                            </TableSortLabel>

                            {
                                orderBy === headCell.id ? (<TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={'asc'}
                                    IconComponent={CancelSortIcon}
                                    IconButton={null}
                                    onClick={createSortHandler('')}
                                > </TableSortLabel>) : null
                            }

                        </> : <>{headCell.label}</>)}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    rowlabel: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    sortEnabled: PropTypes.bool.isRequired
};

// Dark theme styles
const darkTheme = createTheme({
    palette: {
        type: 'dark',
        background: {
            default: '#333', // Dark background color
        },
    },
    overrides: {
        MuiTableRow: {
            root: {
                '&.Mui-selected, &.Mui-selected:hover': {
                    background: '#e6934433', // Custom color for selected row
                    '& > .MuiTableCell-root': {
                        color: 'white !important', // Custom text color for selected row
                    },
                },
            },
        },
        MuiCheckbox: {
            root: {
                color: 'white !important', // Custom color for checkbox
                '&:hover': {
                    background: '#ffffff33 !important', // Custom background for checkbox hover
                },
            },
        },
    }
});

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },

    highlight:
        theme.palette.type === 'light'
            ? {
                color: "yellow",
                backgroundColor: "red",
            }
            : {
                color: "white",
                backgroundColor: "#e69244ef",
            },
    title: {
        flex: '1 1 100%',
    },
    inputRoot: {
        flex: '1 1 100%',
        display: "flex",
        flexDirection: "row-reverse",
    },
    inputInput: {
        flex: '1 1 100%',
    },
    search: {
        flex: '1 1 100%',
        display: "flex",
        flexDirection: "row",
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { itemsSelected, title, onSearch, handleDelete, handleSave, showSave, setSelected } = props;
    const [searchBarVisible, setSearchBarVisible] = React.useState(false);
    const numSelected = itemsSelected.length

    const handleSearchChange = (event) => {
        onSearch(event.target.value);
    };

    const toggleSearchBar = () => {
        setSearchBarVisible(!searchBarVisible);
    };

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected} seleccionados
                </Typography>
            ) : searchBarVisible ? (<div className={classes.search}>
                    <Tooltip title="Buscar">
                        <IconButton aria-label="buscar">
                        <SearchIcon />
                    </IconButton>
                </Tooltip>
                <InputBase
                        placeholder="Buscar..."
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                        inputProps={{ 'aria-label': 'buscar' }}
                    onChange={handleSearchChange}
                />
            </div>) : (
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    {title}
                </Typography>
            )}

            {numSelected > 0 ? (
                <Tooltip title="Eliminar">
                    <IconButton aria-label="eliminar" onClick={() => {
                        setSelected([])
                        handleDelete(itemsSelected)
                    }}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                    <Tooltip title="Filtrar lista">
                        <IconButton aria-label="filtrar lista" onClick={toggleSearchBar}>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )}

            {showSave ? (
                <>
                    <Tooltip title="Save changes">
                        <IconButton aria-label="filter list" onClick={() => handleSave(true)}>
                            <SaveChangesIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Discard changes">
                        <IconButton aria-label="filter list" onClick={() => handleSave(false)}>
                            <CancelChangesIcon />
                        </IconButton>
                    </Tooltip>
                </>
            ) : null}
        </Toolbar>
    );
};


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        boxShadow: "6px 6px 4px -1px rgba(0, 0, 0, 0.4)",
        marginBottom: theme.spacing(2),
        backgroundColor: '#444', // Color de fondo oscuro del papel
    },
    table: {
        minWidth: 250,
    },
    tableHeader: {
        background: '#1f1f1f', // Color de fondo oscuro del encabezado
        color: '#ffffff', // Color del texto para el encabezado oscuro
    },
    // Anular los estilos de fila de tabla predeterminados
    tableRow: {
        '&:nth-child(even)': {
            background: '#333', // Color de fondo alternativo oscuro de la fila
        },
    },
    // Anular el color de texto predeterminado
    tableCell: {
        color: '#ffffff', // Color del texto para el tema oscuro
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

// Componente TableCell personalizado con estilos de tema oscuro
const DarkTableCell = ({ children, ...props }) => {
    const classes = useStyles();
    return (
        <TableCell className={classes.tableCell} {...props}>
            {children}
        </TableCell>
    );
};

const EnhancedTable = ({ rows, title, rowlabel, handleDelete, sortEnabled = true, updateDataHandler, saveItems, reload }) => {
    const classes = useStyles();
    const [searchTerm, setSearchTerm] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [draggingIndex, setDraggingIndex] = useState(null);
    const [draggingItem, setDraggingItem] = useState(null);
    const [showSave, setSave] = useState(false);

    const handleSearch = (value) => {
        setSearchTerm(value);
        setPage(0); // Reset page when searching
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleSave = (value) => {
        setSave(false);

        if (value) {
            saveItems();
        }
        else {
            reload();
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const handleDragStart = (event, index) => {
        setDraggingIndex(index + (page * rowsPerPage));
        setDraggingItem(rows[index + (page * rowsPerPage)]);

        const tableRows = document.querySelectorAll('.MuiTableRow-root');
        tableRows.forEach(row => {
            row.classList.remove('MuiTableRow-hover');
        });

        event.currentTarget.classList.add('dragging');
    };

    const handleDragOver = (event, index) => {
        event.preventDefault();
        if (!draggingItem || draggingIndex === null) return;

        const draggedOverItem = rows[index + (page * rowsPerPage)];
        if (draggedOverItem === draggingItem) return;

        // Crear una copia actualizada de las filas con el elemento arrastrado insertado en la nueva posición
        const newRows = [...rows];
        newRows.splice(draggingIndex, 1); // Remover el elemento arrastrado de su posición original
        newRows.splice(index + (page * rowsPerPage), 0, draggingItem);

        // Actualizar el estado con la nueva matriz de filas
        const updatedData = newRows.map(row => row.id).join('\n'); // Convertir a cadena de IDs delimitados por espacios en blanco
        updateDataHandler(updatedData);
        setDraggingIndex(index + (page * rowsPerPage)); // Actualizar el índice de arrastre
        setSave(true);
    };

    const handleDragEnd = () => {
        // Reiniciar los índices de arrastre
        setDraggingIndex(null);
        setDraggingItem(null);

        const tableRows = document.querySelectorAll('.MuiTableRow-root');
        tableRows.forEach(row => {
            row.classList.add('MuiTableRow-hover');
            row.classList.remove('dragging');
        });
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
        <ThemeProvider theme={darkTheme}>
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <EnhancedTableToolbar
                        itemsSelected={selected}
                        title={title}
                        onSearch={handleSearch}
                        handleDelete={handleDelete}
                        handleSave={handleSave}
                        showSave={showSave}
                        setSelected={setSelected}
                    />
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead
                                classes={classes}
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                                rowlabel={rowlabel}
                                sortEnabled={sortEnabled}
                            />

                            <TableBody>
                                {stableSort(rows, getComparator(order, orderBy), searchTerm, sortEnabled)
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        const isItemSelected = isSelected(row.id);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                hover
                                                onClick={(event) => handleClick(event, row.id)}
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.id}
                                                selected={isItemSelected}
                                                draggable={searchTerm === '' && orderBy === ''}
                                                onDragStart={(event) => handleDragStart(event, index)}
                                                onDragOver={(event) => handleDragOver(event, index)}
                                                onDragEnd={handleDragEnd}
                                            >
                                                <DarkTableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={isItemSelected}
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </DarkTableCell>
                                                <DarkTableCell component="th" id={labelId} scope="row" padding="none">
                                                    {row.id}
                                                </DarkTableCell>
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <DarkTableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelDisplayedRows={({ from, to, count }) => {
                            return `${page + 1} of ${Math.ceil(rows.length / rowsPerPage)} pages`;
                        }}
                        ActionsComponent={({ page }) => (
                            <div style={{ display: "flex" }}>
                                <IconButton onClick={() => setPage(0)} disabled={page === 0}>
                                    <FirstPageIcon />
                                </IconButton>
                                <IconButton onClick={() => setPage(page - 1)} disabled={page === 0}>
                                    <PrevPageIcon />
                                </IconButton>
                                <IconButton onClick={() => setPage(page + 1)} disabled={page === Math.ceil(rows.length / rowsPerPage) - 1}>
                                    <NextPageIcon />
                                </IconButton>
                                <IconButton onClick={() => setPage(Math.ceil(rows.length / rowsPerPage) - 1)} disabled={page === Math.ceil(rows.length / rowsPerPage) - 1}>
                                    <LastPageIcon />

                                </IconButton>
                            </div>
                        )}
                    />
                </Paper>
            </div>
        </ThemeProvider>
    );
};

export default EnhancedTable;
