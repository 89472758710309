import React, { useState, useEffect } from 'react';
import internal_fetch from "../../../util/local-api";
import Select from 'react-select';
import HelpIcon from '@material-ui/icons/Help';
import ToolTip from "../../Shared/ToolTip";
import Modal from 'react-modal';

const colourStyles = {
    control: styles => ({
        ...styles, backgroundColor: '#3f3e3e5c', minHeight: "0px", padding: "0px", color: "white"
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: "#3f3e3e5c",
            color: "white",
            cursor: isDisabled ? 'not-allowed' : 'default',

        };
    },
    dropdownIndicator: (styles) => {
        return {
            ...styles,
            padding: "0px",
            paddingRight: "5px"
        }
    },
    menu: (styles) => {
        return {
            ...styles,
            width: "250px",
            backgroundColor: "#3f3e3e",
            boxShadow: "6px 6px 4px -1px rgba(0, 0, 0, 0.4)"
        }
    },
    singleValue: (styles, { data }) => {
        return {
            ...styles,
            color: "white",
        };
    },
};

const VersionNumberSelector = ({ postVersionNumber, updatePostVersionNumber }) => {
    const [tooltipModalOpen, updateTooltipModal] = useState(false);
    const [allVersions, updateAllVersions] = useState([]);
    const [innerpostVersionNumber, updateInnerPostVersionNumber] = useState("");

    useEffect(() => {
        (async function iife() {
            const response = await internal_fetch.get_versions();
            if (response.versions) {
                updateAllVersions(response.versions);
            }
        })()
    }, [])

    useEffect(() => {
        if (postVersionNumber) {
            const ver = allVersions;
            const result = ver.find(e => e === postVersionNumber)
            if (result) {
                updateInnerPostVersionNumber({ label: result, value: result })
            }
        }
    }, [postVersionNumber, allVersions]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="version_number_selector">
            <Select
                placeholder="Version number..."
                value={innerpostVersionNumber}
                options={allVersions.map((version) => { return { label: version, value: version } })}
                className="basic-multi-select"
                styles={colourStyles}
                onChange={(e) => {
                    updatePostVersionNumber(e.value);
                    updateInnerPostVersionNumber(e)
                }}
            />
            <ToolTip
                Icon={HelpIcon}
                text="Click for help with versions"
                onClick={() => {
                    updateTooltipModal(true)
                }}
                color="#e69344"
            />
            <Modal
                ariaHideApp={false}
                isOpen={tooltipModalOpen}
                onRequestClose={() => updateTooltipModal(false)}
                style={{
                    content: {
                        top: "0px",
                        left: "50%",
                        right: "auto",
                        bottom: "auto",
                        marginRight: "-50%",
                        transform: "translate(-50%, 0%)",
                        width: "80%",
                        marginTop: "20px",
                        height: "80%",
                        maxHeight: "910px",
                        maxWidth: "1000px",
                        padding: "10px",
                        filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#969392', endColorstr='#8a8685', GradientType=1) ",
                        backgroundColor: "#3f3e3e",
                        position: "relative",
                        paddingBottom: "0px"
                    },
                    overlay: {
                        backgroundColor: "#000000ab",
                        zIndex: "2",
                        display: "flex",
                        alignItems: "center"
                    }
                }}
                contentLabel="Message Editor"
            >
                    <div className="tooltip_modal_contents">
                        <h1>Where's my version number?</h1>
                        <div>
                            The "Version Number" is the current number of the build you're playing, this helps us identify and keep track of issues and fix them more efficiently.
                            There's multiple ways to find what version number you're playing, here are some examples:
                            <ol>
                                <li>
                                    <div>
                                        <h1>From In-Game</h1>
                                        <p>At any point in game you can look at the top left part of the screen and you will find this message</p>
                                        <img className="version_tutorial_2" src="https://satisfactoryweb.blob.core.windows.net/public/qa_site/tutorial%202.png" alt="tooltip_version" />
                                        <p>Take note of the numbers and select them on the dropdown when making a post</p>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <h1>From the Epic Games Launcher</h1>
                                        <p>In the launcher, find the game and click on the dots ("...") next to the name, when pressed you will find this screenshot:</p>
                                        <img className="version_tutorial_3" src="https://satisfactoryweb.blob.core.windows.net/public/qa_site/tutorial%203.png" alt="tooltip_version" />
                                        <p>At the bottom, you can find the version number</p>
                                        <p>If your version number is not listed on the website dropdown, please update the game and try again.</p>
                                    </div>
                                </li>
                            </ol>
                        </div>
                </div>
            </Modal>
        </div>
    );
}

export default VersionNumberSelector;
