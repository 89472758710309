import React, { useState } from 'react';
import { useParams, Redirect } from "react-router-dom";
import { useAlert } from 'react-alert'
import internal_fetch from "../../util/local-api";
import FancyButton from '../Shared/FancyButton';
import logo from './../../assets/FICSIT.svg'; // Tell webpack this JS file uses this image
import logo_window from './../../assets/window_icon.svg'
import LoginFieldText from './LoginFieldText';

const Confirmation = () => {
    let { token } = useParams();
    const alert = useAlert()

    const [email, updateEmail] = useState("");
    const [redirect, setRedirect] = useState(false);

    const confirm = async () => {
        if (!/^\S+@\S+$/.test(email))
            return alert.error("Invalid email")
        const response = await internal_fetch.verify_account(email, token);
        if (response.tokenConfirmation) {
            alert.info(response.tokenConfirmation);
            setRedirect(true);
        }
        else if (response.errors)
            alert.error(response.errors)
    }

    if (redirect) {
        return <Redirect to="/login" />;
    }


    return <>
        <div style={{ width: "290px", paddingLeft: "0px", paddingTop: "14px", position: "absolute" }}>
            <a className={"nav_block"} key={"https://satisfactoryweb.blob.core.windows.net/public/qa_site/logo.png"} href={"/"} >
                <img src={"https://satisfactoryweb.blob.core.windows.net/public/qa_site/logo.png"} alt="nav" />
            </a>
        </div >

        <div className="confirm_screen">
            <div className="window_pop_up">
                <div className="windows_pop_up_title">
                    <img className="logo_window" src={logo_window} alt="Logo" />
                    Verify Account
                </div>
                <div className="login_content">
                    <div className="login_header">
                        <img className="login_logo" src={logo} alt="Logo" />
                    </div>
                    <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "end", alignItems: "center" }}>
                        <div className="login_form">
                            <LoginFieldText
                                placeholder="Email"
                                currentValue={email}
                                onChangeValue={updateEmail}
                                onKeyDown={(e) => e.key === "Enter" ? confirm() : null}
                                type="text" />

                            <FancyButton onClick={confirm} text="Verify" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
};


export default Confirmation;