import React, { useState, useEffect } from 'react';
import PostControls from "./PostControls";
import { mobileResValue } from './PostControls.js';
import Posts from "./Posts.js";
import Spinner from '../Shared/Spinner';
import Feed from "./Feed";
import Banner from "./Banner";
import internal_fetch from "../../util/local-api";
import "./PostList.css";
import UsePagination from './Paginator.js';

const PostList = ({ user, isAdmin, updateUser, userUpvotes, pushVote, windowState, functions: { updateLeftBar } }) => {
    const pathName = `paginator-post-list`;
    const value = sessionStorage.getItem(pathName) ? parseInt(sessionStorage.getItem(pathName)) : 1;
    const [posts, updatePosts] = useState([]);
    const [isFetching, updateIsFetching] = useState(false);
    const [totalPages, updateTotalPages] = useState(0);
    const [selectedPage, updateSelectedPage] = useState(value);

    //On Mount
    useEffect(() => { updateLeftBar(true) }, []); // eslint-disable-line react-hooks/exhaustive-deps
    //On Unmount
    useEffect(() => () => updateLeftBar(false), []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (windowState.windowWidth >= mobileResValue) {
            updateLeftBar(true)
        }
        else {
            updateLeftBar(false)
        }
    }, [windowState]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        sessionStorage.setItem(pathName, selectedPage)
    }, [selectedPage, pathName]); 

    const voteOnPost = (data, downvote) => {
        const [...tmpPosts] = posts;
        const foundPostIndex = tmpPosts.findIndex(p => p.id === data.id);
        if (downvote)
            tmpPosts[foundPostIndex].upvotes = data.upvotes - 1;
        else
            tmpPosts[foundPostIndex].upvotes = data.upvotes + 1;

        updatePosts(tmpPosts);
        pushVote(data.id, downvote);
    }

    const hidePost = async (postId) => {
        const response = await internal_fetch.toggle_hide_post(postId);
        if (response && response.toggleHidePost) {
            const [...tmpPosts] = posts;
            const foundPostIndex = tmpPosts.findIndex(p => p.id === postId);
            tmpPosts[foundPostIndex].hidden = !tmpPosts[foundPostIndex].hidden;
            updatePosts(tmpPosts);
        }
    }
    return (
        <div className="post_list">
            <PostControls user={user} isAdmin={isAdmin} updateUser={updateUser} functions={{ updatePosts, updateIsFetching, updateTotalPages, updateSelectedPage }} selectedPage={selectedPage} isFetching={isFetching} windowState={windowState} />
            <Banner />
            {

                <>
                    {totalPages === 0 || totalPages === 1 || posts.length <= 0 ? <></> : <UsePagination pages={totalPages} selectedPage={selectedPage} functions={{ updateSelectedPage }} ></UsePagination>}
                    {!isFetching ? <Posts user={user} adminOnly={false} hidePost={hidePost} posts={posts} userUpvotes={userUpvotes} voteOnPost={voteOnPost} /> : <Spinner />}
                    {totalPages === 0 || totalPages === 1 || posts.length <= 0 ? <div style={{ height: "40px" }}></div> : <UsePagination pages={totalPages} selectedPage={selectedPage} functions={{ updateSelectedPage }} ></UsePagination>}

                </>
            }
            <Feed />

        </div>
    );
}

export default PostList;