// EditableUserCell.js
import React, { useState, useEffect } from 'react';
import TableCell from '@material-ui/core/TableCell';

const EditableUserCell = ({ children, value, onEdit, startEdit, showSave, ...props }) => {
    const [editMode, setEditMode] = useState(false);
    const [editedValue, setEditedValue] = useState(value);

    const handleDoubleClick = (e) => {
        e.stopPropagation();
        setEditMode(true);
        if (showSave)
            showSave(true)
        if (startEdit)
            startEdit();
    };

    useEffect(() => {
        if (!showSave) {
            setEditedValue(value)
            setEditMode(false);
        }
    }, [showSave, value]);

    const handleChange = (event) => {
        setEditedValue(event.target.value);
        if (editedValue !== event.target.value) {
            onEdit(event.target.value);
        }
    };

    return (
        <TableCell {...props} style={{ padding: 0, paddingRight: "8px" }}>
            {editMode ? (
                <select
                    onChange={handleChange}
                    onClick={(e) => e.stopPropagation()}
                    defaultValue={value}>
                    <option value="admin">admin</option>
                    <option value="developer"  >developer</option>
                    <option value="moderator"  >moderator</option>
                    <option value="contributor"  >contributor</option>
                    <option value="user">user</option>
                </select>
            ) : (
                <div onClick={handleDoubleClick}>
                    {children ? children : value}
                </div>
            )}
        </TableCell>
    );
};

export default EditableUserCell;