import React, { useState, useEffect } from 'react';
import internal_fetch from "../../util/local-api"
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PostPageRichContent from '../PostPage/PostPageRichContent';

import "./Banner.css";

const Banner = () => {
    const [bannerCollapsed, updateBannerCollapsed] = useState(JSON.parse(localStorage.getItem("banner_minimized")));
    const [bannerData, updateBannerData] = useState("");

    useEffect(() => {
        localStorage.setItem("banner_minimized", bannerCollapsed);
    }, [bannerCollapsed])

    useEffect(() => {
        (async function iife() {
            const response = await internal_fetch.banner();
            if (response.banner) {
                updateBannerData(response.banner)
            }
        })();
    }, []);

    if (!bannerData) return null;

    return (
        <div className="banner">
            <div className="banner_top">
                {bannerCollapsed ? <ArrowDropDownIcon style={{ fontSize: "60px", zIndex: "1" }} onClick={() => updateBannerCollapsed(!bannerCollapsed)} /> : <ArrowDropUpIcon style={{ fontSize: "60px" }} onClick={() => updateBannerCollapsed(!bannerCollapsed)} />}
                <h1>Welcome Engineer!</h1>
            </div>
            <div className={`banner_contents ${bannerCollapsed ? 'collapsed' : 'expanded'}`}>
                <PostPageRichContent post={{ contents: bannerData }} />
            </div>
        </div>
    );
}

export default Banner;