import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import CreateAccountBox from "./CreateAccountBox";
import LoginBox from "./LoginBox";
import ForgotPasswordBox from "./ForgotPasswordBox";
import { checkIfLoggedIn } from "../../util/auth";
import SideNav from "./../Nav/SideNav";
import { mobileRes } from "./../Nav/SideNav";
import logo from './../../assets/FICSIT.svg'; // Tell webpack this JS file uses this image
import logo_window from './../../assets/window_icon.svg'
import "./Login.css";
import "./WindowPopUp.css"
import { isClosedBeta, setLoggedLocal } from "../../util/misc";

const Login = ({ windowState, user, updateUser, methods, functions: { updateLeftBar } }) => {
  const [panelShown, updatePanelShown] = useState(0);

  //On Mount
  useEffect(() => { updateLeftBar(true) }, []); // eslint-disable-line react-hooks/exhaustive-deps
  //On Unmount
  useEffect(() => () => updateLeftBar(false), []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (windowState.windowWidth >= mobileRes) {
      updateLeftBar(true)
    }
    else {
      updateLeftBar(false)
    }
  }, [windowState]) // eslint-disable-line react-hooks/exhaustive-deps

  const history = useHistory();
  let view;
  let text;
  if (checkIfLoggedIn()) history.push("/profile");
  if (!checkIfLoggedIn()) setLoggedLocal(false);
  if (panelShown === 0) {
    view = <LoginBox methods={methods} updatePanelShown={updatePanelShown} />;
    text = "LOGIN"
  }
  else if (panelShown === 1) {
    view = <CreateAccountBox updatePanelShown={updatePanelShown} />;
    text = "CREATE ACCOUNT"
  }
  else if (panelShown === 2) {
    view = <ForgotPasswordBox updatePanelShown={updatePanelShown} />;
    text = "FORGOT PASSWORD"
  }

  return <div className="login_screen">
    <div className="login_controls">
      <SideNav user={user} updateUser={updateUser}>
        <div className="control_text" onClick={() => updatePanelShown(1)}><p>New Account</p></div>
        <div className="control_text" onClick={() => updatePanelShown(2)}><p>Forgot Password</p></div>
        {isClosedBeta() ? <div className="control_text" onClick={() => window.open('https://www.satisfactorygame.com/#closedbeta', '_blank')}><p>Register to Beta</p></div> : <></>}
        {panelShown === 0 ? <></> : <div className="control_text" onClick={() => updatePanelShown(0)}><p>Back to login</p></div>}
      </SideNav>
    </div>
    <div className="window_pop_up">
      <div className="windows_pop_up_title">
        <img className="logo_window" src={logo_window} alt="Logo" />
        {text}
      </div>
      <div className="login_content">
        <div className="login_header">
          <img className="login_logo" src={logo} alt="Logo" />
        </div>
        <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "end", alignItems: "center" }}>
        {view}
        </div>
      </div>
    </div>
  </div>
};

export default Login;
