import React, { useState, useRef } from 'react';


const PaginationInput = ({ totalPages, onPageChange }) => {
    const [pageInput, setPageInput] = useState('');
    const inputRef = useRef(null);

    const handlePageInput = () => {
        const pageNumber = parseInt(pageInput);
        if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
            onPageChange(pageNumber - 1); // Subtract 1 to convert to zero-based index
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handlePageInput();
        }
    };

    return (
        <div>
            <input
                ref={inputRef}
                type="numeric"
                inputprops={{ min: 1, max: totalPages, step: 1 }}
                style={{
                    backgroundColor: "#333",
                    color: "#fff",
                    border: "none",
                    borderRadius: "4px",
                    padding: "8px",
                    marginRight: "8px",
                    outline: "none"
                }}
                value={pageInput}
                onChange={(e) => setPageInput(e.target.value)}
                onKeyPress={handleKeyPress} // Listen for key press events
                placeholder="Go to page"
            />
            <button
                style={{
                    backgroundColor: "#333",
                    color: "#fff",
                    border: "none",
                    borderRadius: "4px",
                    padding: "8px 16px",
                    cursor: "pointer"
                }}
                onClick={handlePageInput}
            >
                Go
            </button>
        </div>
    );
};

export default PaginationInput;
