import React, { useState, useEffect } from "react";
import ExpandableControl from "../../Shared/ExpandableControl";
import internal_fetch from "../../../util/local-api";
import FancyButton from "../../Shared/FancyButton";
import { useAlert } from 'react-alert'
import NewTextAreaWithList from "../NewTextAreaWithList";

const AdminCategories = ({ defaultExpanded }) => {
    const [fetchedCategories, updateFetchedCategories] = useState("");
    const alert = useAlert()

    useEffect(() => {
        (async function iife() {
            reload();
        })();
    }, []);

    const reload = async () => {
        updateFetchedCategories("");

        const response = await internal_fetch.get_categories();
        if (response.categories)
            updateFetchedCategories(response.categories.join("\n"));
    }

    const update_categories = async (value) => {
        let data = value && (typeof (value) === 'string') ? value.trim().split("\n") : fetchedCategories.trim().split("\n");
        const response = await internal_fetch.update_categories(data);
        if (response.updateCategories) {
            alert.info("Updated");
            sessionStorage.clear();
        }
        else if (response.errors)
            alert.error(response.errors)
    };

    const delete_categories = async (value) => {
        const response = await internal_fetch.update_categories(
            value.trim().split("\n")
        );
        if (response.updateCategories) alert.info("Updated");
        else if (response.errors)
            alert.error(response.errors)
    };

    if (defaultExpanded) {
        return <NewTextAreaWithList
            title={"Categories"}
            text={"Add new Categories"}
            rowLabel={"Categories"}
            fetchedData={fetchedCategories}
            updateData={updateFetchedCategories}
            reload={reload}
            updateDataHandler={update_categories}
            deleteDataHandler={delete_categories} />
    }


    return (
        <ExpandableControl
            className="admin_IP"
            height="275px"
            header="Categories"
            defaultExpanded={defaultExpanded}
        >
            <div className="admin_IP_details admin_section_details">
                <label>Categories (Newline Seperated):</label>
                <textarea
                    value={fetchedCategories}
                    onChange={e => updateFetchedCategories(e.target.value)}
                />
                <FancyButton
                    disabled={fetchedCategories === ""}
                    onClick={update_categories}
                    text="Save"
                />
            </div>
        </ExpandableControl>
    );
};

export default AdminCategories;
