import React from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';

const Spinner = ({ loading }) => {
    return (<div style={{ width: "40px", margin: "auto", marginTop: "40px", marginBottom: "40px" }}>
        <ScaleLoader
            size={25}
            color={'black'}
            loading={loading}
        />
    </div>);
}

export default Spinner;