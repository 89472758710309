import React, { useState, useEffect } from "react";
import ExpandableControl from "../../Shared/ExpandableControl";
import internal_fetch from "../../../util/local-api";
import FancyButton from "../../Shared/FancyButton";
import { useAlert } from 'react-alert'
import NewTextAreaWithList from "../NewTextAreaWithList";

const AdminAutoTag = ({ defaultExpanded }) => {
    const [fetchedTags, updateFetchedTags] = useState("");
    const alert = useAlert()

    useEffect(() => {
        (async function iife() {
            reload();
        })();
    }, []);

    const reload = async () => {
        updateFetchedTags("");

        const response = await internal_fetch.auto_tags();
        if (response.autoTags)
            updateFetchedTags(response.autoTags.join("\n"));
    }

    const update_auto_tags = async (value) => {
        let data = value && (typeof (value) === 'string') ? value.trim().split("\n") : fetchedTags.trim().split("\n");
        const response = await internal_fetch.update_auto_tags(data);
        if (response.updateAutoTags) alert.info("Updated");
        else if (response.errors)
            alert.error(response.errors)
    };

    const delete_auto_tags = async (value) => {
        const response = await internal_fetch.update_auto_tags(
            value.trim().split("\n")
        );
        if (response.updateAutoTags) alert.info("Updated");
        else if (response.errors)
            alert.error(response.errors)
    };

    if (defaultExpanded) {
        return <NewTextAreaWithList
            title={"Version Numbers"}
            text={"Automatically tag posts with a specific word in the post body "}
            rowLabel={"Tags"}
            fetchedData={fetchedTags}
            updateData={updateFetchedTags}
            reload={reload}
            updateDataHandler={update_auto_tags}
            deleteDataHandler={delete_auto_tags} />
    }

    return (
        <ExpandableControl
            className="admin_IP"
            height="275px"
            header="Auto Tag"
            defaultExpanded={defaultExpanded}
        >
            <div className="admin_IP_details admin_section_details">
                <label>Automatically tag posts with a specific word in the post body (Newline Seperated):</label>
                <textarea
                    value={fetchedTags}
                    onChange={e => updateFetchedTags(e.target.value)}
                />
                <FancyButton
                    disabled={fetchedTags === ""}
                    onClick={update_auto_tags}
                    text="Save"
                />
            </div>
        </ExpandableControl>
    );
};

export default AdminAutoTag;
