import React, { useState, useEffect } from 'react';
import { NavLink as Link, useHistory } from "react-router-dom";
import { clearIdToken } from "../../util/auth"
import MenuPopper from "./MenuPopper"
import Button from '@material-ui/core/Button';
import Drawer from "./Drawer";
import "./Nav.css";
import { setLoggedLocal, isLoggedLocal } from '../../util/misc';
import { isClosedBeta, getItem, item_types } from "../../util/misc"
import logoDiscord from './../../assets/discord-mark-white.svg'
import internal_fetch from './../../util/local-api'
import { setItem } from './../../util/misc'

const Nav = ({ user, updateUser, isMobile, usesLeftBar }) => {

  const [discord_link, setDiscordLink] = useState('');

  useEffect(() => {
    if (!isClosedBeta() || (!user) || (!user.isLoggedIn)) {
      return
    }

    (async function iife() {
      const discord = await internal_fetch.other_settings();
      if (discord.otherSettings) {
        setItem(item_types.discord_link, discord.otherSettings[0])
        setDiscordLink(getItem(item_types.discord_link))
      }
      else {
        setItem('')
        setDiscordLink('')
      }
    })();
  }, [user]);


  const history = useHistory();
  const navOptions = [
    {
      image: "https://satisfactoryweb.blob.core.windows.net/public/qa_site/logo.png",
      link: "/",
      exact: true
    },
    {
      text: "All Posts",
      link: "/",
      exact: true,
    },
    {
      text: "Patch notes",
      link: "/patchnotes",
      exact: true
    },
    {
      text: "Latest Fixed",
      link: "/latest",
      exact: false
    },
    {
      text: "Leaderboard",
      link: "/leaderboard",
      exact: true
    },
  ];

  const logout = () => {
    clearIdToken();
    setLoggedLocal(false)
    updateUser({ isLoggedIn: false });
    history.push("/")
  }

  if (user.isLoggedIn || isLoggedLocal()) {
    setLoggedLocal(true)
    navOptions.push({ text: "New post", link: "/post", exact: true });
  }

  if (isClosedBeta() && !user.isLoggedIn) return <></>

  if (isMobile)
    return <Drawer navs={navOptions} userPanel={<MenuPopper user={user} logout={logout} />} />
  return (
    <div style={{ display: "flex" }}>
      {usesLeftBar ? <div className='empty_space' /> : <></>}
      <div className="nav">
      <div className="nav_links">
          {navOptions.filter((e) => e.text || !usesLeftBar).map((nav, i) => !nav.realURL ? (
          <Link
            key={i}
            exact={nav.exact}
            activeClassName={nav.image ? "" : "nav_active"}
            className={"nav_block"}
            to={nav.link}
            onClick={nav.action ? nav.action : null}
          >
            {nav.image ? <img src={nav.image} alt="nav" /> : <Button >{nav.text}</Button>}
          </Link>) :
          <a className={"nav_block"} key={i} href={nav.link} >
            {nav.image ? <img src={nav.image} alt="nav" /> : <Button >{nav.text}</Button>}
          </a>
        )}
          {
            isClosedBeta() ?
          <div className='nav_block'>
                <img className='logo_discord' src={logoDiscord} alt="discord-logo" />
                <Button onClick={() => {
                  window.open(discord_link, '_blank');
                }}>Join discord</Button>
              </div> : <></>
          }
      </div>
        <div className='menu_popper'>
          <MenuPopper user={user} logout={logout} />
        </div>
    </div>
    </div>
  );
};

export default Nav;
