import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Category from "./Category";
import { pageQuery } from "../../util/misc";
import "./Categories.css"
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const { parseQuery, constructQueryURL } = pageQuery;

const Categories = ({ categories, onClick, className, noBottomMargin, flexEnd }) => {
    const history = useHistory();
    const containerRef = useRef(null);
    const [visibleCategories, setVisibleCategories] = useState([]);
    const [hiddenCategoriesCount, setHiddenCategoriesCount] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        const updateHiddenCategories = () => {
            let multiply = containerRef.current.clientWidth <= 450 ? 1 : 1.45;
            const containerWidth = containerRef.current.clientWidth * multiply;
            let totalWidth = 0;
            let hiddenCount = 0;
            let hiddenCats = [];

            const visibleCats = categories.filter(cat => {
                const tempDiv = document.createElement('div');
                tempDiv.style.display = 'inline-block';
                tempDiv.style.visibility = 'hidden';
                tempDiv.innerText = cat;
                document.body.appendChild(tempDiv);
                const width = tempDiv.clientWidth;
                document.body.removeChild(tempDiv);
                totalWidth += width;
                if (totalWidth <= containerWidth) {
                    return true;
                } else {
                    hiddenCount++;
                    hiddenCats.push(cat);
                    return false;
                }
            });

            setVisibleCategories(visibleCats);
            setHiddenCategoriesCount(hiddenCount);
        };

        // Initial update
        updateHiddenCategories();

        // Event listener for resize
        const handleResize = () => {
            updateHiddenCategories();
        };

        window.addEventListener('resize', handleResize);

        // Cleanup
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [categories]);

    const defaultOnClick = (cat) => {
        const query = parseQuery(true);
        query.category = cat;
        const url = constructQueryURL(query)
        history.push(url)
    }

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={"categories " + (className ? className : "")} ref={containerRef} style={flexEnd ? { justifyContent: "flex-end" } : { width: "100%", maxHeight: "45px", flex: "flex-start" }}>
            {
                visibleCategories.map((cat, i) => <Category style={noBottomMargin ? { marginBottom: "0px" } : {}} key={i} cat={cat} onClick={onClick ? () => onClick(cat) : () => defaultOnClick(cat)} />)
            }
            {hiddenCategoriesCount > 0 && (
                <div>
                    <Category style={noBottomMargin ? { marginBottom: "0px" } : {}} key={hiddenCategoriesCount} cat={`+${hiddenCategoriesCount} categories`} onClick={handleMenuOpen} />

                    {/* <span className="overflow-badge" onClick={handleMenuOpen}>+{hiddenCategoriesCount}</span> */}
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                        className='menu-categories'
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        {anchorEl && categories.slice(visibleCategories.length).map((cat, i) => (
                            <MenuItem key={i} onClick={() => defaultOnClick(cat)}
                                classes={{ root: 'menu-item', hover: 'menu-item-hover' }}
                            >{cat}</MenuItem>
                        ))}
                    </Menu>
                </div>
            )}
        </div>
    );
}

export default Categories;
