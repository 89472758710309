import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CancelSortIcon from '@material-ui/icons/CancelRounded';

export function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, rowlabel, sortEnabled } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    const headCells = [
        { name: 'key', numeric: false, disablePadding: true, label: rowlabel },
        { name: 'value', numeric: false, disablePadding: true, label: "Value" },
        { name: 'visibility', numeric: false, disablePadding: true, label: "Visibility" }
    ];

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.name}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.name ? order : false}
                    >
                        {(sortEnabled ? <>
                            <TableSortLabel
                            active={orderBy === headCell.name}
                            direction={orderBy === headCell.name ? order : 'asc'}
                            onClick={createSortHandler(headCell.name)}
                        >
                            {headCell.label}
                            {orderBy === headCell.name ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                            </TableSortLabel>

                            {
                                orderBy === headCell.name ? (<TableSortLabel
                                    active={orderBy === headCell.name}
                                    direction={'asc'}
                                    IconComponent={CancelSortIcon}
                                    onClick={createSortHandler('')}
                                > </TableSortLabel>) : null
                            }

                        </> : <>{headCell.label}</>)}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    rowlabel: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    sortEnabled: PropTypes.bool.isRequired
};