import React from 'react';

import './SlideSelector.css'; // Import the stylesheet

import FaArrowLeft from '@material-ui/icons/ArrowLeft'
import FaArrowRight from '@material-ui/icons/ArrowRight'


const SlideSelector = ({ isEnabled, setIsEnabled }) => {

    return (
        <div className="switch-selector">
            <FaArrowLeft style={{ opacity: isEnabled ? "1" : "0.5" }} className='arrow_btn' onClick={isEnabled ? () => setIsEnabled(false) : null} />
            <span>{isEnabled ? 'Enabled' : 'Disabled'}</span>
            <FaArrowRight style={{ opacity: isEnabled ? "0.5" : "1" }} className='arrow_btn' onClick={isEnabled ? null : () => setIsEnabled(true)} />
        </div>
    );
};

export default SlideSelector;