import React, { useState } from "react";
import ExpandableControl from "../Shared/ExpandableControl";
import internal_fetch from "../../util/local-api";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import FancyButton from "../Shared/FancyButton";
import { clearIdToken } from "../../util/auth";
import { useAlert } from 'react-alert'
import { useHistory } from "react-router-dom";
const MiscSettings = ({ privateMessagesEnabled }) => {

    const [isFetching, updateIsFetching] = useState(false);
    const [isChecked, updateIsChecked] = useState(privateMessagesEnabled);
    const alert = useAlert()
    const history = useHistory();
    const updateprivateMessagesetting = async () => {
        updateIsFetching(true);
        const response = await internal_fetch.update_public_message_setting(!isChecked)
        if (response.updateprivateMessagesSetting) {
            updateIsFetching(false);
        }
    };

    const confirmDelete = async () => {
        if (window.prompt("Please type \"really delete\" if you are sure you want to delete your Satisfactory QA site account.").toLowerCase().trim() === "really delete") {
            if (window.confirm("Are you sure you want to permanently delete your Satisfactory QA site account?")) {
                const response = await internal_fetch.delete_account()
                if (response.deleteAccount === "Deleted") {
                    clearIdToken();
                    window.alert("Deleted, logging out now.")
                    history.push("/");
                }
                else
                    alert.info(response.deleteAccount);
            }
        }
    }

    return (
        <ExpandableControl header="Miscellaneous Settings" height="130px">
            <div >
                <FormControlLabel
                    control={
                        <Checkbox checked={isChecked} onChange={(e) => {
                            updateIsChecked(e.target.checked);
                            updateprivateMessagesetting();
                        }} value={isChecked} disabled={isFetching} />
                    }
                    label="Private Messages Enabled"
                />
                <FancyButton text="Delete Account" onClick={confirmDelete} />
            </div>
        </ExpandableControl>
    );
};

export default MiscSettings;
