import React, { useEffect, useState } from 'react';
import internal_fetch from "../../util/local-api"
import Categories from "../Shared/Categories";
import Spinner from '../Shared/Spinner';
import { NavLink as Link } from "react-router-dom"
import { Tabs, Tab, TabList, TabPanel } from "react-tabs";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import "./AssignedPosts.css";
import "react-tabs/style/react-tabs.css";
import { MenuItem, Select, makeStyles } from '@material-ui/core';

const LOAD_INCREMENT = 20;
const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 120,
    },
    select: {
        color: "white",
        backgroundColor: "#3f3e3e",
        textIndent: "10px"
    }
}));

const AssignedPosts = ({ user, updateUser, windowState, functions: { updateLeftBar } }) => {

    const [currentTabIndex, setTabIndex] = useState(0);
    const [numberDisplayed, updateNumberDisplayed] = useState(LOAD_INCREMENT);
    const [allStatuses, setStatuses] = useState([]);
    const [isFetching, updateIsFetching] = useState(false);
    const [allPosts, updateAllPosts] = useState([])
    const [posts, updatePosts] = useState([])
    const classes = useStyles();

    //On Mount
    useEffect(() => {
        const menuPopper = document.querySelector('.menu_popper');
        if (menuPopper) {
            menuPopper.style.display = 'unset'; // or any other style you want
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    //On Unmount
    useEffect(() => () => {
        const menuPopper = document.querySelector('.menu_popper');
        if (menuPopper) {
            menuPopper.style.display = 'none'; // or any other style you want
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    useBottomScrollListener(() => {
        let tmpDisplayedNumber = 0;
        const comparativeList = posts[currentTabIndex];
        if (numberDisplayed === comparativeList.length) return;
        if (numberDisplayed + LOAD_INCREMENT > comparativeList.length) {
            tmpDisplayedNumber = comparativeList.length;
        } else {
            tmpDisplayedNumber = numberDisplayed + LOAD_INCREMENT;
        }
        updateNumberDisplayed(tmpDisplayedNumber);
    });

    useEffect(() => {
        (async function iife() {
            updateIsFetching(true);
            const response = await internal_fetch.assigned_posts();
            if (response.me && response.me.user) {
                const allPosts = response.me.user.assigned_posts
                updateAllPosts(allPosts);
                updateIsFetching(false);
            }
        })();
    }, []);
    useEffect(() => {
        const inProgressPosts = allPosts.filter(p => p.status === "in_progress")
        const rejectedPosts = allPosts.filter(p => p.status === "rejected")
        const solvedPosts = allPosts.filter(p => p.status === "solved")
        const fixedInternallyPosts = allPosts.filter(p => p.status === "fixed_internally")
        const otherPosts = allPosts.filter(p => !["in_progress", "fixed_internally", "rejected", "solved"].includes(p.status))
        updatePosts([inProgressPosts, rejectedPosts, solvedPosts, fixedInternallyPosts, otherPosts]);
    }, [allPosts])

    useEffect(() => {
        (async function iife() {
            const stats = await internal_fetch.get_statuses();
            if (stats.statuses)
                setStatuses(stats.statuses.map(x => Object({ key: x.split(":")[0], value: x.split(":")[1] })));
        })();
    }, [])

    const updateStatus = async (post, status) => {
        const response = await internal_fetch.edit_post(post.id, {
            status
        });
        if (response.editPost) {
            const tmpPosts = [...allPosts];
            const thePOSTINDEX = tmpPosts.findIndex(p => p.id === post.id)
            tmpPosts[thePOSTINDEX].status = status;
            updateAllPosts(tmpPosts);
        }
    }

    if (isFetching) return <Spinner loading={isFetching} />;

    if (!posts || posts.length === 0)
        return null;

    return (<div className="user_public">
        <Tabs selectedIndex={currentTabIndex} onSelect={(tabIndex) => { setTabIndex(tabIndex); if (currentTabIndex !== tabIndex) updateNumberDisplayed(LOAD_INCREMENT) }}>
            <TabList style={{ marginTop: "10px" }}>
                <Tab>In Progress</Tab>
                <Tab>Rejected</Tab>
                <Tab>Solved</Tab>
                <Tab>Fixed Internally</Tab>
                <Tab>Other</Tab>
            </TabList>
            {
                posts.map((postList, i) => {
                    const tabStatus = ["In Progress", "Rejected", "Solved", "Fixed Internally", "Other"][i];
                    return <TabPanel key={i}>
                        <h1 className="nice_h1">{tabStatus}</h1>
                        {postList.slice(0, numberDisplayed).map((post, j) => (
                            <div className="post" key={j} style={{ justifyContent: "space-between" }}>
                                <div className="post_left">
                                    <div className="upvote_box">
                                        <p>{post.upvotes} vote(s)</p>
                                        <span>{post.countComments} comments</span>
                                    </div>
                                    <div className="post_box">
                                        <Link to={`/post/${post.id}`}>
                                            <h2>{post.title}</h2>
                                        </Link>
                                        <p>Posted on <b> {new Date(+post.creation_date).toLocaleDateString()}</b></p>
                                        <Categories categories={post.categories} />
                                    </div>
                                </div>

                                <div className="post_status_controls">
                                    <Select
                                        labelId="demo-controlled-open-select-label"
                                        id="demo-controlled-open-select"
                                        value={post.status}
                                        onChange={(e) => updateStatus(post, e.target.value)}
                                        className={classes.select}
                                    >
                                        {
                                            allStatuses.map((status, i) =>
                                                <MenuItem key={i} value={status.key}>{status.value}</MenuItem>)
                                        }
                                    </Select>
                                </div>
                            </div>
                        ))}
                    </TabPanel>
                })
            }
        </Tabs>
    </div >);
}

export default AssignedPosts;
