import React, { useState, useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';



const ITEM_HEIGHT = 48;

export default function PostPageDropdownMenu({ editOption, deleteOption, favouritesOption, unfavouritesOption, flagPostOption, pinOption, unpinOption }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [menuList, updateMenuList] = useState([]);
    const open = Boolean(anchorEl);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    useEffect(() => {
        var list = [];
        if (pinOption) {
            list.push({ label: "PIN POST", function: () => { pinOption() } })
        }
        if (unpinOption) {
            list.push({ label: "UNPIN POST", function: () => { unpinOption() } })
        }
        if (editOption) {
            list.push({ label: "EDIT", function: () => { editOption() } })
        }
        if (deleteOption) {
            list.push({ label: "DELETE", function: () => { deleteOption() } })
        }
        if (favouritesOption) {
            list.push({ label: "ADD FAVOURITES", function: () => { favouritesOption() } })
        }
        if (unfavouritesOption) {
            list.push({ label: "UNFAVOURITE", function: () => { unfavouritesOption() } })
        }
        if (flagPostOption) {
            list.push({ label: "FLAG POST", function: () => { flagPostOption() } })
        }

        updateMenuList(list)
    }, [editOption, deleteOption, favouritesOption, unfavouritesOption, flagPostOption, pinOption, unpinOption])

    return (
        editOption !== undefined || deleteOption !== undefined || favouritesOption !== undefined || flagPostOption !== undefined ?
            <div>
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    size="small"
                    onClick={handleClick}
                >
                    <MoreVertIcon style={{ fontSize: 20, color: "white" }} />
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: 200,
                        },
                    }}
                >
                    {menuList.map(option => (
                        <MenuItem
                            key={option.label}
                            onClick={() => {
                                handleClose()
                                option.function()
                            }}>{option.label}</MenuItem>
                    ))}
                </Menu>
            </div>
            :
            <></>
    );
}
