import React, { useState, useEffect } from 'react';
import Spinner from '../Shared/Spinner';
import PostCommentsList from './PostCommentsList.js';
import UsePagination from '../PostList/Paginator.js';
import internal_fetch from "../../util/local-api";
import "./PostComments.css";

const PostComments = ({ user, newComment, post, postClosed, triggerUpdate, voteComment, mappedComments }) => {

    const [comments, updateComments] = useState([]);
    const [isFetching, updateIsFetching] = useState(false);
    const [totalPages, updateTotalPages] = useState(0);
    const [selectedPage, updateSelectedPage] = useState(1);

    useEffect(() => {
        if (!isFetching) {
            fetch(0, appendComments)
            updateSelectedPage(1)
        }
    }, [newComment]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!isFetching) {
            fetch(selectedPage - 1, appendComments)
        }
    }, [selectedPage]) // eslint-disable-line react-hooks/exhaustive-deps

    const fetch = async (page, cb) => {
        if (isFetching) return
        updateIsFetching(true);

        const data = await internal_fetch.specific_post_comments(post.id, page * 20, 20);
        if (data && data.allPostComments) {
            appendComments(data.allPostComments.comments);
            updateTotalPages(data.allPostComments.totalPages);
        }
        else
            appendComments([])
        updateIsFetching(false);
    }

    const appendComments = (newComments) => {
        const tmpComments = newComments;
        const uniqueArray = tmpComments.filter((thing, index) => {
            const _thing = JSON.stringify(thing);
            return index === tmpComments.findIndex(obj => {
                return JSON.stringify(obj) === _thing;
            });
        });
        updateComments(uniqueArray);
    }

    return (
        <div className="post_comments_list">
            <>
                {totalPages === 0 || totalPages === 1 || comments.length <= 0 ? <></> : <UsePagination pages={totalPages} selectedPage={selectedPage} functions={{ updateSelectedPage }} ></UsePagination>}
                {!isFetching ? <PostCommentsList comments={comments} user={user} post={post} postClosed={postClosed} triggerUpdate={triggerUpdate} voteComment={voteComment} mappedComments={mappedComments} /> : <Spinner />}
                {totalPages === 0 || totalPages === 1 || comments.length <= 0 ? <></> : <UsePagination pages={totalPages} selectedPage={selectedPage} functions={{ updateSelectedPage }} ></UsePagination>}
            </>

        </div>
    );
}

export default PostComments;