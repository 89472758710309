import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import ExpandableControl from "../Shared/ExpandableControl";
import FancyButton from "../Shared/FancyButton";
import { clearIdToken } from "../../util/auth";
import internal_fetch from "../../util/local-api";
import { useAlert } from 'react-alert'

const ChangePassword = () => {
  const [currentPassword, updateCurrentPasword] = useState("");
  const [currentNewPassword, updateNewPassword] = useState("");
  const [
    currentNewPasswordVerification,
    updateNewPasswordVerification
  ] = useState("");
  const alert = useAlert()

  const history = useHistory();
  const changePassword = async () => {
    if (currentNewPasswordVerification !== currentNewPassword)
      return alert.error("Passwords do not match.");
    if (currentNewPassword.length < 8)
      return alert.error("Password not long enough.");

    const response = await internal_fetch.change_password(
      currentPassword,
      currentNewPassword
    );
    if (response.changePassword === "Updated Password") {
      clearIdToken();
      alert.success("Changed successfully, you will be logged out now.");
      history.push("/login");
    }
    else if (response.errors){
      alert.error(response.errors)
    }
  };
  return (
    <ExpandableControl header="Change Password" height="270px">
      <div>
        <label>Current Password:</label>
        <input
          type="password"
          className="nice_textbox"
          value={currentPassword}
          onChange={e => updateCurrentPasword(e.target.value)}
        />
      </div>
      <div>
        <label>New Password:</label>
        <input
          type="password"
          className="nice_textbox"
          value={currentNewPassword}
          onChange={e => updateNewPassword(e.target.value)}
        />
      </div>
      <div>
        <label>New Password Again:</label>
        <input
          type="password"
          className="nice_textbox"
          value={currentNewPasswordVerification}
          onChange={e => updateNewPasswordVerification(e.target.value)}
        />
      </div>
      <FancyButton onClick={changePassword} text="Change password" />
    </ExpandableControl>
  );
};

export default ChangePassword;
