
import React, { useState, useEffect } from 'react'
import { NavLink as Link } from 'react-router-dom'
import {
    disableBodyScroll,
    enableBodyScroll,
    clearAllBodyScrollLocks
} from 'body-scroll-lock'
import HamburgerMenu from 'react-hamburger-menu'
import { Scrollbars } from 'react-custom-scrollbars'

import { CSSTransition } from 'react-transition-group'
import './Drawer.css'

const Drawer = ({ navs, userPanel }) => {
    const [drawerOpen, setDrawerVisible] = useState(false)

    const toggleDrawer = () => {
        const newValue = !drawerOpen
        setDrawerVisible(newValue)
        if (newValue) disableBodyScroll(document.querySelector('#drawer'))
        else enableBodyScroll(document.querySelector('#drawer'))
    }

    useEffect(() => {
        return () => {
            clearAllBodyScrollLocks()
        }
    }, [])

    const navImage = navs
        .filter(nav => nav.image)
        .map((nav, i) => (
            <Link key={i} to={nav.link} className="white_logo" onClick={toggleDrawer}>
                <img src={nav.image} alt="logo" />
            </Link>
        ))

    return (
        <div className="drawer_nav">
            <ul>
                <div className={drawerOpen ? "hamBurgerOpen" : "hamBurger"}>
                    <HamburgerMenu
                        isOpen={drawerOpen}
                        menuClicked={toggleDrawer}
                        width={35}
                        height={25}
                        strokeWidth={2}
                        rotate={0}
                        color="white"
                        borderRadius={1}
                        animationDuration={0.5}
                        style={{
                            zIndex: 1001
                        }}
                    />
                </div>
                <img src="https://satisfactoryweb.blob.core.windows.net/public/qa_site/logo.png" alt="logo" />
                {userPanel}
            </ul>

            <CSSTransition in={drawerOpen} timeout={200} classNames="my-node">
                <div id="drawer">
                    <Scrollbars
                        key={"scrollbars-drawer"}
                        autoHeight
                        autoHeightMax={window.innerHeight}
                        autoHeightMin={window.innerHeight}
                        renderView={props => (
                            <div {...props} className="modal_scroll_view" />
                        )}
                    >
                        <ul>
                            {navImage}
                            {navs
                                .filter(nav => !nav.image)
                                .map((nav, i) => (
                                    <div className="drawer-nav" key={i}>
                                        {!nav.realURL ? <Link
                                            key={`${i}-link`}
                                            exact={!!nav.exact}
                                            to={nav.link}
                                            activeClassName={nav.image ? '' : 'selectedNavLink'}
                                            className={nav.image ? 'white_logo' : ''}
                                            onClick={toggleDrawer}
                                        >
                                            {nav.image ? (
                                                <img src={nav.image} alt="logo" />
                                            ) : (
                                                    nav.text
                                                )}
                                        </Link> : <a href={nav.link} >
                                                {nav.image ? <img src={nav.image} alt="nav" /> : nav.text}
                                            </a>}
                                    </div>
                                ))}
                        </ul>
                    </Scrollbars>
                </div>
            </CSSTransition>
        </div>
    )
}

export default Drawer
