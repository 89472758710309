// EditableVisibilityCell.js
import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControlLabel } from '@material-ui/core';

const EditableVisibilityCell = ({ children, isEditable, value, onEdit, ...props }) => {

  const handleCheckboxChange = (event) => {
    onEdit(event.target.checked);
  };

  return (
    <TableCell {...props}>
      <FormControlLabel
        control={<Checkbox
          style={{ padding: "0" }}
          checked={value || false}
          onChange={handleCheckboxChange}
          onClick={(e) => e.stopPropagation()}
          inputProps={{ 'aria-labelledby': props.labelId }}
        />}
        onClick={(e) => e.stopPropagation()} // Prevent row selection on label click
      />
      {children}
    </TableCell>
  );
};

export default EditableVisibilityCell;

