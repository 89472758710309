import React, { useEffect, useState } from 'react';

import Post from "./Post";

const Posts = ({ user, posts, userUpvotes, voteOnPost, hidePost }) => {


    const [mappedUpvotes, updateMappedUpvotes] = useState({})


    useEffect(() => {
        if (Object.keys(mappedUpvotes).length === 0) {
            if (!!userUpvotes > 0) {
                updateMappedUpvotes(userUpvotes.reduce((obj, v) => {
                    obj[v] = true;
                    return obj;
                }, {}))
            }
        }
    }, [userUpvotes]) // eslint-disable-line react-hooks/exhaustive-deps

    if (!posts || posts.length < 1) {
        return <div className="no_posts_found">No posts found</div>
    }
    return (
        <div className="posts">
            {posts.map((post, i) =>
                <Post
                    key={i}
                    post={post}
                    user={user}
                    voteOnPost={(data, downvote) => {
                        const { ...upvotes } = mappedUpvotes;
                        if (downvote)
                            delete upvotes[data.id];
                        else
                            upvotes[data.id] = true;
                        updateMappedUpvotes(upvotes);
                        voteOnPost(data, downvote)
                    }}
                    userLoggedIn={!!userUpvotes}
                    userVoted={mappedUpvotes[post.id]}
                    hidePost={() => hidePost(post.id)}
                />)}
        </div>
    );
}

export default Posts;