import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const DefaultInput = styled.input`
height: 25px;
    background-color: #333;
    color: white;
    border: 1px solid #555;
    border-radius: 5px;
    outline: none;
`;

function DefaultColumnFilter({
    filterValue,
    onFilterChange,
    columnId,
}) {
    const [inputValue, setInputValue] = useState(filterValue);

    const handleChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            sessionStorage.setItem('focusedInput', columnId);
            onFilterChange(inputValue);
        }
    };


    useEffect(() => {
        setInputValue(filterValue);
    }, [filterValue]);

    return (
        <DefaultInput
            id={columnId}
            value={inputValue}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
        />
    );
}

export default DefaultColumnFilter;