import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isClosedBeta } from "./util/misc";
import { checkIfLoggedIn } from "./util/auth";

const PrivateRouter = ({ children, ...rest }) => {
  if (!checkIfLoggedIn()) return <Redirect to="/login" />;
  return <Route {...rest}  >{children}</Route>;
};

export const ClosedBetaPrivateRouter = ({ children, ...rest }) => {
  if (!checkIfLoggedIn() && isClosedBeta()) return <Redirect to="/login" />;
  return <Route {...rest}  >{children}</Route>;
};

export default PrivateRouter;
