// PostWindowPopUpContent.js
import React from 'react';
import logo_window from './../../assets/window_icon.svg'

const LeaderboardtWindowPopUpContent = ({ children }) => {
    return (
        <div className="post_window_pop_up">
            <div className="comment_window_pop_up_title">
                <img className="logo_window" src={logo_window} alt="Logo Window" />
                <p className='title_comment_pop_up'>Users by points</p>
            </div>
            <div className='leaderboard_window_main'>
                {children}
            </div>
        </div>
    );
};

export default LeaderboardtWindowPopUpContent;