import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import FancyButton from "../Shared/FancyButton";
import { clearIdToken } from "../../util/auth";
import internal_fetch from "../../util/local-api";
import { useAlert } from 'react-alert'
import Modal from 'react-modal';
import Draggable from 'react-draggable';
import logo_window from './../../assets/window_icon.svg'
import LogoClose from '@material-ui/icons/Close'
import SlideSelector from "./SlideSelector";
import Spinner from "../Shared/Spinner";


const OtherSettingsModal = ({ modalShown, updateModal, user }) => {

  const [isFetching, updateIsFetching] = useState(false);
  const [isChecked, updateIsChecked] = useState(false);
  const alert = useAlert()
  const history = useHistory();

  const updateprivateMessagesetting = async () => {
    updateIsFetching(true);
    const response = await internal_fetch.update_public_message_setting(!isChecked)
    if (response.updateprivateMessagesSetting) {
      updateIsFetching(false);
    }
  };

  useEffect(() => {
    if (user?.data?.privateMessagesEnabled) {
      updateIsChecked(true)
    }
    else {
      updateIsChecked(false)
    }
  }, [user])



  const confirmDelete = async () => {
    if (window.prompt("Please type \"really delete\" if you are sure you want to delete your Satisfactory QA site account.").toLowerCase().trim() === "really delete") {
      if (window.confirm("Are you sure you want to permanently delete your Satisfactory QA site account?")) {
        const response = await internal_fetch.delete_account()
        if (response.deleteAccount === "Deleted") {
          clearIdToken();
          window.alert("Deleted, logging out now.")
          history.push("/");
        }
        else
          alert.info(response.deleteAccount);
      }
    }
  }


  return (<Modal
    overlayClassName={"other_settings_modal"}
    ariaHideApp={false}
    isOpen={modalShown}
    onRequestClose={() => updateModal(false)}
    style={{
      content: {
        width: "100%",
        height: "100%",
        paddingBottom: "0",
        backgroundColor: "transparent",
        position: "unset",
        border: "none",
        borderRadius: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      },
      overlay: {
        zIndex: "2",
        backgroundColor: "#000000ab",
      }
    }}
    contentLabel="Example Modal"
  >

    <Draggable bounds="parent" cancel='.comment_panel_descriptor_textarea, .logo_close, .comment_buttons'>
      <div className="window_pop_up">
        <div className="overlayBackgroundStyle"></div>
        <div className="windows_pop_up_title">
          <img className="logo_window" src={logo_window} alt="Logo" />
          <p className='title_comment_pop_up' >OTHER SETTINGS</p>
          <div className='logo_close_div' onClick={() => updateModal(false)}>
            <LogoClose className='logo_close' />
          </div>
        </div>
        <div className="login_content">
          <div style={{ width: "100%", display: "flex", justifyContent: "end", flexDirection: "column" }}>
            <div>
              <p>
                <span>Private Messages</span>
                <div className="spinner_settings">
                  <Spinner loading={isFetching} />
                </div>
                <SlideSelector isEnabled={isChecked} setIsEnabled={(value) => {
                  updateIsChecked(value);
                  updateprivateMessagesetting();
                }} />
              </p>
            </div>
            <div>
              <p>
                <span>Delete Account</span>
                <FancyButton className="delete_btn" text="Delete Account" onClick={confirmDelete} />
              </p>
            </div>
          </div>
        </div>
      </div>
    </Draggable>
  </Modal >);
}

export default OtherSettingsModal;