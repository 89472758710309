import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import FancyButton from "../Shared/FancyButton";
import { clearIdToken } from "../../util/auth";
import internal_fetch from "../../util/local-api";
import { useAlert } from 'react-alert'
import Modal from 'react-modal';
import Draggable from 'react-draggable';
import logo_window from './../../assets/window_icon.svg'
import LogoClose from '@material-ui/icons/Close'
import logo from './../../assets/FICSIT.svg'; // Tell webpack this JS file uses this image
import LoginFieldText from "../Login/LoginFieldText";

const ChangePasswordModal = ({ modalShown, updateModal }) => {

  const [currentPassword, updateCurrentPasword] = useState("");
  const [currentNewPassword, updateNewPassword] = useState("");
  const [
    currentNewPasswordVerification,
    updateNewPasswordVerification
  ] = useState("");
  const alert = useAlert()

  const history = useHistory();
  const changePassword = async () => {
    if (currentNewPasswordVerification !== currentNewPassword)
      return alert.error("Passwords do not match.");
    if (currentNewPassword.length < 8)
      return alert.error("Password not long enough.");

    const response = await internal_fetch.change_password(
      currentPassword,
      currentNewPassword
    );
    if (response.changePassword === "Updated Password") {
      clearIdToken();
      alert.success("Changed successfully, you will be logged out now.");
      history.push("/login");
    }
    else if (response.errors) {
      alert.error(response.errors)
    }
  };

  return (<Modal
    overlayClassName={"change_password_modal"}
    ariaHideApp={false}
    isOpen={modalShown}
    onRequestClose={() => updateModal(false)}
    style={{
      content: {
        width: "100%",
        height: "100%",
        paddingBottom: "0",
        backgroundColor: "transparent",
        position: "unset",
        border: "none",
        borderRadius: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      },
      overlay: {
        zIndex: "2",
        backgroundColor: "#000000ab",
      }
    }}
    contentLabel="Example Modal"
  >

    <Draggable bounds="parent" cancel='.comment_panel_descriptor_textarea, .logo_close, .comment_buttons'>
      <div className="window_pop_up" style={{ zIndex: "100" }}>
        <div className="overlayBackgroundStyle"></div>
        <div className="windows_pop_up_title">
          <img className="logo_window" src={logo_window} alt="Logo" />
          <p className='title_comment_pop_up' >CHANGE PASSWORD</p>
          <div className='logo_close_div' onClick={() => updateModal(false)}>
            <LogoClose className='logo_close' />
          </div>
        </div>
        <div className="login_content">
          <div className="login_header">
            <img className="login_logo" src={logo} alt="Logo" />
          </div>
          <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "end", alignItems: "center" }}>
            <div className="login_form">

              <LoginFieldText placeholder="Password"
                currentValue={currentPassword}
                onChangeValue={updateCurrentPasword} type="password" />
              <LoginFieldText placeholder="New Password"
                currentValue={currentNewPassword}
                onChangeValue={updateNewPassword} type="password" />
              <LoginFieldText placeholder="New password Again"
                currentValue={currentNewPasswordVerification}
                onChangeValue={updateNewPasswordVerification} type="password" />

              <FancyButton
                className="login_button"
                onClick={changePassword}
                text="Change password" />
            </div>
          </div>
        </div>
      </div>
    </Draggable>
  </Modal >);
}

export default ChangePasswordModal;