import React from 'react';

import ResolvedPost from "./ResolvedPost";

const ResolvedPosts = ({ posts }) => {

    if (!posts || posts.length < 1) {
        return <div className="no_posts_found">No posts found</div>
    }
    return (
        <div className="posts">
            {posts.map((post, i) =>
                <ResolvedPost post={post} key={i} />)}
        </div>
    );
}

export default ResolvedPosts;