import decode from "jwt-decode";

const ID_TOKEN_KEY = "id_token";

function getTokenExpirationDate(encodedToken) {
  const token = decode(encodedToken);
  if (!token.exp) {
    return null;
  }
  const date = new Date(0);
  date.setUTCSeconds(token.exp);
  return date;
}

function isTokenExpired(token) {
  const expirationDate = getTokenExpirationDate(token);
  return expirationDate < new Date();
}

export function setIdToken(token) {
  localStorage.setItem(ID_TOKEN_KEY, token);
}
export function getIdToken() {
  return localStorage.getItem(ID_TOKEN_KEY);
}
export function clearIdToken() {
  localStorage.removeItem(ID_TOKEN_KEY);
}

export function checkIfLoggedIn() {
  const idToken = getIdToken();
  if (idToken && !isTokenExpired(idToken)) {
    return true;
  }
  else {
    clearIdToken();
    return false;
  }
}

export function requireAuth(nextState, replace) {
  if (!checkIfLoggedIn()) {
    replace({ pathname: "/" });
  }
}
