import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom"
import PostEditor from "../Shared/PostEditor/PostEditor";
import internal_fetch from "../../util/local-api";
import { isAdmin, isModerator } from '../../util/misc';
import { mobileRes } from "./../Nav/SideNav";
import "./CreateAPostDashboard.css";
import SideNav from "./../Nav/SideNav";
import PostPageRichContent from "../PostPage/PostPageRichContent";

const CreateAPostDashboard = ({ user, updateUser, windowState, functions: { updateLeftBar } }) => {
    const [bannerData, updateBannerData] = useState("");

    //On Mount
    useEffect(() => { updateLeftBar(true) }, []); // eslint-disable-line react-hooks/exhaustive-deps
    //On Unmount
    useEffect(() => () => updateLeftBar(false), []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (windowState.windowWidth >= mobileRes) {
            updateLeftBar(true)
        }
        else {
            updateLeftBar(false)
        }
    }, [windowState]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        (async function iife() {
            const response = await internal_fetch.banner_new_post();
            if (response.bannerNewPost) {
                updateBannerData(response.bannerNewPost)
            }
        })();

        const disclaimer = document.querySelectorAll('.post_main_body');
        disclaimer.forEach(row => {
            row.classList.remove('post_main_body');
        });
    }, []);

    const history = useHistory();
    const userIsAdmin = user.isLoggedIn && isAdmin(user.data.role)
    const userIsMod = user.isLoggedIn && isModerator(user.data.role)

    const submitPost = async ({ postTitle, postContents, postCategories, postVersionNumber, isPatchNote }) => {
        if (isPatchNote) {
            const response = await internal_fetch.create_post_admin(postTitle, postContents, postVersionNumber, postCategories);
            if (response && response.createPatchNote && response.createPatchNote.id)
                history.push("/post/" + response.createPatchNote.id)
        }
        else {
            const response = await internal_fetch.create_post(postTitle, postContents, postVersionNumber, postCategories);
            if (response && response.createPost && response.createPost.id)
                history.push("/post/" + response.createPost.id)
        }
    }

    return (
        <div className="post_screen">
            <div className="create_post_controls">
                <SideNav user={user} updateUser={updateUser}>
                    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <div className="rules_window_pop_up">
                            <div className="comment_window_pop_up_title">
                                <p className='title_comment_pop_up' >Rules</p>
                            </div>
                            <div className="post_brief">
                                <PostPageRichContent post={{ contents: bannerData }} />
                            </div>
                        </div>
                    </div>
                </SideNav>
            </div>
            <div className="create_post_body">
                <PostEditor windowState={windowState} isAdmin={userIsAdmin} isModerator={userIsMod} onSubmit={submitPost} defaultData={{}} status={false}>
                    <div className="disclaimer_full">
                        <PostPageRichContent post={{ contents: bannerData }} />
                    </div>
                    <div className="disclaimer_short"> Please use English for all questions, answers and comments. </div>
                    {/* Please use English for all questions, answers and comments. <br /> <br />
                    Keep each question-feedback item to a separate post, do not group them, if you find someone elses post with the same question, upvote theirs instead of posting a new question.
                    <strong>
                        <br /> <br /> Due to a lot of spam recently, users with a low score will have their first questions moderated before they are public
                    </strong> */}
                </PostEditor>
            </div>
        </div >
    );
}

export default CreateAPostDashboard;