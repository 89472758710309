import React, { useState } from "react";
import { useParams } from "react-router-dom";
import FancyButton from "../Shared/FancyButton";
import internal_fetch from "../../util/local-api";
import { useAlert } from 'react-alert'
import IsEmail from "isemail";

const ResetPassword = ({ importAccount }) => {
  let { token } = useParams();

  const [email, updateEmail] = useState("");
  const [password, updatePassword] = useState("");
  const [confirmPassword, updateConfirmPassword] = useState("");


  const submit = () => {
    if (!IsEmail.validate(email))
      return alert.error("Invalid email")
    if (password.length < 8 || confirmPassword.length < 8)
      return alert.error("Invalid password, must be length of 8 or greater.")
    if (confirmPassword !== password)
      return alert.error("Passwords do not match.")


    if (importAccount)
      finish_import();
    else
      reset_password();
  }

  const reset_password = async () => {

    const response = await internal_fetch.reset_password(
      email,
      token,
      password
    );
    if (response.passwordResetConfirmation)
      alert.info(response.passwordResetConfirmation);
    else if (response.errors)
      alert.error(response.errors)
  };


  const finish_import = async () => {
    const response = await internal_fetch.finish_import(
      email,
      token,
      password
    );
    if (response.finishImport)
      alert.info(response.finishImport);
    else if (response.errors)
      alert.error(response.errors)
  };

  const alert = useAlert()

  return (
    <div className="login_form">
      <div>
        <label>Token</label>
        <input
          disabled
          className="nice_textbox"
          type="text"
          readOnly
          value={token}
        />
      </div>
      <div>
        <label>Email</label>
        <input
          className="nice_textbox"
          type="email"
          value={email}
          onChange={e => updateEmail(e.target.value)}
        />
      </div>
      <div>
        <label>Password</label>
        <input
          className="nice_textbox"
          type="password"
          value={password}
          onChange={e => updatePassword(e.target.value)}
        />
      </div>
      <div>
        <label>Confirm Password</label>
        <input
          className="nice_textbox"
          type="password"
          value={confirmPassword}
          onChange={e => updateConfirmPassword(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" ? submit() : null}
        />
      </div>
      <FancyButton onClick={submit} text="Verify" />
    </div>
  );
};

export default ResetPassword;
