import React from 'react';

import ChangePassword from './ChangePassword';
import MiscSettings from './MiscSettings';


import "./UserControls.css"

const UserControls = ({ user }) => {
    return (<div className="user_controls">
        <ChangePassword />
        <MiscSettings privateMessagesEnabled={user.privateMessagesEnabled} />
    </div>);
}

export default UserControls;