import React from 'react';

import Button from '@material-ui/core/Button';


const FancyButton = ({ secondary, disabled, text, onClick, size, style }) => {
    if (secondary) return <Button style={style} size={size} variant="contained" onClick={onClick} color="secondary" disabled={disabled}>{text}</Button>
    return (<Button style={style} size={size} variant="contained" onClick={onClick} color="primary" disabled={disabled}>{text}</Button>);
}

export default FancyButton;