import React, { useState } from "react";
import ExpandableControl from "../../Shared/ExpandableControl";
import internal_fetch from "../../../util/local-api";
import ClearIcon from "@material-ui/icons/Clear";
import ToolTip from "../../Shared/ToolTip";
import FancyButton from "../../Shared/FancyButton";
import NumericInput from "react-numeric-input";
import { useAlert } from 'react-alert'
import { DatePicker } from 'react-nice-dates'
import { enGB } from 'date-fns/locale'
import 'react-nice-dates/build/style.css'
import NewUserControls from "../NewUserControls"



const AdminUsers = ({ defaultExpanded }) => {
  const [username, updateUserName] = useState("");
  const [fetchedUser, updateFetchedUser] = useState({
    hasFetched: false,
    data: {}
  });

  const alert = useAlert()

  const updateValue = v => e => {
    if (!e)
      return
    const { ...tmpUser } = fetchedUser.data;
    tmpUser[v] = e.target ? e.target.value : e;
    updateFetchedUser({ hasFetched: true, data: tmpUser });
  };

  const lookupUser = async () => {
    const user = await internal_fetch.user_by_name(username.trim());
    if (user.userByName)
      updateFetchedUser({ hasFetched: true, data: user.userByName });
    else alert.error(username + " not found")
  };

  const updateUser = async () => {
    if (window.confirm("Update user with new details?")) {
      const { id, ...rest } = fetchedUser.data;
      delete rest.privateMessagesEnabled;
      const response = await internal_fetch.update_user(id, rest);
      if (response.updateUser) {
        alert.info(response.updateUser);
      }
      else if (response.errors)
        alert.error(response.errors)
    }
  };

  const deleteUser = async () => {
    if (window.confirm("Really Delete user?")) {
      const response = await internal_fetch.delete_user(fetchedUser.data.id);
      if (response.deleteUser) {
        alert.info(response.deleteUser);
        updateUserName("");
        updateFetchedUser({ hasFetched: false, data: {} });
      }
      else if (response.errors)
        alert.error(response.errors)
    }
  };


  if (defaultExpanded) {
    return <NewUserControls />
  }

  return (
    <ExpandableControl
      className="admin_user "
      height="395px"
      header="User Controls"
      defaultExpanded={defaultExpanded}
    >
      <div>
        <label>Username:</label>
        <input
          value={username}
          disabled={fetchedUser.hasFetched}
          onChange={e => updateUserName(e.target.value)}
          type="text"
          className="nice_textbox"
        ></input>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            width: "100%",
            maxWidth: "250px"
          }}
        >
          <FancyButton
            size="medium"
            onClick={lookupUser}
            disabled={fetchedUser.hasFetched}
            text="Lookup User"
          />
          <ToolTip
            Icon={ClearIcon}
            text="Clear/Reset"
            onClick={() => {
              updateUserName("");
              updateFetchedUser({ hasFetched: false, data: { author: {} } });
            }}
          />
        </div>
      </div>
      <div className="admin_user_details admin_section_details">
        <div>
          <label>username</label>
          <input
            className="nice_textbox"
            type="text"
            disabled={!fetchedUser.hasFetched}
            value={fetchedUser.data.username || ""}
            onChange={updateValue("username")}
          />
        </div>

        <div>
          <label>email</label>
          <input
            className="nice_textbox"
            type="text"
            disabled={!fetchedUser.hasFetched}
            value={fetchedUser.data.email || ""}
            onChange={updateValue("email")}
          />
        </div>
        <div>
          <label>creation_date</label>
          <input
            className="nice_textbox"
            type="text"
            disabled={!fetchedUser.hasFetched}
            value={fetchedUser.data.creation_date || ""}
            onChange={updateValue("creation_date")}
          />
        </div>
        <div>
          <label>role</label>
          <select
            disabled={!fetchedUser.hasFetched}
            onChange={updateValue("role")}
            defaultValue={fetchedUser.role}>
            <option value="admin" selected={fetchedUser.data.role === "admin"}>admin</option>
            <option value="moderator" selected={fetchedUser.data.role === "moderator"} >moderator</option>
            <option value="contributor" selected={fetchedUser.data.role === "contributor"} >contributor</option>
            <option value="user" selected={fetchedUser.data.role === "user"} >user</option>
          </select>

        </div>
        <div>
          <label>Points</label>
          <NumericInput
            strict
            className="nice_textbox"
            type="text"
            disabled={!fetchedUser.hasFetched}
            value={fetchedUser.data.points || ""}
            onChange={updateValue("points")}
          />
        </div>
        <div>
          <label>Banned Until</label>
          {/* <input
            className="nice_textbox"
            type="text"
            disabled={!fetchedUser.hasFetched}
            value={fetchedUser.data.banned_until || ""}
            onChange={updateValue("banned_until")}
          /> */}
          <div style={{ display: "flex" }}>

            <button style={{ marginRight: "5px" }} disabled={!fetchedUser.hasFetched}
              onClick={() => updateValue("banned_until")(new Date("December 1, 42069 00:00:00").getTime().toString())}>Perma ban</button>
            <DatePicker date={fetchedUser.data.banned_until ? new Date(+fetchedUser.data.banned_until) : 0} onDateChange={(e) => {
              updateValue("banned_until")(new Date(e).getTime().toString())
            }}
              locale={enGB}
            >
              {({ inputProps, focused }) => (
                <input
                  className={'input' + (focused ? ' -focused' : '')}
                  style={{ width: "230px" }}
                  disabled={!fetchedUser.hasFetched}

                  {...inputProps}
                />
              )}
            </DatePicker>
          </div>

        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "175px",
            marginTop: "10px"
          }}
        >
          <FancyButton
            disabled={!fetchedUser.hasFetched}
            onClick={updateUser}
            text="Update"
          />
          <FancyButton
            disabled={!fetchedUser.hasFetched}
            onClick={deleteUser}
            text="Delete"
          />
        </div>
      </div>
    </ExpandableControl >
  );
};

export default AdminUsers;
