// EnhancedTableToolbar.js
import React from 'react';
import clsx from 'clsx';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import SaveChangesIcon from '@material-ui/icons/Save';
import CancelChangesIcon from '@material-ui/icons/Cancel';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import { useToolbarStyles } from './styles';
import { Add } from '@material-ui/icons';

export const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { itemsSelected, title, onSearch, handleDelete, handleSave, showSave, setSelected, handleAddRow } = props;
    const [searchBarVisible, setSearchBarVisible] = React.useState(false);
    const numSelected = itemsSelected.length;

    const handleSearchChange = (event) => {
        onSearch(event.target.value);
    };

    const toggleSearchBar = () => {
        setSearchBarVisible(!searchBarVisible);
    };

    const handleAddRowClick = () => {
        const newKey = prompt('Enter new key:');
        const newValue = prompt('Enter new value:');
        const newVisibility = window.confirm(`Confirm new status with key: ${newKey} and label: ${newValue}`);
        if (newKey && newValue && newVisibility) {
            handleAddRow(newKey, newValue);
        }
    };

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : searchBarVisible ? (
                <div className={classes.search}>
                    <Tooltip title="Search">
                        <IconButton aria-label="search">
                            <SearchIcon />
                        </IconButton>
                    </Tooltip>
                    <InputBase
                        placeholder="Search…"
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        inputProps={{ 'aria-label': 'search' }}
                        onChange={handleSearchChange}
                    />
                    </div>
                ) : (
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    {title}
                </Typography>
            )}

            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton aria-label="delete" onClick={() => {
                        setSelected([]);
                        handleDelete(itemsSelected);
                    }}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton aria-label="filter list" onClick={toggleSearchBar}>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )}

            {showSave ? (
                <>
                    <Tooltip title="Save changes">
                        <IconButton aria-label="filter list" onClick={() => handleSave(true)}>
                            <SaveChangesIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Discard changes">
                        <IconButton aria-label="filter list" onClick={() => handleSave(false)}>
                            <CancelChangesIcon />
                        </IconButton>
                    </Tooltip>
                </>
            ) : null}

            <Tooltip title="Add row">
                <IconButton aria-label="add row" onClick={handleAddRowClick}>
                    <Add />
                </IconButton>
            </Tooltip>
        </Toolbar>
    );
};
