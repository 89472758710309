import React from 'react';
import ReactTimeAgo from 'react-time-ago/commonjs/ReactTimeAgo';
import ReactHtmlParser from "react-html-parser";
import { NavLink as Link } from 'react-router-dom';

const InboxMessage = ({ message, readMessage, reply }) => {
    return (<div className="inbox_message">
        <div>
            <div className="inbox_message_header">
                <h2>{message.title.toUpperCase()}</h2>
                <p>
                    <div className="inbox_date_header">
                        <span>
                            Sent by {message.sender ? <Link to={`/user/${message.sender.username}`}>{message.sender.username} </Link> : "deleted "}
                        </span>

                        <div className="inbox_date_slash"> - </div>

                        <span><ReactTimeAgo date={+message.sent_date} /></span>
                    </div>



                    <div className="inbox_message_controls">
                        {message.notification_message ? message.notification_message_postID ? <div className="inbox_control">
                            <Link to={`/post/${message.notification_message_postID}`}>Go to post</Link>
                        </div> : null : <div className="inbox_control" onClick={reply}>
                            Reply
                        </div>}
                        {readMessage ? <div className="inbox_control" onClick={readMessage}>
                            Mark as Read
                        </div> : null}
                    </div>
                </p>
            </div>
            <hr />
            <div className="post_main_body">{ReactHtmlParser(message.body)}</div>
        </div>
    </div>);
}

export default InboxMessage;