
import queryString from "query-string"
import config from "../config";



const sorts = [
    {
        text: "Top",
        sort: "upvotes-asc"
    },
    {
        text: "New",
        sort: "creation_date-asc"
    },
    {
        text: "Hot",
        sort: "lastActivity-asc"
    },
]

const dates = [
    {
        text: "Day",
        value: 1
    },
    {
        text: "3 Days",
        value: 3
    },
    {
        text: "Week",
        value: 7
    },
    {
        text: "Month",
        value: 30
    },
    {
        text: "Year",
        value: 365
    },
    {
        text: "All Time",
        value: 9999
    },

]

const pageSizes = [20, 50, 100];
const maxPageLimit = pageSizes[pageSizes.length - 1]


const defaults = {
    sort: 0,
    page: 0,
    limit: pageSizes[0],
    search: "",
    category: "",
    date: dates[1],
    status: "",
    versions: "",
    answered: "all",
    showHidden: false
}

const defaultsAdmin = {
    sort: 0,
    page: 0,
    limit: pageSizes[0],
    search: "",
    category: "",
    date: dates[5],
    status: "",
    versions: "",
    answered: "all",
    showHidden: false
}


const defaultsLATEST = {
    sort: 0,
    page: 0,
    limit: pageSizes[0],
    search: "",
    category: "",
    date: dates[5],
    status: "",
    versions: "",
    answered: "all",
    showHidden: false
}


function constructQueryURL({ sort, page, limit, search, category, date = {}, status, versions, answered, showHidden }) {
    let queryString = "";
    queryString += appendQuery(queryString, "sort", sort);

    if (sort === 2)
        return queryString;

    queryString += appendQuery(queryString, "page", page);
    queryString += appendQuery(queryString, "limit", limit);
    queryString += appendQuery(queryString, "search", search);
    queryString += appendQuery(queryString, "category", category);
    if (date && date.text)
        queryString += appendQuery(queryString, "date", date.text);
    queryString += appendQuery(queryString, "status", status);
    queryString += appendQuery(queryString, "versions", versions);
    queryString += appendQuery(queryString, "answered", answered);
    queryString += appendQuery(queryString, "showHidden", showHidden);
    return queryString;
}

function appendQuery(queryString, name, value) {
    let tmp = "";
    if (defaults[name] === value || defaults[name].text === value)
        return ""

    if (queryString === "")
        tmp += "/search?"
    else
        tmp += "&"

    tmp += `${name}=${value}`

    return tmp
}
function parseQuery(setDefault = false) {
    const query = queryString.parse(window.location.search, { parseNumbers: true });
    if (query.sort != null && query.sort >= sorts.length)
        query.sort = 0;

    if ((query.sort === 2 || query.sort == null) && (query.date || query.page || query.category || query.version_number || query.status || query.showHidden)) {
        query.sort = 0;
    }

    if (query.date !== null && query.date !== undefined) {
        const date = dates.find(d => d.text.toLowerCase() === query.date.toString().toLowerCase())
        if (date) query.date = date;
        else query.date = dates[0];
    }
    else if (setDefault) query.date = defaults.date;
    if (query.limit != null) {
        if (query.limit > maxPageLimit)
            query.limit = maxPageLimit;
        if (query.limit < 0)
            query.limit = pageSizes[0];
    }
    else if (setDefault) query.limit = defaults.limit;
    if (query.page != null) {
        if (query.page < 0)
            query.page = 0;
    }
    else if (setDefault) query.page = defaults.page;

    if (query.category && typeof query.category !== "string")
        query.category = "";
    if (query.answered && typeof query.answered === "number")
        query.answered += "";
    return query;
}

export const debounce = function (fn, ms) {
    let timer
    return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null
            fn.apply(this, arguments)
        }, ms)
    }
}
export const truncateText = (text, limit) => {
    return text.length > limit ? text.slice(0, limit) + '...' : text;
};

export const item_types = {
    discord_link: "discord_link",
};

export function setItem(key, token) {
    localStorage.setItem(key, token);
}
export function getItem(key) {
    return localStorage.getItem(key);
}
export function clearItem(key) {
    localStorage.removeItem(key);
}

export const isAdmin = function (role) {
    return ["developer", "admin"].includes(role);
}
export const isClosedBeta = function () {
    return config.isClosedBeta;
}
export const isModerator = function (role) {
    return ["moderator"].includes(role);
}
export const isContributor = function (role) {
    return ["contributor"].includes(role);
}
export const pageQuery = {
    parseQuery,
    defaults,
    defaultsAdmin,
    defaultsLATEST,
    constructQueryURL,
    sorts,
    pageSizes,
    dates
}

export const setLoggedLocal = function (data) {
    sessionStorage.setItem("isLogged", JSON.stringify(data));
}

export const isLoggedLocal = function () {
    return JSON.parse(sessionStorage.getItem("isLogged"));
}

export const setUserNameLocal = function (data) {
    sessionStorage.setItem("userName", JSON.stringify(data));
}

export const userNameLocal = function () {
    return JSON.parse(sessionStorage.getItem("userName"));
}