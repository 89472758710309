// UserPostsPanel.js
import React, { useState, useEffect } from 'react';
import Categories from "../Shared/Categories";
import Tooltip from "../Shared/ToolTip"
import FlagIcon from '@material-ui/icons/Flag';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import UsePagination from '../PostList/Paginator';
import internal_fetch from "../../util/local-api";
import Spinner from "../Shared/Spinner";
import logo_window from './../../assets/window_icon.svg'
import UpvoteDisplay from '../Shared/UpvoteDisplay';
import { Link } from "react-router-dom";
import ArrowDropUpIcon from '@material-ui/icons/Message';
import QuestionIcon from '@material-ui/icons/Help';
import HealingIcon from '@material-ui/icons/Healing';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import DoneIcon from '@material-ui/icons/Done';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import BuildIcon from '@material-ui/icons/Build';
import LockIcon from '@material-ui/icons/Lock';
import ToolTip from "../Shared/ToolTip"
import ReactTimeAgo from 'react-time-ago'
import { truncateText } from '../../util/misc';
import { useAlert } from 'react-alert'

const statusMap = function (status) {
    switch (status) {
        case "in_progress": return BuildIcon
        case "solved": return DoneIcon
        case "rejected": return NotInterestedIcon
        case "closed": return LockIcon
        case "fixed_internally": return HealingIcon
        case "known_issue": return FolderSharedIcon
        case "open": return "div";
        default: {
            return QuestionIcon;
        }
    }

}


const UserPostsPanel = ({ username, isAdmin, flagsOnly, numberDisplayed }) => {
    const [userPosts, updateUserPosts] = useState([]);
    const [totalPages, updateTotalPages] = useState(0);
    const [selectedPage, updateSelectedPage] = useState(1);
    const [filteredPosts, updateFilteredPosts] = useState([]);
    const [isFetching, updateIsFetching] = useState(true);
    const alert = useAlert()

    useEffect(() => {
        (async function iife() {
            //checks if user login check has done or not.
            if (isAdmin === undefined) return

            updateIsFetching(true);
            const { userPostsByName } = await internal_fetch.user_posts_by_name(username, isAdmin);
            if (userPostsByName) {
                updateUserPosts(userPostsByName.posts);
            }
            updateIsFetching(false);
        })();
    }, [username, isAdmin]);


    const deletePost = async (postId) => {
        if (window.confirm("Are you sure you want to delete?")) {
            const response = await internal_fetch.delete_post(postId);
            if (response.deletePost) {
                alert.info(response.deletePost);
                const [...tmpPosts] = userPosts.filter(post => post.id !== postId);
                updateUserPosts(tmpPosts);
            }
            else if (response.errors)
                alert.error(response.errors)
        }
    }

    useEffect(() => {
        const result = userPosts.filter((p => flagsOnly ? (flagsOnly && p.flags && p.flags.length > 0) : true));
        updateFilteredPosts(result)
        updateTotalPages(Math.ceil(result.length / numberDisplayed))
    }, [userPosts, flagsOnly]); // eslint-disable-line react-hooks/exhaustive-deps 

    if (isFetching) return <Spinner loading={isFetching} />;

    if (!isFetching && filteredPosts.length === 0) return <h1 className="nice_h1">No {username} posts found</h1>;

    return (
        <div className='user_panel'>
            <div className="comment_window_pop_up_title">
                <img className="logo_window" src={logo_window} alt="Logo Window" />
                <p className='title_comment_pop_up'>Posts by {truncateText(username, 30)}</p>
            </div>
            <div className='user_public_body'>

                {totalPages === 0 || totalPages === 1 || filteredPosts.length <= 0 ? <></> : <UsePagination pages={totalPages} selectedPage={selectedPage} functions={{ updateSelectedPage }} ></UsePagination>}

                {filteredPosts.slice((selectedPage - 1) * numberDisplayed, selectedPage * numberDisplayed).map((post, i) => (
                    <div className="post" key={post.id}>
                        <div className="post_upvotes">
                            <UpvoteDisplay post={post} disabled={true} upvotes={post.upvotes} userVoted={false} action={async () => { }} />
                        </div>
                        <div className="post_contents">
                            <div className="post_title">
                                {!["open"].includes(post.status) ? <div style={{ marginTop: "-10px" }}>
                                    <ToolTip
                                        Icon={statusMap(post.status)}
                                        text={post.status}
                                        color="rgb(230, 147, 68)"
                                    />
                                </div> : null}

                                <div className='post_title_div' style={{ width: "100%" }}>
                                    <Link to={`/post/${post.id}`}>
                                        <h2>{post.title}</h2>
                                    </Link>
                                </div>
                                <div className='post_list_controls_div'>
                                    <div className="date_time_by" style={{ marginRight: "5px" }}>
                                        <ReactTimeAgo date={+post.creation_date} />
                                    </div>
                                    {isAdmin ?
                                        <div className='controls_post_title' style={{ marginLeft: "5px" }}>
                                            <Tooltip Icon={DeleteIcon} text="Delete" secondary={true} onClick={() => deletePost(post.id)} />
                                        </div> : null}

                                    {post.flags && post.flags.length > 0 ?
                                        <div className='controls_post_title'>
                                            <Tooltip
                                                Icon={FlagIcon}
                                                type="secondary"
                                                text={post.flags.map(a => a.username).join()}
                                            />
                                        </div> : null}
                                </div>
                            </div>

                            <div className="version_number" >{post.version_number}</div>
                            <div className="post_content_body"> <p>{post.contents.replace(/(<([^>]+)>)/gi, "")}</p></div>
                            <Categories categories={post.categories} />
                            <div className='comments_number' style={{ width: "0px", marginRight: "45px" }}> <span>{post.countComments}</span> <ArrowDropUpIcon style={{ fontSize: "20px", marginBottom: "-5px", marginLeft: "2px" }} /></div>

                        </div>

                    </div>
                ))}

                {totalPages === 0 || totalPages === 1 || filteredPosts.length <= 0 ? <></> : <UsePagination pages={totalPages} selectedPage={selectedPage} functions={{ updateSelectedPage }} ></UsePagination>}

            </div>
        </div>
    )
};

export default UserPostsPanel;
