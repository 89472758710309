import React from 'react';

import "./Banner.css";

const Banner = () => {
    return (<div className="banner">
        <div className="banner_contents">
            Release patch notes
        </div>

    </div>);
}

export default Banner;