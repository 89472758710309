import React, { useState, useEffect } from "react";
import ExpandableControl from "../../Shared/ExpandableControl";
import internal_fetch from "../../../util/local-api";
import FancyButton from "../../Shared/FancyButton";
import { useAlert } from 'react-alert'
import NewTextAreaWithList from "../NewTextAreaWithList";

const AdminUsernames = ({ defaultExpanded }) => {
  const [fetchedVersionNumbers, updateVersionNumbers] = useState("");
  const alert = useAlert()

  useEffect(() => {
    (async function iife() {
      reload();
    })();
  }, []);

  const reload = async () => {
    updateVersionNumbers("")

    const response = await internal_fetch.get_versions(true);
    if (response.versions)
      updateVersionNumbers(response.versions.join("\n"));
  }

  const update_version_numbers = async (value) => {
    let data = value && (typeof (value) === 'string') ? value.trim().split("\n") : fetchedVersionNumbers.trim().split("\n");
    const response = await internal_fetch.update_versions(data);
    if (response.updateVersions) alert.info("Updated");
    else if (response.errors)
      alert.error(response.errors)
  };

  const delete_version_numbers = async (value) => {
    const response = await internal_fetch.update_versions(
      value.trim().split("\n")
    );
    if (response.updateVersions) alert.info("Updated");
    else if (response.errors)
      alert.error(response.errors)
  };

  if (defaultExpanded) {
    return <NewTextAreaWithList
      sortEnabled={false}
      title={"Version Numbers"}
      text={"Version Numbers, First 5 will be shown to all users."}
      rowLabel={"Version"}
      fetchedData={fetchedVersionNumbers}
      updateData={updateVersionNumbers}
      reload={reload}
      updateDataHandler={update_version_numbers}
      deleteDataHandler={delete_version_numbers} />
  }

  return (
    <ExpandableControl
      className="admin_IP"
      height="275px"
      header="Version Numbers"
      defaultExpanded={defaultExpanded}
    >
      <div className="admin_IP_details admin_section_details">
        <label>Version Numbers, First 5 will be shown to all users. (Newline Seperated):</label>
        <textarea
          value={fetchedVersionNumbers}
          onChange={e => updateVersionNumbers(e.target.value)}
        />
        <FancyButton
          disabled={fetchedVersionNumbers === ""}
          onClick={update_version_numbers}
          text="Save"
        />
      </div>
    </ExpandableControl>
  );
};

export default AdminUsernames;
