import React, { useState, useEffect } from 'react';
import internal_fetch from "../../../util/local-api";
import HelpIcon from '@material-ui/icons/Help';
import ToolTip from "../../Shared/ToolTip";
import Modal from 'react-modal';
import Select from 'react-select';

const colourStyles = {
    control: styles => ({
        ...styles, backgroundColor: '#3f3e3e5c', minHeight: "0px", padding: "0px", color: "white"
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: "#3f3e3e5c",
            color: "white",
            cursor: isDisabled ? 'not-allowed' : 'default',

        };
    },
    dropdownIndicator: (styles) => {
        return {
            ...styles,
            padding: "0px",
            paddingRight: "5px"
        }
    },
    menu: (styles) => {
        return {
            ...styles,
            top: 'auto',
            bottom: "100%",
            width: "250px",
            backgroundColor: "#3f3e3e",
            boxShadow: "6px 6px 4px -1px rgba(0, 0, 0, 0.4)"
        }
    },
    multiValue: (styles, { data }) => {
        return {
            ...styles,
            color: "white",
            borderRadius: "15px",
            padding: "3px 10px 3px 10px",
            backgroundColor: "#e67e22",
        };
    },
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: "white",
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        ':hover': {
            color: 'white',
        },
    }),
};


const CategorySelector = ({ postCategories, updatePostCategories }) => {
    const [categorySearch, updateCategorySearch] = useState("");
    const [allCategories, updateAllCategories] = useState([]);
    const [tooltipModalOpen, updateTooltipModal] = useState(false);

    useEffect(() => {
        (async function iife() {
            const cats = await internal_fetch.get_categories();
            if (cats.categories)
                updateAllCategories(cats.categories);
        })()
    }, [])

    useEffect(() => {
        if (postCategories) {
            updateCategorySearch(postCategories.map(p => p).join(","))
        }
    }, [postCategories])

    return (
        <div className="category_selector">
            <div>
                <p style={{ margin: "5px", marginLeft: "9px", fontSize: " 12px" }}>Categories</p>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "8px"
                }}>
                    <Select
                        value={categorySearch ? categorySearch.split(",").map((category) => { return { label: category, value: category } }) : []}
                        isMulti
                        options={allCategories.map((category) => { return { label: category, value: category } })}
                        className="basic-multi-select"
                        placeholder="All Categories"
                        styles={colourStyles}
                        onChange={(options) => {
                            if (options && options.length > 0) {
                                const selectedCategories = options.map(p => p.value).join(",")
                                updateCategorySearch(selectedCategories)
                                updatePostCategories(options.map(p => p.value))
                            }
                            else {
                                updateCategorySearch("")
                                updatePostCategories([])
                            }

                        }}
                    />      

                    <ToolTip
                        Icon={HelpIcon}
                        text="Click for explanation on categories"
                        onClick={() => {
                            updateTooltipModal(true)
                        }}
                        color="#e69344"
                    />
                </div>
            </div>


            <Modal
                ariaHideApp={false}
                isOpen={tooltipModalOpen}
                onRequestClose={() => updateTooltipModal(false)}
                style={{
                    content: {
                        top: "0px",
                        left: "50%",
                        right: "auto",
                        bottom: "auto",
                        marginRight: "-50%",
                        transform: "translate(-50%, 0%)",
                        width: "80%",
                        marginTop: "20px",
                        height: "80%",
                        maxHeight: "910px",
                        maxWidth: "1000px",
                        padding: "10px",
                        filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#969392', endColorstr='#8a8685', GradientType=1) ",
                        backgroundColor: "#3f3e3e",
                        position: "relative",
                        paddingBottom: "0px"
                    },
                    overlay: {
                        backgroundColor: "#000000ab",
                        zIndex: "2",
                        display: "flex",
                        alignItems: "center"
                    }
                }}
                contentLabel="Message Editor"
            >
                <div className="tooltip_modal_contents">
                        <div><b>Bug: </b> Something is not working like it should? It's probably a bug.</div>
                        <div><b>Suggestion:</b> Do you want to post anything that's not really an issue, but you think changing this would improve the game?, this is where it goes.</div>
                        <div><b>In-Game Help:</b>Having problems with the gameplay?, don't know how to unlock something?, trouble defeating mobs?, you should post it here.</div>
                        <div><b>UI: </b>For anything related to the User Interface, menus not working?, clicking a certain button does nothing?, UI related.</div>
                        <div><b>QOL:</b>For anything that improves the "Quality of Life" of your gameplay, would the game feel better if you could do things differently? Then it's definitely QOL.</div>
                        <div><b>Multiplayer:</b>For any issue that affects multiplayer, from network issues to gameplay issues, general terminology is "Host" for whoever is loading the save and "Client" for those who join it.Got any ideas that would make the gameplay better? You can add QOL, Suggestion or Feature Request as well. Got issues with the gameplay? Use it by itself or add Bug, Crash, Performance or anything else relevant.</div>
                        <div><b>Crash:</b>For when your game closes unexpectedly, we try to focus on fixing these always.</div>
                        <div><b>Gameplay:</b>Got any ideas that would make the gameplay better? You can add QOL, Suggestion or Feature Request as well. Got issues with the gameplay? Use it by itself or add Bug, Crash, Performance or anything else relevant.</div>
                        <div><b>Feature Request:</b>For a Suggestion that goes beyond asking us to tweak something that already exists, if you want us to add something completely new, this is where it should go.</div>
                        <div><b>Buildings:</b>For anything related to all the things you can build in-game, from factory buildings to foundations and beyond.</div>
                        <div><b>Vehicles:</b>For anything related to all of your intended means of transportation.</div>
                        <div><b>Collision:</b>For when it's a little too hard to place a building, walk around it, or you can walk through something or get stuck on it.</div>
                        <div><b>Mobs:</b> For anything related to anything you can fight in-game.</div>
                        <div><b>Performance:</b>Did your performance suddenly decrease or increase after a patch? Please let us know.</div>
                        <div><b>Graphics:</b>Are the textures missing on some item or building? Something looks wrong? It's probably unintended.</div>
                        <div><b>Mods: </b>If you're having issues with the game and you're also using mods, adding this tag will help both us and modders identify what the problem actually is more quickly.</div>
                        <div><b>Audio: </b>For any issue that's related to the audio with the game, you can add "bug" to report issues or "suggestion" if you think a certain sound should be louder/quieter/different or anything else.</div>
                </div>
            </Modal>
        </div >
    );
}

export default CategorySelector;