import React, { useState } from 'react';
import styled from 'styled-components';

const DateInput = styled.input`
    width: 120px;
    background-color: #333;
    color: white;
    border: 1px solid #555;
    padding: 5px;
    border-radius: 5px;
    outline: none;
    /* Remove default appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    /* Style the calendar icon */
    &::-webkit-calendar-picker-indicator {
        filter: invert(1); /* Invert the color to white */
    }
`;

function DateColumnFilter({
    startDate,
    endDate,
    onStartDateChange,
    onEndDateChange
}) {
    const [startValue, setStartValue] = useState(startDate);
    const [endValue, setEndValue] = useState(endDate);

    const handleStartDateChange = (e) => {
        setStartValue(e.target.value);
    };

    const handleEndDateChange = (e) => {
        setEndValue(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            onStartDateChange(startValue);
            onEndDateChange(endValue);
        }
    };

    return (
        <>
            <DateInput
                type="date"
                value={startValue}
                onChange={handleStartDateChange}
                onKeyDown={handleKeyDown}
                placeholder="Start Date"
            />
            to
            <DateInput
                type="date"
                value={endValue}
                onChange={handleEndDateChange}
                onKeyDown={handleKeyDown}
                placeholder="End Date"
            />
        </>
    );
}

export default DateColumnFilter;
