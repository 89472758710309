import React from 'react';
import IconArrow from "@material-ui/icons/ArrowForwardIos"
import LeaderboardControls from './LeaderboardControls';

const LeaderboardSideNavContent = ({ children, isFetching, foundUser, data, fetchUsers, page, currentTabIndex, setTabIndex, updateHighlightName }) => {
    return (
        <>
            <LeaderboardControls isFetching={isFetching} page={page} updateData={fetchUsers} updateHighlightName={updateHighlightName} >
            {(!isFetching) && foundUser && data.length > 0 ?
                <>
                    {currentTabIndex !== 0 ? <div className="control_text" onClick={() => { setTabIndex(0) }}><p>Back to leaderboard</p></div> : <div style={{ height: "40px" }}> </div>}
                    <div className={currentTabIndex === 1 ? "control_text_selected" : "control_text"} onClick={() => { setTabIndex(1) }}><p>See posts by<IconArrow></IconArrow></p></div>
                    <div className={currentTabIndex === 2 ? "control_text_selected" : "control_text"} onClick={() => { setTabIndex(2) }}><p>See comments by<IconArrow></IconArrow></p></div>
                    <div className={currentTabIndex === 3 ? "control_text_selected" : "control_text"} onClick={() => { setTabIndex(3) }}><p>See favorites<IconArrow></IconArrow></p></div></>
                : <></>
            }
            {children}
            </LeaderboardControls>
        </>
    );
};

export default LeaderboardSideNavContent;