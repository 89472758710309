import React from 'react';

const ProfileSideNavControls = ({ children, updateModal, updateModalSettings }) => {
    return (
        <>
            <div className="control_text" onClick={() => updateModal(true)}><p>Change Password</p></div>
            <div className="control_text" onClick={() => updateModalSettings(true)}><p>Other Settings</p></div>
        </>
    );
};

export default ProfileSideNavControls;