import React, { useEffect, useState } from 'react';
import UserDetails from "./UserDetails";
import UserControls from "./UserControls";
import SideNav from '../Nav/SideNav';
import { mobileRes } from "./../Nav/SideNav";
import Spinner from '../Shared/Spinner';
import "./ProfileDashboard.css";
import ProfileSideNavControls from './ProfileSideNavControls';
import ChangePasswordModal from './ChangePasswordModal';
import OtherSettingsModal from './OtherSettingsModal';

const ProfileDashboard = ({ isFetching, user, updateUser, windowState, functions: { updateLeftBar } }) => {

    const [modalShown, updateModal] = useState(false);
    const [modalSettingsShown, updateModalSettings] = useState(false);

    //On Mount
    useEffect(() => { updateLeftBar(true) }, []); // eslint-disable-line react-hooks/exhaustive-deps
    //On Unmount
    useEffect(() => () => updateLeftBar(false), []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (windowState.windowWidth >= mobileRes) {
            updateLeftBar(true)
        }
        else {
            updateLeftBar(false)
        }
    }, [windowState]) // eslint-disable-line react-hooks/exhaustive-deps

    if (isFetching) return <Spinner loading={isFetching} />;

    if (!user.data) return null;

    return (<div className="profile_dashboard_main_body">
        <div className="leaderboard_screen">
            <div className="leaderboard_controls">
                <SideNav user={user} updateUser={updateUser}>
                    <ProfileSideNavControls updateModal={updateModal} updateModalSettings={updateModalSettings} />
                </SideNav>
            </div>
            <div className="user_public_main_body">
                <div style={{ width: "100%", padding: "20px" }}>
                    <div className="profile_dashboard">
                        <UserDetails user={user.data} />

                        <div className="profile_pass_settings">
                            <UserControls user={user.data} />
                        </div>
                    </div>
                </div>
            </div>
        </div >

        <ChangePasswordModal modalShown={modalShown} updateModal={updateModal}></ChangePasswordModal>
        <OtherSettingsModal user={user} modalShown={modalSettingsShown} updateModal={updateModalSettings}></OtherSettingsModal>
    </div>);

}

export default ProfileDashboard;