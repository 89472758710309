import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

export default function SimpleTooltips({ text, Icon, onClick, color, type, size, div }) {

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: onClick ? "pointer" : "inherit" }}>
            <Tooltip title={text}>
                <span style={{ display: "flex" }}>{div} <Icon onClick={onClick} color={type} style={{ color, fontSize: size }} /></span>
            </Tooltip>
        </div>
    );
}