import React from 'react';
import Spinner from '../Shared/Spinner';
import LeaderboardtWindowPopUpContent from './LeaderboardWindowPopUpLike';
import UsePagination from '../PostList/Paginator';
import LeaderboardList from "./LeaderboardList"

// Define the LeaderboardBody component
const LeaderboardBody = ({
    isFetching,  // Boolean indicating whether data is being fetched
    totalPages,  // Total number of pages in the data
    data,        // Array of data to be displayed in the leaderboard
    highlightName,  // Name to be highlighted in the leaderboard
    updateHighlightName,
    selectedPage,   // Currently selected page number
    updateSelectedPage,  // Function to update the selected page
}) => {
    return (
        <div className="leaderboard_body">
            {isFetching ? (
                <Spinner loading={isFetching} />
            ) : (
                <>
                    <LeaderboardtWindowPopUpContent>
                        {/* Pagination section */}
                        {totalPages > 1 && data.length > 0 && (
                            <UsePagination
                                pages={totalPages}
                                selectedPage={selectedPage}
                                functions={{ updateSelectedPage }}
                            />
                        )}

                        {/* LeaderboardList section */}
                            <LeaderboardList data={data} highlightName={highlightName} updateHighlightName={updateHighlightName} />

                        {/* Pagination section (again) */}
                        {totalPages > 1 && data.length > 0 && (
                            <UsePagination
                                pages={totalPages}
                                selectedPage={selectedPage}
                                functions={{ updateSelectedPage }}
                            />
                        )}
                    </LeaderboardtWindowPopUpContent>
                </>
            )}
        </div>
    );
};

export default LeaderboardBody;