import React, { useState, useEffect, } from 'react';
import { useHistory } from "react-router-dom";
import Category from '../Shared/Category';
import internal_fetch from "../../util/local-api";

import "./Categories.css";


const Categories = () => {
    const [categoryData, setCategoryData] = useState([]);
    const [searchText, updateSearchText] = useState("");
    const history = useHistory();

    useEffect(() => {
        (async function iife() {
            const response = await internal_fetch.get_categories_stats();
            if (response.statsByType) {
                setCategoryData(response.statsByType.stats_value)
            }
        })();
    }, [])

    return (<div className="category_data">
        <h1 className="nice_h1">All Tags</h1>
        <div className="category_search_field">
            <input type="text" className="nice_textbox" placeholder="search..." value={searchText} onChange={(e) => updateSearchText(e.target.value)} />
        </div>
        <div className="category_data_body">

            {categoryData.filter(({ name }) => name.toLowerCase().includes(searchText.toLowerCase())).map((cat, i) => {
                return <div className="cat_data" key={i}>
                    <p>{cat.value} x </p>
                    <Category cat={cat.name} onClick={() => history.push(`/search?category=${cat.name}`)} />
                </div>
            })}
        </div>
    </div>);
}

export default Categories;