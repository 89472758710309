import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Tabs, Tab, TabList, TabPanel } from "react-tabs";

import Spinner from "../Shared/Spinner";
import internal_fetch from "../../util/local-api";
import MessageModal from "../Inbox/MessageModal";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { useAlert } from 'react-alert'
import "react-tabs/style/react-tabs.css";
import "./UserPublic.css";
import UserPostsPanel from "./UserPostsPanel";
import UserCommentsPanel from "./UserCommentsPanel";
import UserFavoritePostsPanel from "./UserFavoritePostsPanel";
import SideNav from "../Nav/SideNav";
import { mobileRes } from "./../Nav/SideNav";
import UserPublicSideNavContent from "./UserPublicSideNavContent";
import BanUserModal from "../Admin/BanUserModal";
import { isContributor as checkContributor } from "../../util/misc";

const LOAD_INCREMENT = 20;

const UserPublic = ({ windowState, user, updateUser, isLoggedIn, isAdmin, isModerator, isContributor, functions: { updateLeftBar } }) => {
  const alert = useAlert()

  const localIndex = JSON.parse(sessionStorage.getItem("user_public_tab_index"));

  const [userId, updateUserId] = useState([]);
  const [userIsBanned, updateUserIsBanned] = useState(false);
  const [userIsContributor, updateUserIsContributor] = useState(false);
  const [privateMessagesEnabled, updatePrivateMessagesEnabled] = useState(false);
  const [isFetching, updateIsFetching] = useState(true);
  const [currentTabIndex, setTabIndex] = useState(localIndex ? localIndex : 0);
  const [numberDisplayed, updateNumberDisplayed] = useState(LOAD_INCREMENT);
  const [profileExists, updateProfileExists] = useState(false);
  const [showMessageModal, updateModalShown] = useState(false);
  const [flagsOnly, updateFlagsOnly] = useState(false);
  const [open, setOpen] = useState(false);
  const [banModalUsername, setBanModalUsername] = useState("");
  const [banModalUserId, setBanModalUserId] = useState("");
  let { username } = useParams();

  //On Mount
  useEffect(() => { updateLeftBar(true) }, []); // eslint-disable-line react-hooks/exhaustive-deps
  //On Unmount
  useEffect(() => () => updateLeftBar(false), []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => { updateLeftBar(windowState.windowWidth >= mobileRes) }, [windowState]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    (async function iife() {
      //checks if user login check has done or not.
      if (isAdmin === undefined) return

      updateIsFetching(true);
      const { userPostsByName } = await internal_fetch.user_posts_by_name(username, (isAdmin || isModerator), isContributor);
      if (userPostsByName) {
        updateUserId(userPostsByName.id)
        updateUserIsBanned(userPostsByName.banned_until !== "0")
        updateUserIsContributor(checkContributor(userPostsByName.role) || userPostsByName.isContributor)
        updatePrivateMessagesEnabled(userPostsByName.privateMessagesEnabled);
        updateProfileExists(true);
      }
      else {
        updateLeftBar(false)
      }
      updateIsFetching(false);
    })();
  }, [username, isAdmin]); // eslint-disable-line react-hooks/exhaustive-deps

  const blockUser = async () => {
    const response = await internal_fetch.update_block_user(userId, !userIsBlocked)
    if (response.updateBlockUserValue) {
      alert.info(response.updateBlockUserValue);
      window.location.reload(false);
    }
    else if (response.errors)
      alert.error(response.errors)
  }

  const showCustomModal = () => {
    setOpen(true);
  };

  const hideCustomModal = () => {
    setOpen(false);
  };

  const showBanUserModal = async (username, userId) => {
    setBanModalUsername(username)
    setBanModalUserId(userId)
    showCustomModal()
  }

  const banUser = async () => {
    const response = await internal_fetch.permaban_user(banModalUserId);
    if (response.permaBanUser) {
      hideCustomModal()
      updateIsFetching(true)
      alert.info(response.permaBanUser, {
        onClose: () => {
          window.location.reload();
        },
      })
    }
    else if (response.errors)
      alert.error(response.errors)
  }

  const unbanUser = async (userId) => {
    const response = await internal_fetch.unban_user(userId);
    if (response.unBanUser) {
      hideCustomModal()
      updateIsFetching(true)
      alert.info(response.unBanUser, {
        onClose: () => {
          window.location.reload();
        },
      })
    }
    else if (response.errors)
      alert.error(response.errors)
  }

  const makeContributor = async (userId) => {
    const response = await internal_fetch.update_as_contributor(userId);
    if (response.updateRoleAsContributor) {
      hideCustomModal()
      updateIsFetching(true)
      alert.info(response.updateRoleAsContributor, {
        onClose: () => {
          window.location.reload();
        },
      })
    }
    else if (response.errors)
      alert.error(response.errors)
  }

  const quitContributor = async (userId) => {
    const response = await internal_fetch.update_as_user(userId);
    if (response.updateRoleAsNormalUser) {
      hideCustomModal()
      updateIsFetching(true)
      alert.info(response.updateRoleAsNormalUser, {
        onClose: () => {
          window.location.reload();
        },
      })
    }
    else if (response.errors)
      alert.error(response.errors)
  }

  useEffect(() => {
    sessionStorage.setItem("user_public_tab_index", JSON.stringify(currentTabIndex))
  }, [currentTabIndex])

  const userIsBlocked = user.data && user.data.blocked_users && user.data.blocked_users.findIndex(u => u.id === userId) !== -1;

  return (
    <div className="">
      {profileExists || isFetching ?
        <>
          <div>
            <BanUserModal username={banModalUsername} open={open} banUser={banUser} handleClose={hideCustomModal} />

            <div className="leaderboard_screen">
              <div className="leaderboard_controls">
                <SideNav user={user} updateUser={updateUser}>
                  <UserPublicSideNavContent
                    user={user}
                    userId={userId}
                    username={username}
                    isLoggedIn={isLoggedIn}
                    isAdmin={isAdmin}
                    isModerator={isModerator}
                    isContributor={isContributor}
                    currentTabIndex={currentTabIndex}
                    isFetching={isFetching}
                    privateMessagesEnabled={privateMessagesEnabled}
                    userIsContributor={userIsContributor}
                    userIsBlocked={userIsBlocked}
                    userIsBanned={userIsBanned}
                    updateModalShown={updateModalShown}
                    blockUser={blockUser}
                    makeContributor={makeContributor}
                    quitContributor={quitContributor}
                    banUser={showBanUserModal}
                    unbanUser={unbanUser}
                    updateFlagsOnly={updateFlagsOnly}
                    seePostsBy={() => { setTabIndex(0) }}
                    seeCommentsBy={() => { setTabIndex(1) }}
                    seeFavorites={() => { setTabIndex(2) }}>
                    <div className="control_flags">  {isAdmin && currentTabIndex !== 2 ? <> <FormControlLabel label="Flags Only" control={<Checkbox checked={flagsOnly} onChange={(e) => { updateFlagsOnly(e.target.checked) }} value="flagsOnly" />} /></> : null}</div>
                  </UserPublicSideNavContent>
                </SideNav>
              </div>
              {isFetching ? <Spinner loading={isFetching} /> : <div className="user_public_main_body">
                <div style={{ width: "100%", padding: "10px" }}>

                  <Tabs selectedIndex={currentTabIndex} onSelect={(tabIndex) => { setTabIndex(tabIndex); if (currentTabIndex !== tabIndex) updateNumberDisplayed(LOAD_INCREMENT) }}>
                    <div className="tablist_user_public">
                      <TabList>
                        <Tab>Posts</Tab>
                        <Tab>Comments</Tab>
                        <Tab>Favourite Posts</Tab>
                    </TabList>
                    </div>
                    <TabPanel>
                      <UserPostsPanel
                        username={username}
                        isAdmin={isAdmin}
                        flagsOnly={flagsOnly}
                        numberDisplayed={numberDisplayed}
                      />
                    </TabPanel>
                    <TabPanel>
                      <UserCommentsPanel
                        username={username}
                        isAdmin={isAdmin}
                        flagsOnly={flagsOnly}
                        numberDisplayed={numberDisplayed}
                      />
                    </TabPanel>
                    <TabPanel>
                      <UserFavoritePostsPanel
                        username={username}
                        isAdmin={isAdmin}
                        numberDisplayed={numberDisplayed}
                      />
                    </TabPanel>
                  </Tabs>
                </div>

              </div>}
            </div >
          </div>
        </> : <h1 className="nice_h1">User not found</h1>
      }
      <MessageModal windowState={windowState} message={{ recipient: userId }} username={username} triggerUpdate={() => { }} updateModal={updateModalShown} modalShown={showMessageModal} />

    </div >
  );
};

export default UserPublic;
