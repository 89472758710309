import React, { useState, useEffect } from "react";
import ExpandableControl from "../../Shared/ExpandableControl";
import internal_fetch from "../../../util/local-api";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, ThemeProvider, createTheme } from '@material-ui/core';
import { useTable, useFilters, usePagination } from 'react-table'
import styled from 'styled-components'
import DefaultColumnFilter from "../DefaultColumnFilter";
import { Skeleton } from '@material-ui/lab';
import { useAlert } from 'react-alert'
import PaginationInput from "../StyledInput";
// import Typography from '@material-ui/core/Typography';
import { truncateText } from "../../../util/misc";
import MinMaxColumnFilter from "../MinMaxColumnFilter";
import DateInputColumnFilter from "../DateInputColumnFilter";
import NuclearBanUserModal from "../NuclearBanUserModal";
import Tooltip from '@material-ui/core/Tooltip';
import FlagIcon from '@material-ui/icons/OutlinedFlag'
import DeleteIcon from '@material-ui/icons/DeleteOutline'
import NuclearIcon from '@material-ui/icons/Security'
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/SkipPrevious';
import LastPageIcon from '@material-ui/icons/SkipNext';
import PrevPageIcon from '@material-ui/icons/ChevronLeft';
import NextPageIcon from '@material-ui/icons/ChevronRight';
import Spinner from "../../Shared/Spinner";

const AdminFlagsPosts = ({ isAdmin, defaultExpanded }) => {
    const [auditLogs, updateAuditLogs] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [pagination, setPagination] = useState({ page: 0, pageSize: 10 });
    const [filterUsername, setFilterUsername] = useState('');
    const [filterTitle, setFilterTitle] = useState('');
    const [minFlags, setminFlags] = useState('');
    const [maxFlags, setmaxFlags] = useState('');
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [banModalUsername, setBanModalUsername] = useState("");
    const [banModalUserId, setBanModalUserId] = useState("");
    const [open, setOpen] = React.useState(false);
    const alert = useAlert()

    useEffect(() => {
        fetchData();
    }, [pagination, filterTitle, filterUsername, startDate, minFlags, maxFlags]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await internal_fetch.all_flags_posts(pagination.page, pagination.pageSize, filterTitle, filterUsername, startDate, parseInt(minFlags), parseInt(maxFlags));
            if (response.flaggedPosts) {
                setTotalItems(response.flaggedPosts.totalDocs)
                updateAuditLogs(response.flaggedPosts.posts);
                if ((response.flaggedPosts.totalPages - 1) < pagination.page) {
                    setPagination({ ...pagination, page: response.flaggedPosts.totalPages - 1 });
                } 
            }
            await new Promise(resolve => setTimeout(resolve, 100));
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (event, newPage) => {
        setPagination({ ...pagination, page: newPage });
    };

    const handleRowsPerPageChange = (event) => {
        setPagination({ ...pagination, pageSize: parseInt(event.target.value, 10), page: 0 });
    };

    const showCustomModal = () => {
        setOpen(true);
    };

    const hideCustomModal = () => {
        setOpen(false);
    };

    const clearPostFlags = async (postId) => {
        const response = await internal_fetch.clear_post_flags(postId);
        if (response.clearPostFlags) {
            fetchData();
            alert.info("Flags cleaned!")
        }
    }

    const deletePost = async (postId) => {
        const response = await internal_fetch.delete_post(postId);
        if (response.deletePost) {
            fetchData();
            alert.info(response.deletePost);
        }
        else if (response.errors)
            alert.error(response.errors)
    }

    const showBanModal = async (username, userId) => {
        setBanModalUsername(username)
        setBanModalUserId(userId)
        showCustomModal()
    }

    const banUser = async () => {
        const response = await internal_fetch.permaban_user(banModalUserId);
        if (response.permaBanUser) {
            alert.info(response.permaBanUser)
            hideCustomModal();
            fetchData();
        }
        else if (response.errors)
            alert.error(response.errors)
    }

    const banAndPurgeUser = async () => {
        let responseBan = await internal_fetch.permaban_user(banModalUserId);
        if (responseBan.permaBanUser) {
            alert.info(responseBan.permaBanUser)
        }
        else if (responseBan.errors)
            alert.error(responseBan.errors)

        let responsePurge = await internal_fetch.purge_user(banModalUserId);
        if (responsePurge.purgeUser) {
            alert.info(responsePurge.purgeUser);
        }
        else if (responsePurge.errors)
            alert.error(responsePurge.errors)

        if (responseBan.permaBanUser && responsePurge.purgeUser) {
            hideCustomModal();
            fetchData();
        }
    }

    const auditColumns = [
        {
            Header: 'ok',
            columns: [
                {
                    Header: 'Options',
                    Cell: ({ row }) => (
                        <div className="controls_options">
                            <div>
                                <Tooltip title="Clear Flags">
                                    <IconButton aria-label="clear flags" onClick={() => clearPostFlags(row.original.id)}>
                                        <FlagIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <div>
                                <Tooltip title="Delete Post">
                                    <IconButton aria-label="delete post" onClick={() => deletePost(row.original.id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                    ),
                },
                {
                    Header: 'Title',
                    accessor: 'title',
                    Cell: ({ row }) => (
                        <Tooltip title={<span style={{ fontSize: '16px' }}>{row.original.title}</span>}>
                        <a href={`/post/${row.original.id}`} target="_blank" rel="noopener noreferrer"><div className="post_table_title" >{row.original.title}</div></a>
                        </Tooltip>

                    ),
                    Filter: ({ column }) => (
                        <DefaultColumnFilter
                            filterValue={filterTitle}
                            columnId='title'
                            onFilterChange={setFilterTitle}
                        />
                    ),
                },
                {
                    Header: 'Author',
                    accessor: a => a.author ? a.author.username : "deleted",
                    Filter: ({ column }) => (
                        <DefaultColumnFilter
                            filterValue={filterUsername}
                            columnId='username'
                            onFilterChange={setFilterUsername}
                        />
                    ),
                    Cell: ({ row }) => (
                        row.original.author ?
                            <Tooltip title={row.original.author.username}>
                                <a href={`/user/${row.original.author.username}`} target="_blank" rel="noopener noreferrer">{truncateText(row.original.author.username, 15)}</a>
                            </Tooltip>
                            :
                            <>deleted</>
                    )
                },
                {
                    Header: 'Date',
                    accessor: a => new Date(a.creation_date).toLocaleDateString(),
                    Filter: ({ column }) => (
                        <DateInputColumnFilter
                            filterValue={startDate}
                            columnId='date'
                            onFilterChange={setStartDate}
                        />
                    )
                },
                {
                    Header: "Flags",
                    accessor: a => a.flagsCount,
                    Filter: ({ column }) => (
                        <MinMaxColumnFilter
                            columnId='flagCount'
                            minFilter={minFlags}
                            maxFilter={maxFlags}
                            onminFilterChange={setminFlags}
                            onmaxFilterChange={setmaxFlags}
                        />
                    ),
                },
                {
                    Header: "Spam",
                    id: 'view',
                    Cell: ({ row }) => (
                        <div className="controls_options">
                            <div>
                                <Tooltip title="Nuclear ban">
                                    <IconButton aria-label="clear flags" onClick={() => showBanModal(row.original.author.username, row.original.author.id)}>
                                        <NuclearIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                    ),
                },
            ].filter(column => isAdmin || !["Spam"].includes(column.Header)),
        }
    ];


    const darkTheme = createTheme({
        palette: {
            type: 'dark',
        },
    });

    return (
        <ExpandableControl
            className="admin_flags"
            header="Audit Log"
            defaultExpanded={defaultExpanded}
        >
            <NuclearBanUserModal username={banModalUsername} open={open} banUser={banUser} banAndPurgeUser={banAndPurgeUser} handleClose={hideCustomModal} />

            {loading && auditLogs.length === 0 ?
                <Spinner loading={loading} /> :
            <ThemeProvider theme={darkTheme}>
                <TableContainer component={Paper}>
                    <Styles>
                        <TableComponent
                            columns={auditColumns}
                            data={auditLogs}
                            pagination={pagination}
                            loading={loading} />
                    </Styles>
                </TableContainer>
                <div>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={totalItems}
                        rowsPerPage={pagination.pageSize}
                        page={pagination.page}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={(event) => handleRowsPerPageChange(event)}
                        disabled={loading}
                        labelDisplayedRows={({ from, to, count }) => {
                            return `${pagination.page + 1} of ${Math.ceil(totalItems / pagination.pageSize)} pages`;
                        }}
                        ActionsComponent={({ page }) => (
                            <div style={{ display: "flex" }}>
                                <IconButton onClick={() => setPagination({ page: 0, pageSize: pagination.pageSize })} disabled={pagination.page === 0}>
                                    <FirstPageIcon />
                                </IconButton>
                                <IconButton onClick={() => setPagination({ page: pagination.page - 1, pageSize: pagination.pageSize })} disabled={pagination.page === 0}>
                                    <PrevPageIcon />
                                </IconButton>
                                <IconButton onClick={() => setPagination({ page: pagination.page + 1, pageSize: pagination.pageSize })} disabled={pagination.page === Math.ceil(totalItems / pagination.pageSize) - 1}>
                                    <NextPageIcon />
                                </IconButton>
                                <IconButton onClick={() => setPagination({ page: Math.ceil(totalItems / pagination.pageSize) - 1, pageSize: pagination.pageSize })} disabled={pagination.page === Math.ceil(totalItems / pagination.pageSize) - 1}>
                                    <LastPageIcon />
                                </IconButton>
                            </div>
                        )}
                    />
                    <PaginationInput totalPages={Math.ceil(totalItems / pagination.pageSize)} onPageChange={(page) => setPagination({ ...pagination, page })} />

                </div>
                </ThemeProvider>}
        </ExpandableControl>
    );
};

export default AdminFlagsPosts;

const Styles = styled.div`
  padding: 1rem;
  overflow: auto;
  height: 100%;
  width: 100%;
`;

function TableComponent({ columns, data, loading, pagination, classes }) {
    const defaultColumn = React.useMemo(
        () => ({
            Filter: DefaultColumnFilter,
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page, // Use 'page' from useTable
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState: { pageIndex: pagination.page, pageSize: pagination.pageSize }, // Set initial page index and size
            manualPagination: true, // Enable manual pagination
            pageCount: Math.ceil(data.length / 10), // Calculate page count based on data length
            useControlledState: state => React.useMemo(() => ({
                ...state,
                pageIndex: pagination.page,
                pageSize: pagination.pageSize,
            }), [state, pagination]), // eslint-disable-line react-hooks/exhaustive-deps
        },
        useFilters,
        usePagination
    );

    return (
        <>
            {/* <Typography variant="h6" id="tableTitle" component="div">
                Audit Logs
            </Typography> */}
            <Table {...getTableProps()} size="small">
                <TableHead>
                    {headerGroups.map((headerGroup, index) => (
                        index === 0 ?
                            <TableRow key='empty'></TableRow> : (
                                <TableRow key='headerGroup' {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <TableCell key={column.id}>
                                            <div className="pre_header">{column.render('Header')}</div>
                                            <div>{column.canFilter ? column.render('Filter') : null}</div>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            )
                    ))}
                </TableHead>
                <TableBody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <TableRow key={row.id} {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <TableCell key={cell.id} {...cell.getCellProps()}>
                                            {loading ? (
                                                <Skeleton animation="wave" height={20} width={"100%"} />
                                            ) : (
                                                cell.render('Cell')
                                            )}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </>
    )
}

