import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
const MobileControlDrawer = ({ children, expanded, toggle }) => {
    return (<div className="mobile_control_drawer">
        {expanded ? children : null}
        {
            expanded ? <ExpandLessIcon style={{ fontSize: "45px", marginTop: "-6px" }} onClick={toggle} /> : <ExpandMoreIcon style={{ fontSize: "45px", marginTop: "-7px" }} onClick={toggle} />
        }
    </div>);
}

export default MobileControlDrawer;