import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import CancelSortIcon from '@material-ui/icons/CancelRounded';

export function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort, sortEnabled } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    const headCells = [
        { name: 'username', numeric: false, disablePadding: true, label: "Username" },
        { name: 'email', numeric: false, disablePadding: true, label: "Email" },
        { name: 'creation_date', numeric: false, disablePadding: true, label: "Creation Date" },
        { name: 'role', numeric: false, disablePadding: true, label: "Role" },
        { name: 'points', numeric: false, disablePadding: true, label: "Points" },
        { name: 'banned_until', numeric: false, disablePadding: true, label: "Banned Until" },
    ];

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">

                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.name}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.name ? order : false}
                    >
                        {(sortEnabled ? <>
                            <TableSortLabel
                                active={orderBy === headCell.name}
                                direction={orderBy === headCell.name ? order : 'asc'}
                                onClick={createSortHandler(headCell.name)}
                            >
                                {headCell.label}
                                {orderBy === headCell.name ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>

                            {
                                orderBy === headCell.name ? (<TableSortLabel
                                    active={orderBy === headCell.name}
                                    direction={'asc'}
                                    IconComponent={CancelSortIcon}
                                    onClick={createSortHandler('')}
                                > </TableSortLabel>) : null
                            }

                        </> : <>{headCell.label}</>)}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    sortEnabled: PropTypes.bool.isRequired
};