import React, { useState, useRef } from 'react';
import SearchIcon from '@material-ui/icons/Search';

const LeaderboardControls = ({ children, noName, hasPrevPage, isFetching, hasNextPage, updateHighlightName, page, updatePage }) => {
    const [tempSearch, updateSearch] = useState("");

    const mainRef = useRef(null);


    return (<div className="leaderboard_controls">
        <div className="post_controls_lower" style={{ width: "100%" }}>
            <div className="post_searchbar" style={{ width: "100%" }}>
                <input ref={mainRef}
                    disabled={isFetching}
                    maxLength={100}
                    type="text"
                    placeholder="Search..."
                    value={tempSearch}
                    onKeyDown={(e) => e.key === "Enter" ? updateHighlightName(tempSearch) : null}
                    onChange={(e) => updateSearch(e.target.value)} />
                <SearchIcon onClick={() => mainRef.current.focus()} style={{ fontSize: "24px" }} />
            </div>
            {children}
        </div>
    </div >);
}

export default LeaderboardControls;