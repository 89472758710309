import React, { useState, useEffect } from 'react';
import { NavLink as Link } from "react-router-dom"
import internal_fetch from "../../util/local-api";
import ReactTimeAgo from 'react-time-ago/commonjs/ReactTimeAgo';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import "./Feed.css";

const Feed = () => {
    const [feedData, updateFeedData] = useState([])
    const [minimized, updateMinimized] = useState(JSON.parse(localStorage.getItem("feed_minimized")));
    useEffect(() => {
        (async function iife() {
            const response = await internal_fetch.feed_data();
            if (response.feedData) {
                updateFeedData(response.feedData)
            }
        })();
    }, []);

    useEffect(() => {
        localStorage.setItem("feed_minimized", minimized);
    }, [minimized])


    if (feedData.length === 0) return null
    if (minimized) return <div className="feed feed_min">
        <div className="feed_header" onClick={() => updateMinimized(false)} >
            <h1>Latest Updates</h1>
            <ExpandLessIcon className="mini_icon" />
        </div>
    </div>

    return (<div className="feed">
        <div>
            <div className="feed_header" onClick={() => updateMinimized(true)}>
                <h1>Latest Updates</h1>
                <ExpandMoreIcon className="mini_icon" />
            </div>
            <div className="feed_blobs">
                {
                    feedData.map((feedBlob, i) =>
                        <div className="feed_blob" key={i}>
                            {
                                <Link to={`/post/${feedBlob.post_id}`}><div>{feedBlob.username}<ReactTimeAgo date={+feedBlob.date} /></div>  <p>{feedBlob.type === "Comment" ? "Commented on " : "Posted "}<u>{feedBlob.postTitle}</u></p> </Link>
                            }
                        </div>)
                }
            </div>
        </div>
    </div >);
}

export default Feed;