import React, { useState, useEffect } from "react";
import HideIcon from '@material-ui/icons/VisibilityOff';
import ShowIcon from '@material-ui/icons/Visibility';

const LoginFieldText = ({ placeholder, currentValue, type, onChangeValue, onKeyDown, onBlur, maxLength }) => {

    const [showHidden, setShowHidden] = useState(false)
    const [Icon, setIcon] = useState(ShowIcon)

    const inputRef = React.useRef(null);

    useEffect(() => {
        setIcon(showHidden ? ShowIcon : HideIcon)
    }, [showHidden]);

    return <div className="nice_container">
        <p>{placeholder}</p>
        <div className="nice_textbox_login_container">
            <input
                ref={inputRef}
                type={showHidden ? "text" : type}
                className="nice_textbox_login"
                placeholder={placeholder}
                value={currentValue}
                onChange={e => onChangeValue(e.target.value)}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                maxLength={maxLength}
            />
            {type === "text" ? <></> : <Icon className="login_input_icon" onClick={() => setShowHidden(!showHidden)} style={{ fontSize: "24px" }} />}
        </div>
    </div>;
}

export default LoginFieldText;