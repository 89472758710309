import React from 'react';
import Button from '@material-ui/core/Button';
import { NavLink as Link, useHistory } from "react-router-dom";
import MenuPopper from "./MenuPopper"
import { clearIdToken } from "../../util/auth"
import { setLoggedLocal, isLoggedLocal } from '../../util/misc';

import "./SideNav.css";

const navOptions = [
    {
        image: "https://satisfactoryweb.blob.core.windows.net/public/qa_site/logo.png",
        link: "/",
        exact: true
    }]

const SideNav = ({ children, user, updateUser }) => {

    const history = useHistory();

    const logout = () => {
        clearIdToken();
        setLoggedLocal(false)
        updateUser({ isLoggedIn: false });
        history.push("/")
    }

    return <div id="mySidenav" className="sidenav">
        {navOptions.map((nav, i) => !nav.realURL ? (
            <Link
                key={i}
                exact={nav.exact}
                activeClassName={nav.image ? "" : "nav_active_sidenav"}
                className={"nav_block_sidenav"}
                to={nav.link}
                onClick={nav.action ? nav.action : null}
            >
                {nav.image ? <img src={nav.image} alt="nav" /> : <Button >{nav.text}</Button>}
            </Link>) :
            <a className={"nav_block"} key={i} href={nav.link} >
                {nav.image ? <img src={nav.image} alt="nav" /> : <Button >{nav.text}</Button>}
            </a>
        )}

        <div className="sidenav-container">
            <div className="sidenav-flexible-child">
                {children}
            </div>

            <div className="sidenav-fixed-height-child">
                <p>Current User :</p>
                {user?.isLoggedIn || isLoggedLocal() ?
                    <MenuPopper user={user} logout={logout} />
                    :

                    <Link
                        to="/login" style={{ color: "white" }} className='sidenav-fixed-height-child-not-logged'
                    >
                        <div>Not Logged into SatisfactoryQA</div>
                    </Link>

                }
            </div>
        </div>       
    </div>;
}

export default SideNav;

export const mobileRes = 884;