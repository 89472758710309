import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import { ThemeProvider } from '@material-ui/core/styles';
import { useStyles, darkTheme } from './NewStatusesTable/styles';
import { EnhancedTableHead } from './NewStatusesTable/EnchancedTableHead';
import { EnhancedTableToolbar } from './NewStatusesTable/EnhancedTableToolbar';
import EditableVisibilityCell from './NewStatusesTable/EditableVisibilityCell';
import EditableTextCell from './NewStatusesTable/EditableTextCell';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/SkipPrevious';
import LastPageIcon from '@material-ui/icons/SkipNext';
import PrevPageIcon from '@material-ui/icons/ChevronLeft';
import NextPageIcon from '@material-ui/icons/ChevronRight';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator, searchTerm, sortEnabled) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    if (sortEnabled)
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });

    const filteredArray = searchTerm
        ? stabilizedThis.filter((row) => (row[0].key.toLowerCase().includes(searchTerm.toLowerCase()) || row[0].value.toLowerCase().includes(searchTerm.toLowerCase())))
        : stabilizedThis;

    return filteredArray.map((el) => el[0]);
}

const DarkTableCell = ({ children, ...props }) => {
    const classes = useStyles();
    return (
        <TableCell className={classes.tableCell} {...props}>
            {children}
        </TableCell>
    );
};

export default function EnhancedTable({ rows, title = "Statuses", rowlabel, sortEnabled = true, save_statuses, updateStatuses, update_statuses, add_statuses, save_given_statuses, reload }) {
    const classes = useStyles();
    const [searchTerm, setSearchTerm] = React.useState('');
    const [showSave, setSave] = React.useState(false);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [draggingIndex, setDraggingIndex] = useState(null);
    const [draggingItem, setDraggingItem] = useState(null);

    const handleSearch = (value) => {
        setSearchTerm(value);
        setPage(0); // Reset page when searching
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleSave = async (value) => {
        setSave(false);

        if (value) {
            await save_statuses();
            reload();
        }
        else {
            reload();
        }
    };

    const handleDelete = (value) => {
        const newRows = rows.filter(row => !value.includes(row.key));
        save_given_statuses(newRows)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const handleDragStart = (event, index) => {
        setDraggingIndex(index + (page * rowsPerPage));
        setDraggingItem(rows[index + (page * rowsPerPage)]);

        const tableRows = document.querySelectorAll('.MuiTableRow-root');
        tableRows.forEach(row => {
            row.classList.remove('MuiTableRow-hover');
        });

        event.currentTarget.classList.add('dragging');
    };

    const handleDragOver = (event, index) => {
        event.preventDefault();
        const draggedOverItem = rows[index + (page * rowsPerPage)];
        if (draggedOverItem === draggingItem || draggingIndex === null || draggingIndex === index) return;

        let newRows = [...rows];
        newRows.splice(draggingIndex, 1);
        newRows.splice(index + (page * rowsPerPage), 0, draggingItem);

        updateStatuses(newRows);
        setDraggingIndex(index + (page * rowsPerPage));
        setSave(true);
    };

    const handleDragEnd = () => {
        setDraggingIndex(null);
        setDraggingItem(null);

        const tableRows = document.querySelectorAll('.MuiTableRow-root');
        tableRows.forEach(row => {
            row.classList.add('MuiTableRow-hover');
            row.classList.remove('dragging');
        });
    };

    return (
        <ThemeProvider theme={darkTheme}>
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <EnhancedTableToolbar
                        itemsSelected={selected}
                        title={title}
                        onSearch={handleSearch}
                        handleDelete={handleDelete}
                        handleSave={handleSave}
                        showSave={showSave}
                        setSelected={setSelected}
                        handleAddRow={add_statuses}
                    />
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead
                                classes={classes}
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                                rowlabel={rowlabel}
                                sortEnabled={sortEnabled}
                            />
                            <TableBody>
                                {stableSort(rows, getComparator(order, orderBy), searchTerm, sortEnabled)
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        const isItemSelected = isSelected(row.key);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                hover
                                                onClick={(event) => handleClick(event, row.key)}
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.key}
                                                selected={isItemSelected}
                                                draggable={searchTerm === '' && orderBy === ''}
                                                onDragStart={(event) => handleDragStart(event, index)}
                                                onDragOver={(event) => handleDragOver(event, index)}
                                                onDragEnd={handleDragEnd}
                                            >
                                                <DarkTableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={isItemSelected}
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </DarkTableCell>
                                                <DarkTableCell component="th" id={labelId} scope="row" padding="none">
                                                    {row.key}
                                                </DarkTableCell>
                                                <EditableTextCell
                                                    value={row.value}
                                                    showSave={setSave}
                                                    onEdit={(newValue) => {
                                                        update_statuses(row.key, newValue, row.visibility);
                                                        setSave(true);
                                                    }}
                                                />
                                                <EditableVisibilityCell
                                                    isEditable={true}
                                                    value={row.visibility}
                                                    onEdit={(newValue) => {
                                                        update_statuses(row.key, row.value, newValue);
                                                        setSave(true);
                                                    }}>
                                                    {row.visibility ? 'True' : 'False'}
                                                </EditableVisibilityCell>
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <DarkTableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelDisplayedRows={({ from, to, count }) => {
                            return `${page + 1} of ${Math.ceil(rows.length / rowsPerPage)} pages`;
                        }}
                        ActionsComponent={({ page }) => (
                            <div style={{ display: "flex" }}>
                                <IconButton onClick={() => setPage(0)} disabled={page === 0}>
                                    <FirstPageIcon />
                                </IconButton>
                                <IconButton onClick={() => setPage(page - 1)} disabled={page === 0}>
                                    <PrevPageIcon />
                                </IconButton>
                                <IconButton onClick={() => setPage(page + 1)} disabled={page === Math.ceil(rows.length / rowsPerPage) - 1}>
                                    <NextPageIcon />
                                </IconButton>
                                <IconButton onClick={() => setPage(Math.ceil(rows.length / rowsPerPage) - 1)} disabled={page === Math.ceil(rows.length / rowsPerPage) - 1}>
                                    <LastPageIcon />

                                </IconButton>
                            </div>
                        )}
                    />
                </Paper>
            </div>
        </ThemeProvider>
    );
}
