import React, { useState, useEffect } from "react";
import ExpandableControl from "../Shared/ExpandableControl";
import internal_fetch from "../../util/local-api";
import FancyButton from "../Shared/FancyButton";
import { useAlert } from 'react-alert'

const AdminOtherControls = ({ defaultExpanded }) => {
  const [data, setData] = useState("");
  const alert = useAlert()

  useEffect(() => {
    (async function iife() {
      const response = await internal_fetch.other_settings();
      if (response.otherSettings)
        setData(response.otherSettings.join("\n"));
    })();
  }, []);

  const update_other_settings = async () => {
    const response = await internal_fetch.update_other_settings(
      data.split("\n")
    );
    if (response.updateOtherSettings) {
      alert.info("updated");
      setTimeout(() => {
        window.location.reload(); // Reloading the page after a delay
      }, 1000);
    }
    else if (response.errors)
      alert.error(response.errors)
  };

  return (
    <ExpandableControl
      className="admin_IP "
      height="215px"
      header="Other Settings"
      defaultExpanded={defaultExpanded}
    >
      <div className="admin_IP_details admin_section_details">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <label style={{ whiteSpace: "nowrap", marginRight: "10px" }}>Discord link:</label>
          <input style={{ width: "100%" }}
            value={data}
            onChange={e => setData(e.target.value)}
          />
        </div>

        <FancyButton
          disabled={data === ""}
          onClick={update_other_settings}
          text="Save"
        />
      </div>
    </ExpandableControl>
  );
};

export default AdminOtherControls;
