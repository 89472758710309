import React, { useMemo, useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // ES6

const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
]

var Delta = Quill.import('delta');

const RichTextEditor = ({ postContents, updatePostContents, placeholder, windowState }) => {
    const quillRef = useRef(null);

    const modules = useMemo(() => {
        return {
            toolbar: {
                container: [
                    [{ 'header': [1, 2, false] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                    ['link', 'image'],
                ],
                handlers: {
                    image: function () {
                        const range = quillRef.current.getEditor().getSelection();
                        const value = prompt('IMAGE URL:');
                        if (value) {
                            quillRef.current.getEditor().insertEmbed(range.index, 'image', value, Quill.sources.USER);
                        }
                    }
                },
            },
            clipboard: {
                matchers: [
                    ["img", function (node, delta) {
                        if (delta && delta.ops && delta.ops[0] && delta.ops[0].insert && delta.ops[0].insert.image && delta.ops[0].insert.image.includes("data:image/")) {
                            let ops = []

                            delta.ops.forEach(op => {
                                if (op.insert && typeof op.insert === 'string') {
                                    ops.push({
                                        insert: op.insert
                                    })
                                }
                            })
                            delta.ops = ops
                            return delta
                        }
                        else
                            return delta.compose(new Delta().retain(delta.length(), { image: false }));
                    }],
                    [Node.TEXT_NODE, function (node, delta) {
                        var regex = /https?:\/\/[^\s]+/g;

                        if (typeof (node.data) !== 'string') return;
                        var matches = node.data.match(regex);


                        if (matches && matches.length > 0) {
                            var ops = [];
                            var str = node.data;
                            matches.forEach(function (match) {
                                var split = str.split(match);
                                var beforeLink = split.shift();
                                ops.push({ insert: beforeLink });
                                ops.push({ insert: match, attributes: { link: match } });
                                str = split.join(match);
                            });
                            ops.push({ insert: str });
                            delta.ops = ops;
                        }

                        return delta
                    }]
                ]
            }
        }
    }, [quillRef]);


    return <ReactQuill style={{
        height: "300px",
        paddingBottom: "43px",
        color: "white",
        marginBottom: windowState.windowWidth > 514 ? "5px" : "25px"
    }} ref={quillRef} defaultValue={postContents} onChange={(value, delta, source) => {
        if (source === 'api')
            return
        updatePostContents(value)
    }
    } placeholder={placeholder || 'Add your content...'} theme="snow" modules={modules} formats={formats} />
}

export default RichTextEditor;