import React, { useState, useEffect } from 'react';
import RichTextEditor from './RichTextEditor';
import CategorySelector from './CategorySelector';
import VersionNumberSelector from './VersionNumberSelector';
import StatusSelector from './StatusSelector';
import FancyButton from '../FancyButton';
import internal_fetch from "../../../util/local-api";
import { NavLink as Link } from "react-router-dom"
import { useAlert } from 'react-alert'
import logo_window from './../../../assets/window_icon.svg'
import LogoClose from '@material-ui/icons/Close'
import PostPageRichContent from '../../PostPage/PostPageRichContent';
import "./PostEditor.css";
import { Checkbox } from '@material-ui/core';


const PostEditor = ({ children, onSubmit, defaultData, isAdmin, isModerator, status, edit, windowState, isModal, updateModal, updateDisableDrag }) => {
    const [postTitle, updatePostTitle] = useState(defaultData.title || "");
    const [postContents, updatePostContents] = useState(defaultData.contents || "");
    const [postCategories, updatePostCategories] = useState(defaultData.categories || []);
    const [postVersionNumber, updatePostVersionNumber] = useState(defaultData.version_number || "");
    const [postStatus, updatePostStatus] = useState(defaultData.status || "");
    const [similarTitles, updateSimilarTitles] = useState([])
    const [previewMode, updatePreviewMode] = useState(false);
    const [isPatchNote, updateisPatchNote] = useState(defaultData.is_patch_note || false);
    const alert = useAlert()

    useEffect(() => {
        updatePostCategories(defaultData.categories || [])
    }, [isPatchNote, defaultData.categories]);

    const submitPost = async () => {
        let alertString = ""
        if (!isPatchNote && postCategories.length < 1)
            alertString += "Need at least 1 category"
        if (postTitle === "" || postTitle.trim().length < 1)
            alertString += "\nNeed title"
        if (postContents === "" || postContents.trim().length < 1)
            alertString += "\nNeed body"
        if (postVersionNumber === "" || postVersionNumber.length < 1)
            alertString += "\nNeed Version Number"
        if (alertString)
            return alert.error(alertString.trim());

        onSubmit({ postTitle, postContents, postCategories, postVersionNumber, postStatus, isPatchNote })
    }

    const findSimilarTitles = async () => {
        if (isPatchNote) return;

        const response = await internal_fetch.find_similar_titles(postTitle);
        if (response.findSimilarTitles) {
            updateSimilarTitles(response.findSimilarTitles)
        }
    }

    return (
        <div className="post_window_pop_up" >
            <div className="comment_window_pop_up_title">
                <img className="logo_window" src={logo_window} alt="logo" />
                <p className='title_comment_pop_up' >{isPatchNote ? "Patch note" : "Ask a question"} </p>

                {
                    isModal ? <div className='logo_close_div' onClick={() => updateModal(false)}>
                        <LogoClose className='logo_close' />
                    </div> : null
                }
            </div>
            <div className={isModal ? "post_dashboard_main_modal" : "post_dashboard_main"}>
                <div className="comment_panel_descriptor_div">
                    <div className="post_panel_descriptor_p">{children}</div>
                    {(isAdmin || isModerator) && status === true && !isPatchNote ?
                        <div className="post_main_version" >
                            <div>
                                <div className="post_main_status_label">
                                    Status
                                </div>
                                <StatusSelector postStatus={postStatus} updatePostStatus={updatePostStatus} restrictedList={isModerator} />
                            </div> 
                        </div>
                        : null}
                </div>
                {
                    isAdmin && !edit ? <div className='post_patch_row' style={{ display: "flex", flexDirection: "row !important", height: "49px", marginRight: "-20px" }}>
                        <div className="" style={{ backgroundColor: "#e67e22", width: "100%", marginLeft: "-10px", }}>
                            <div style={{ display: "flex", alignItems: "center", height: "100%", padding: "10px" }}>
                                <p className="post_panel_descriptor_p">Is this a Patch Note?</p>
                                <div >
                                    <Checkbox checked={isPatchNote} onChange={(e) => {
                                        updateisPatchNote(e.target.checked)
                                    }} value={isPatchNote} disabled={false} />
                                </div>
                            </div>
                        </div>

                    </div> : <></>
                }
                <div className='post_second_row' style={{ display: "flex", flexDirection: "row", height: "100%" }}>
                    <div className="post_main_question">
                        <div ><input
                            className='nice_textbox_post'
                            placeholder={isPatchNote ? 'Patch note title' : 'The question in one sentence:'}
                            value={postTitle}
                            onChange={(e) => updatePostTitle(e.target.value)}
                            onBlur={findSimilarTitles}
                            type="text" maxLength={100} /></div>
                        <div className="post_similar_titles" style={{ display: similarTitles.length > 0 ? null : "none" }} >
                            {similarTitles.length > 0 ? <h1>Try checking out one of these similar posts first!</h1> : null}
                            {similarTitles.map((post, i) => <Link key={i} to={`/post/${post.id}`}>{post.title}</Link>)}
                        </div>
                    </div>
                    <div className="post_main_version" >
                        <div>
                            <div className="post_main_version_label">
                                Version
                            </div>
                            <VersionNumberSelector postVersionNumber={postVersionNumber} updatePostVersionNumber={updatePostVersionNumber} />
                        </div>
                    </div>
                </div>
                <div className="post_main_content">
                    {!previewMode ?
                        <RichTextEditor
                            isPatchNote={isPatchNote}
                            onMouseEnter={() => updateDisableDrag(true)}
                            onMouseLeave={() => updateDisableDrag(false)}
                            onClick={() => updateDisableDrag(false)}
                            postContents={postContents}
                            updatePostContents={updatePostContents}
                            windowState={windowState} />
                        : <div className={isModal ? 'post_editor_rich_content_modal' : 'post_editor_rich_content'}>
                            <PostPageRichContent
                                post={{ contents: postContents }} /
                            ></div>}

                </div>

                <>
                    {
                        !isPatchNote ? 
                            <div className="post_main_categories">
                                <CategorySelector postCategories={postCategories} updatePostCategories={updatePostCategories} />
                            </div> : <></>
                    }
                </>
                <div className="submit_post_editor_modal">
                    <FancyButton onClick={submitPost} text={edit ? "Save" : "Submit"} />
                    <div className='submit_post_editor_modal_toogle_button'>
                        <FancyButton onClick={() => updatePreviewMode(!previewMode)} text="Toggle Preview" />
                    </div>
                    {isModal ? <FancyButton onClick={() => updateModal(false)} text="Cancel" /> : null}
                </div>
            </div>
        </div >
    );
}

export default PostEditor;