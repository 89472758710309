import React from 'react';
import ArrowDropUpIcon from '@material-ui/icons/ThumbUp';
import ToolTip from "../Shared/ToolTip";

import "./UpvoteDisplay.css";

const UpvoteDisplay = ({ action, upvotes, userVoted, disabled }) => {
    return (<div className="upvote_display" onClick={disabled ? null : action}>
        <ToolTip
            div={<div className="upvote_display_text">
                {upvotes}
            </div>}
            Icon={ArrowDropUpIcon}
            text={disabled ? "Disabled" : userVoted ? "Downvote" : "Upvote"}
            size={20}
            color={disabled ? "grey" : userVoted ? "#E69344" : ""}
        />
    </div>);
}

export default UpvoteDisplay;