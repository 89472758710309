import React from 'react';
import Modal from 'react-modal';
import PostEditor from '../Shared/PostEditor/PostEditor';
import Draggable from 'react-draggable';
import internal_fetch from "../../util/local-api"
import { isAdmin, isModerator } from "../../util/misc"
import { useAlert } from 'react-alert'

const EditModal = ({ post, triggerUpdate, modalShown, updateModal, user, windowState }) => {

    const onSubmit = async ({ postTitle, postContents, postCategories, postVersionNumber, postStatus }) => {
        const response = await internal_fetch.edit_post(post.id, {
            title: postTitle,
            contents: postContents,
            categories: postCategories,
            version_number: postVersionNumber,
            status: postStatus
        });
        if (response.editPost) {
            alert.info(response.editPost);
            triggerUpdate();
            updateModal(false);
        }
        else if (response.errors)
            alert.error(response.errors)
    }
    const alert = useAlert()


    return (<Modal
        ariaHideApp={false}
        isOpen={modalShown}
        onRequestClose={() => updateModal(false)}
        style={{
            content: {
                width: "100%",
                height: "100%",
                paddingBottom: "0",
                filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#969392', endColorstr='#8a8685', GradientType=1) ",
                backgroundColor: "transparent",
                position: "unset",
                border: "none",
                borderRadius: 0,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            },
            overlay: {
                zIndex: "2",
                backgroundColor: "#000000ab",
                display: "flex",
                alignItems: "center"
            }
        }}
        contentLabel="Example Modal"
    >
        <Draggable bounds="parent" cancel='.post_main_content, .logo_close, .comment_buttons, .post_main_question, .submit_post_editor_modal'>
            <div className='commment_window_pop_up'>
                <PostEditor isModal={true}
                    windowState={windowState}
                    onSubmit={onSubmit}
                    defaultData={post}
                    isAdmin={user.isLoggedIn && isAdmin(user.data.role)}
                    isModerator={user.isLoggedIn && isModerator(user.data.role)}
                    status={true}
                    edit={true}
                    updateModal={updateModal} >
                    Please use English for all questions, answers and comments.
                </PostEditor>
            </div>

        </Draggable>
    </Modal>);
}

export default EditModal;