import React, { useState } from "react";
import FancyButton from "../Shared/FancyButton";
import LoginFieldText from "./LoginFieldText";
import internal_fetch from "../../util/local-api";

const ForgotPasswordBox = ({ updatePanelShown }) => {
  const [currentStatus, updateCurrentStatus] = useState("");
  const [currentEmail, updateCurrentEmail] = useState("");

  const send_reset_email = async () => {
    await internal_fetch.send_password_reset(currentEmail);
    updateCurrentStatus(
      "If that email exists, an email has been sent! Please follow the instructions in the email to reset your password."
    );
  };

  return (
    <div className="login_form">

      <LoginFieldText
        placeholder="email"
        currentValue={currentEmail}
        onChangeValue={updateCurrentEmail}
        type="text" />


      <FancyButton onClick={send_reset_email} text="Send Reset Email" />
      <div className="login_button_text" onClick={() => updatePanelShown(0)}>Login</div>

      {currentStatus ? <div className="status">{currentStatus}</div> : null}
    </div>
  );
};

export default ForgotPasswordBox;
