import React from 'react';
import PostPageComment from './PostPageComment';
import "./PostComments.css";

const PostCommentsList = ({ comments, user, post, postClosed, triggerUpdate, voteComment, mappedComments }) => {

    if (!comments || comments.length < 1) {
        return <div className="no_comments_found">No coments found</div>
    }
    return (
        <div className="">
            {comments.map((comment) => (
                <PostPageComment
                    comment={comment}
                    voteComment={voteComment}
                    userVoted={mappedComments[comment.id]}
                    key={comment.id}
                    isLoggedIn={user.isLoggedIn}
                    user={user}
                    triggerUpdate={triggerUpdate}
                    postAuthorId={post.author ? post.author.id : null}
                    postClosed={postClosed}
                />
            ))}
        </div>
    );
}

export default PostCommentsList;