import React, { useState, useEffect } from "react";
import ExpandableControl from "../../Shared/ExpandableControl";
import internal_fetch from "../../../util/local-api";
import FancyButton from "../../Shared/FancyButton";
import { useAlert } from 'react-alert';
import PostPageRichContent from "../../PostPage/PostPageRichContent";

const AdminBanner = ({ defaultExpanded }) => {
    const [fetchedBanner, updateBanner] = useState("");
    const [expanded, updateExpanded] = useState(false);

    const alert = useAlert()

    useEffect(() => {
        (async function iife() {
            const response = await internal_fetch.banner();
            if (response.banner)
                updateBanner(response.banner);
        })();
    }, []);

    const update_banner = async () => {
        const response = await internal_fetch.update_banner(
            fetchedBanner.trim()
        );
        if (response.updateBanner) alert.info("Updated");
        else if (response.errors)
            alert.error(response.errors)
    };


    // Function to resize textarea based on content
    const autoResize = () => {
        const textarea = document.getElementById('myTextarea');
        if (textarea) {
            textarea.style.height = 'auto'; // Reset the height to auto to recalculate the content height
            textarea.style.height = textarea.scrollHeight + 'px'; // Set the height to match the content
        }
    };

    // useEffect hook to resize textarea on component mount and value change
    useEffect(() => {
        autoResize();
    }, [fetchedBanner, expanded]);

    if (defaultExpanded) {
        return <ExpandableControl
            header="Welcome Banner"
            defaultExpanded={false}
            isExpanded={updateExpanded}
        >
            <div className="NewAdminBanner">
                <div className="admin_IP_details admin_section_details">
                    <textarea style={{ marginTop: "10px" }}
                        id="myTextarea" 
                        value={fetchedBanner}
                        onChange={e => updateBanner(e.target.value)}
                />
                    <div style={{ marginBottom: "30px", padding: "20px", border: "1px solid #ffffff63", borderRadius: "4px" }}>
                        <div className="post_panel_descriptor_p" style={{ height: "100%" }}>
                            <PostPageRichContent post={{ contents: fetchedBanner }} />
                        </div>
                    </div>
                <FancyButton
                    disabled={fetchedBanner === ""}
                    onClick={update_banner}
                    text="Save"
                />
            </div>
        </div>
        </ExpandableControl>
    }


    return (
        <ExpandableControl
            className="admin_IP"
            height="275px"
            header="Welcome Banner"
            defaultExpanded={defaultExpanded}
        >
            <div className="admin_IP_details admin_section_details">
                <label>Welcome Banner:</label>
                <textarea
                    value={fetchedBanner}
                    onChange={e => updateBanner(e.target.value)}
                />
                <FancyButton
                    disabled={fetchedBanner === ""}
                    onClick={update_banner}
                    text="Save"
                />
            </div>
        </ExpandableControl>
    );
};

export default AdminBanner;
