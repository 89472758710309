import React, { useState, useEffect } from "react";
import ExpandableControl from "../../Shared/ExpandableControl";
import internal_fetch from "../../../util/local-api";
import FancyButton from "../../Shared/FancyButton";
import { useAlert } from 'react-alert'
import NewTextAreaWithList from "../NewTextAreaWithList";

const AdminUsernames = ({ defaultExpanded }) => {
  const [fetchedUsernames, updateFetchedUsernames] = useState("");
  const alert = useAlert()

  useEffect(() => {
    (async function iife() {
      reload()
    })();
  }, []);

  const reload = async () => {
    updateFetchedUsernames("")

    const response = await internal_fetch.username_blacklist();
    if (response.usernameBlackList)
      updateFetchedUsernames(response.usernameBlackList.join("\n"));
  }

  const update_usernames = async (value) => {
    let data = value && (typeof (value) === 'string') ? value.trim().split("\n") : fetchedUsernames.trim().split("\n");
    const response = await internal_fetch.update_username_blacklist(data);
    if (response.updateUsernameBlackList) alert.info("updated");
    else if (response.errors)
      alert.error(response.errors)
  };

  const delete_usernames = async (value) => {
    const response = await internal_fetch.update_username_blacklist(
      value.trim().split("\n")
    );
    if (response.updateUsernameBlackList) alert.info("Updated");
    else if (response.errors)
      alert.error(response.errors)
  };

  if (defaultExpanded) {
    return <NewTextAreaWithList
      title={"Black listed usernames"}
      text={"Blacklisted Usernames:"}
      rowLabel={"Username"}
      fetchedData={fetchedUsernames}
      updateData={updateFetchedUsernames}
      reload={reload}
      updateDataHandler={update_usernames}
      deleteDataHandler={delete_usernames} />
  }

  return (
    <ExpandableControl
      className="admin_IP"
      height="275px"
      header="Username Settings"
      defaultExpanded={defaultExpanded}
    >
      <div className="admin_IP_details admin_section_details">
        <label>Blacklisted Usernames (Newline Seperated):</label>
        <textarea
          value={fetchedUsernames}
          onChange={e => updateFetchedUsernames(e.target.value)}
        />
        <FancyButton
          disabled={fetchedUsernames === ""}
          onClick={update_usernames}
          text="Save"
        />
      </div>
    </ExpandableControl>
  );
};

export default AdminUsernames;
