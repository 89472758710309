import React, { useState, useEffect } from "react";
import ExpandableControl from "../../Shared/ExpandableControl";
import internal_fetch from "../../../util/local-api";
import FancyButton from "../../Shared/FancyButton";
import { useAlert } from 'react-alert'
import NewTextAreaWithList from "../NewTextAreaWithList";

const AdminEmailWhitelist = ({ defaultExpanded }) => {
  const [fetchedEmails, updateEmails] = useState("");
  const alert = useAlert()

  useEffect(() => {
    (async function iife() {
      reload()
    })();
  }, []);

  const reload = async () => {
    updateEmails("");

    const response = await internal_fetch.get_emailWhitelist(true);
    if (response.whiteList)
      updateEmails(response.whiteList.join("\n"));
  }

  const update_emails = async (value) => {
    let data = value && (typeof (value) === 'string') ? value.trim().split("\n") : fetchedEmails.trim().split("\n");  
    const response = await internal_fetch.update_emailWhiteList(
      data
    );
    if (response.updateWhitelist) alert.info("Updated");
    else if (response.errors)
      alert.error(response.errors)
  };

  const delete_emails = async (value) => {
    const response = await internal_fetch.update_emailWhiteList(
      value.trim().split("\n")
    );
    if (response.updateWhitelist) alert.info("Updated");
    else if (response.errors)
      alert.error(response.errors)
  };

  if (defaultExpanded) {
    return <NewTextAreaWithList
      title={"Email White List"}
      text={"Enter a new email list to add to the closed beta:"}
      rowLabel={"Email"}
      fetchedData={fetchedEmails}
      updateData={updateEmails}
      reload={reload}
      updateDataHandler={update_emails}
      deleteDataHandler={delete_emails} />
  }

  return (
    <ExpandableControl
      className="admin_IP"
      height="275px"
      header="Closed beta whitelist"
      defaultExpanded={defaultExpanded}
    >
      <div className="admin_IP_details admin_section_details">
        <label>Email white list for closed beta. (Newline Seperated):</label>
        <textarea
          value={fetchedEmails}
          onChange={e => updateEmails(e.target.value)}
        />
        <FancyButton
          disabled={fetchedEmails === ""}
          onClick={update_emails}
          text="Save"
        />
      </div>
    </ExpandableControl>
  );
};

export default AdminEmailWhitelist;
