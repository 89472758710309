import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import { ThemeProvider } from '@material-ui/core/styles';
import { useStyles, darkTheme } from './NewUserControlsTable/styles';
import { EnhancedTableHead } from './NewUserControlsTable/EnchancedTableHead';
import { EnhancedTableToolbar } from './NewUserControlsTable/EnhancedTableToolbar';
import EditableTextCell from './NewUserControlsTable/EditableTextCell';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/SkipPrevious';
import LastPageIcon from '@material-ui/icons/SkipNext';
import PrevPageIcon from '@material-ui/icons/ChevronLeft';
import NextPageIcon from '@material-ui/icons/ChevronRight';
import internal_fetch from "../../util/local-api";
import { Tooltip } from '@material-ui/core';
import { truncateText } from '../../util/misc';
import EditableUserCell from './NewUserControlsTable/EditableUserCell';
import { useAlert } from 'react-alert'

const DarkTableCell = ({ children, ...props }) => {
    const classes = useStyles();
    return (
        <TableCell className={classes.tableCell} {...props}>
            {children}
        </TableCell>
    );
};

export default function EnhancedTable({ title = "Users List", sortEnabled = true }) {
    const classes = useStyles();
    const [searchTerm, setSearchTerm] = React.useState('');
    const [showSave, setSave] = React.useState(false);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');
    const [selected, setSelected] = React.useState([]);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [pagination, setPagination] = useState({ page: 0, pageSize: 10 });
    const [editingIndex, setEditingIndex] = useState(null);
    const [selectedUsername, setSelectedUsername] = useState('');
    const [updatedUser, setUpdatedUser] = useState({});

    const alert = useAlert()


    useEffect(() => {
        fetchData();
    }, [pagination, orderBy, order]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const selectedUser = data.find((user) => user.id === editingIndex)
        if (selectedUser && updatedUser.id !== selectedUser.id) {
            setUpdatedUser({ ...selectedUser })
        }
        else {
            setUpdatedUser({})
        }
    }, [editingIndex]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await internal_fetch.all_users(pagination.page, pagination.pageSize, searchTerm, orderBy, order);
            if (response.allUsers) {
                setData(response.allUsers.users);
                setTotalItems(response.allUsers.totalDocs);
                setTotalPages(response.allUsers.totalPages);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = (value) => {
        setSearchTerm(value);
        setPagination({ page: 0, pageSize: pagination.pageSize }); // Reset page when searching
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const handleClick = (event, id, name) => {
        const selectedIndex = selected.indexOf(id);

        if (selectedIndex === -1) {
            setSelected([id]);
            setSelectedUsername(name)
        } else {
            setSelected([])
            setSelectedUsername('')
        }
    };

    const permaBan = async () => {
        const user = data.find((user) => user.id === selected[0])
        const bannedUser = { ...user, banned_until: new Date("December 1, 42069 00:00:00").getTime().toString() }
        setUpdatedUser(bannedUser)
        updateUser(bannedUser, "Ban user until December 1, 42069")
    }

    const updateUser = async (user, disclaimer) => {
        if (window.confirm(disclaimer)) {
            const { id, ...rest } = user;
            delete rest.privateMessagesEnabled;
            const response = await internal_fetch.update_user(id, rest);
            if (response.updateUser) {
                alert.info(response.updateUser);
                setSelected([])
                setSelectedUsername('')
                setUpdatedUser({})
                setEditingIndex(null)
                setData([])
                fetchData()
            }
            else if (response.errors)
                alert.error(response.errors)
        }
    };

    const deleteUser = async (id) => {
        if (window.confirm("Really Delete user?")) {
            const response = await internal_fetch.delete_user(id);
            if (response.deleteUser) {
                alert.info(response.deleteUser);
                setSelected([])
                setSelectedUsername('')
                setUpdatedUser({})
                setEditingIndex(null)
                setData([])
                fetchData()
            }
            else if (response.errors)
                alert.error(response.errors)
        }
    };

    const handleSave = (value) => {
        setSave(false);

        if (value) {
            updateUser(updatedUser, "Update user with new details?");
        }
        else {
            setEditingIndex(null)
        }
    };

    const handleDelete = (value) => {
        const user = data.find((user) => user.id === selected[0])

        deleteUser(user.id)
    }

    const handleChangeRowsPerPage = (event) => {
        setPagination({ ...pagination, pageSize: parseInt(event.target.value, 10), page: 0 });
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    return (
        <ThemeProvider theme={darkTheme}>
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <EnhancedTableToolbar
                        username={selectedUsername}
                        itemsSelected={selected}
                        title={title}
                        onSearch={handleSearch}
                        handleDelete={handleDelete}
                        handleSave={handleSave}
                        showSave={showSave}
                        permaBan={permaBan}
                        setSelected={setSelected}
                    />
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead
                                classes={classes}
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={data.length}
                                sortEnabled={sortEnabled}
                            />
                            <TableBody>
                                {data.map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                hover
                                                onClick={(event) => handleClick(event, row.id, row.username)}
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.id}
                                                selected={isItemSelected}
                                            >
                                                <DarkTableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={isItemSelected}
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </DarkTableCell>
                                                <EditableTextCell
                                                    value={row.username}
                                                    showSave={row.id === editingIndex ? setSave : null}
                                                    startEdit={() => {
                                                        setSave(true);
                                                        setEditingIndex(row.id)
                                                    }}
                                                    onEdit={(newValue) => {
                                                        setUpdatedUser({ ...updatedUser, username: newValue })
                                                        setSave(true);
                                                    }}
                                                >
                                                    <Tooltip title={row.username}>
                                                        <span>{truncateText(row.username, 15)}</span>
                                                    </Tooltip>
                                                </EditableTextCell>
                                                <EditableTextCell
                                                    value={row.email}
                                                    showSave={row.id === editingIndex ? setSave : null}
                                                    startEdit={() => {
                                                        setSave(true);
                                                        setEditingIndex(row.id)
                                                    }}
                                                    onEdit={(newValue) => {
                                                        setUpdatedUser({ ...updatedUser, email: newValue })
                                                        setSave(true);
                                                    }}
                                                >
                                                    <Tooltip title={row.email}>
                                                        <span>{truncateText(row.email, 30)}</span>
                                                    </Tooltip>
                                                </EditableTextCell>
                                                <EditableTextCell
                                                    value={new Date(row.creation_date).toLocaleDateString()}
                                                    showSave={row.id === editingIndex ? setSave : null}
                                                    startEdit={() => {
                                                        setSave(true);
                                                        setEditingIndex(row.id)
                                                    }}
                                                    onEdit={(newValue) => {
                                                        setUpdatedUser({ ...updatedUser, creation_date: new Date(newValue).getTime() })
                                                        setSave(true);
                                                    }}
                                                />
                                                <EditableUserCell
                                                    value={row.role}
                                                    showSave={row.id === editingIndex ? setSave : null}
                                                    startEdit={() => {
                                                        setSave(true);
                                                        setEditingIndex(row.id)
                                                    }}
                                                    onEdit={(newValue) => {
                                                        setUpdatedUser({ ...updatedUser, role: newValue })
                                                        setSave(true);
                                                    }}>

                                                </EditableUserCell>

                                                <EditableTextCell
                                                    value={row.points}
                                                    showSave={row.id === editingIndex ? setSave : null}
                                                    startEdit={() => {
                                                        setSave(true);
                                                        setEditingIndex(row.id)
                                                    }}
                                                    onEdit={(newValue) => {
                                                        setUpdatedUser({ ...updatedUser, points: parseInt(newValue) })
                                                        setSave(true);
                                                    }}
                                                />
                                                <EditableTextCell
                                                    value={row.banned_until === '0' ? '0' : new Date(row.banned_until / 1).toLocaleDateString()}
                                                    showSave={row.id === editingIndex ? setSave : null}
                                                    startEdit={() => {
                                                        setSave(true);
                                                        setEditingIndex(row.id)
                                                    }}
                                                    onEdit={(newValue) => {
                                                        let newBannedDate = new Date(newValue).getTime().toString()
                                                        if (newValue === 0 || newValue === '0') {
                                                            newBannedDate = '0'
                                                        }
                                                        setUpdatedUser({ ...updatedUser, banned_until: newBannedDate })
                                                        setSave(true);
                                                    }}
                                                />
                                            </TableRow>
                                        );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={totalItems}
                        rowsPerPage={pagination.pageSize}
                        page={pagination.page}
                        onPageChange={() => { console.log("onPageChange") }}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelDisplayedRows={({ from, to, count }) => {
                            return `${pagination.page + 1} of ${totalPages} pages`;
                        }}
                        ActionsComponent={({ page }) => (
                            <div style={{ display: "flex" }}>
                                <IconButton onClick={() => setPagination({ page: 0, pageSize: pagination.pageSize })} disabled={loading || pagination.page === 0}>
                                    <FirstPageIcon />
                                </IconButton>
                                <IconButton onClick={() => setPagination({ page: pagination.page - 1, pageSize: pagination.pageSize })} disabled={loading || pagination.page === 0}>
                                    <PrevPageIcon />
                                </IconButton>
                                <IconButton onClick={() => setPagination({ page: pagination.page + 1, pageSize: pagination.pageSize })} disabled={loading || pagination.page === totalPages - 1}>
                                    <NextPageIcon />
                                </IconButton>
                                <IconButton onClick={() => setPagination({ page: Math.ceil(totalItems / pagination.pageSize) - 1, pageSize: pagination.pageSize })} disabled={loading || pagination.page === totalPages - 1}>
                                    <LastPageIcon />

                                </IconButton>
                            </div>
                        )}
                    />
                </Paper>
            </div>
        </ThemeProvider>
    );
}
