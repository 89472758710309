import React from 'react';
import Modal from 'react-modal';
import MessageEditor from './MessageEditor';
import Draggable from 'react-draggable';
import internal_fetch from "../../util/local-api"
import { useAlert } from 'react-alert'

const MessageModal = ({ message, triggerUpdate, modalShown, updateModal, username, windowState }) => {

    const onSubmit = async ({ messageTitle, messageBody, messageRecipient }) => {
        const response = await internal_fetch.send_message({
            title: messageTitle,
            body: messageBody,
            recipient: messageRecipient,
        });
        if (response.sendMessage) {
            alert.info(response.sendMessage)
            triggerUpdate();
            updateModal(false);
        }
        else if (response.errors)
            alert.error(response.errors)
    }
    const alert = useAlert()




    return (<Modal
        ariaHideApp={false}
        isOpen={modalShown}
        onRequestClose={() => updateModal(false)}
        style={{
            content: {
                width: "100%",
                height: "100%",
                paddingBottom: "0",
                filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#969392', endColorstr='#8a8685', GradientType=1) ",
                backgroundColor: "transparent",
                position: "unset",
                border: "none",
                borderRadius: 0,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            },
            overlay: {
                zIndex: "2",
                backgroundColor: "#000000ab",
            }
        }}
        contentLabel="Message Editor"
    >
        <Draggable bounds="parent" cancel='.post_main_content, .logo_close, .comment_buttons, .post_main_question'>
            <div className="commment_window_pop_up" >
                <MessageEditor windowState={windowState} onSubmit={onSubmit} defaultData={message} username={username} updateModal={updateModal} />
            </div>
        </Draggable>
    </Modal>);
}

export default MessageModal;