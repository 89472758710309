import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import UpvoteDisplay from "../Shared/UpvoteDisplay";
import ReactTimeAgo from 'react-time-ago'
import internal_fetch from "../../util/local-api";
import Categories from '../Shared/Categories';
import HealingIcon from '@material-ui/icons/Healing';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import DoneIcon from '@material-ui/icons/Done';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import BuildIcon from '@material-ui/icons/Build';
import LockIcon from '@material-ui/icons/Lock';
import ToolTip from "../Shared/ToolTip"
import PostDropdownMenu from "./PostDropdownMenu"
import "./Post.css";
import 'react-time-ago/Tooltip.css'
import { Tooltip } from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/Message';
import QuestionIcon from '@material-ui/icons/Help';
import { truncateText } from '../../util/misc';
import ReactHtmlParser from "react-html-parser";
import PinIcon from './../../assets/pin.svg';
import StarIcon from '@material-ui/icons/Star';

const options = {
    decodeEntities: true,
    transform: (node) => {
        // If the node is an anchor tag (a), replace it with plain text
        if (node.name === 'a') {
            return node.children.map(child => child.data).join('');
        }

        if (node.name === 'img') {
            return node.attribs.src;
        }
        // Return other nodes as is
        return undefined;
    }
};

const statusMap = function (status){
    switch (status) {
        case "in_progress": return BuildIcon
        case "solved": return DoneIcon
        case "rejected": return NotInterestedIcon
        case "closed": return LockIcon
        case "fixed_internally": return HealingIcon
        case "known_issue": return FolderSharedIcon
        case "open": return "div";
        default : {
            return QuestionIcon;
        }
    }
    
}

const Post = ({ post, user, voteOnPost, userLoggedIn, userVoted, hidePost }) => {
    const [isFetching, updateIsFetching] = useState(false);
    const [isFavourite, updateIsFavourite] = useState(false);

    useEffect(() => {
        if (user?.data?.favourites && post.id) {
            updateIsFavourite(user.data.favourites.map(p => p.id).includes(post.id))
        }
    }, [post.id, user]);

    const postHasBeenResolved = post.answered || ["solved", "fixed_internally"].includes(post.status)
    return (
        <div className={`${!postHasBeenResolved ? "post" : "post_answered"} ${post.admin_data ? post.admin_data.in_progress && !postHasBeenResolved ? "post_in_progress" : "" : ""} ${post.hidden ? "post_hidden" : ""} ${post.isPinned ? "post_pinned" : ""} `}>
            <div className="post_upvotes">
                <UpvoteDisplay post={post} disabled={!userLoggedIn || isFetching} upvotes={post.upvotes} userVoted={userVoted} action={async () => {
                    updateIsFetching(true);
                    const response = await internal_fetch.vote_post(post.id, userVoted);
                    updateIsFetching(false);
                    if (response && (response.upvotePost || response.downvotePost))
                        voteOnPost(response.upvotePost || response.downvotePost, userVoted);
                }} />
                <div className="posted_by"> {post.author ? <Link style={{ padding: "5px 0 15px" }} to={`/user/${post.author.username}`}> {truncateText(post.author.username, 15)} </Link> : <b>deleted</b>}</div>
            </div>
            <div className="post_contents">
                <div className="post_title">

                    {!["open"].includes(post.status) ? <ToolTip
                        Icon={statusMap(post.status)}
                        text={post.status}
                        color="rgb(230, 147, 68)"
                    /> : null}

                    <div className='post_title_div' style={{ width: "100%" }}>
                        <Link to={`/post/${post.id}`}>{post.title.length > 20 ? <Tooltip arrow placement="bottom-end" enterDelay={600} leaveDelay={200} title={post.title}><h1>{post.title}</h1></Tooltip> : <h1>{post.title}</h1>} </Link>
                    </div>
                    <div className='post_list_controls_div'>
                        <div className="date_time_by">
                            <ReactTimeAgo date={+post.creation_date} />
                        </div>
                        {userLoggedIn ?
                            <div className='controls_post_title'>
                                <PostDropdownMenu post={post} functions={{ hidePost }} />
                            </div> : null}
                    </div>
                </div>
                <div className="version_number">{post.version_number}</div>
                <div className="post_content_body"><div className="html_parser">{ReactHtmlParser(post.contents, options)}</div></div>
                <div style={{ paddingRight: "60px", marginTop: "8px" }}><Categories categories={post.categories} /></div>
            </div>
            <Link className="comments_number" style={{ textAlign: "center" }} to={`/post/${post.id}`}><span>{post.countComments}</span>
                <ArrowDropUpIcon style={{ fontSize: "20px", marginBottom: "-5px", marginLeft: "2px" }} />
                <StarIcon style={{ fontSize: "22px", marginLeft: "5px", marginBottom: "-3 px", display: isFavourite ? "unset" : "none" }} />
                <Tooltip arrow placement="bottom-end" enterDelay={600} leaveDelay={200} title="Pinned post"><img src={PinIcon} style={{ width: "17px", marginLeft: "6px", filter: "invert(1)", marginTop: "2px", display: post.isPinned ? "unset" : "none" }} alt="pin icon" /></Tooltip>
            </Link>
        </div>
    );
}

export default Post;