import { makeStyles, createTheme } from '@material-ui/core/styles';


export const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },

    highlight:
        theme.palette.type === 'light'
            ? {
                color: "yellow",
                backgroundColor: "red",
            }
            : {
                color: "white",
                backgroundColor: "#e69244ef",
            },
    title: {
        flex: '1 1 100%',
    },
    inputRoot: {
        flex: '1 1 100%',
        display: "flex",
        flexDirection: "row-reverse",
    },
    inputInput: {
        flex: '1 1 100%',
    },
    search: {
        flex: '1 1 100%',
        display: "flex",
        flexDirection: "row",
    },
}));

export const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        boxShadow: "6px 6px 4px -1px rgba(0, 0, 0, 0.4)",
        marginBottom: theme.spacing(2),
        backgroundColor: '#444', // Dark paper background color
    },
    table: {
        minWidth: 250,
    },
    tableHeader: {
        background: '#1f1f1f', // Dark header background color
        color: '#ffffff', // Text color for dark header
    },
    // Override the default table row styles
    tableRow: {
        '&:nth-child(even)': {
            background: '#333', // Dark alternate row background color
        },
    },
    // Override the default text color
    tableCell: {
        color: '#ffffff', // Text color for dark theme
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

export const darkTheme = createTheme({
    palette: {
        type: 'dark',
        background: {
            default: '#333', // Dark background color
        },
    },
    overrides: {
        MuiTableRow: {
            root: {
                '&.Mui-selected, &.Mui-selected:hover': {
                    background: '#e6934433', // Custom color for selected row
                    '& > .MuiTableCell-root': {
                        color: 'white !important', // Custom text color for selected row
                    },
                },
            },
        },
        MuiCheckbox: {
            root: {
                color: 'white !important', // Custom color for checkbox
                '&:hover': {
                    background: '#ffffff33 !important', // Custom background for checkbox hover
                },
            },
        },
    }
});