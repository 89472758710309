import React from 'react';
import logo_window from './../../assets/window_icon.svg'
import user_bitmap from './../../assets/user_bitmap.png'
import "./UserDetails.css";

const UserDetails = ({ user }) => {
    return (
        <div className="user_window_pop_up">
            <div className="comment_window_pop_up_title">
                <img className="logo_window" src={logo_window} alt="logo" />
                <div className='user_window_title_text'>
                    <p className='title_comment_pop_up' >USER STUFF</p>
                    <p className='title_comment_pop_up_date' style={{ paddingRight: "20px" }} >MEMBER SINCE: {new Date(+user.creation_date).toLocaleDateString()}</p>
                </div>
            </div>
            <div className='user_details_window'>
                <div className="user_details_window_data" >
                    <p>Username: <span>{user.username}</span></p>
                    <p>Email: <span>{user.email}</span></p>
                    <p>Points: <span>{user.points}</span></p>
                    <p>Upvotes received: <span>{user.upvotes_recieved}</span></p>
                    <p>Upvotes given: <span>{user.upvotes_given.length}</span></p>
                    <p>Comments: <span>{user.comments.length}</span></p>
                    <p>Posts: <span>{user.posts.length}</span></p>
                    <p>Answers: <span>{user.answers}</span></p>
                    <p>Role: <span>{user.role}</span></p>
                </div>
                <div class="parent-container">
                    <div class="flexible-child">
                        <img class="user_bitmap" src={user_bitmap} alt="user_image"></img>
                    </div>

                    <div class="fixed-height-child">
                        <div className='role_data'> <span>{user.role === "user" ? "Pioneer" : user.role}</span></div>

                    </div>
                </div>
            </div>
        </div>

    );
}

export default UserDetails;