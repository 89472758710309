import React, { useState, useEffect } from "react";
import ExpandableControl from "../../Shared/ExpandableControl";
import internal_fetch from "../../../util/local-api";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, ThemeProvider, createTheme } from '@material-ui/core';
import { useTable, useFilters, usePagination } from 'react-table'
import styled from 'styled-components'
import DefaultColumnFilter from "../DefaultColumnFilter";
import { Skeleton } from '@material-ui/lab';
import DateColumnFilter from "../DateColumnFilter";
import PaginationInput from "../StyledInput";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/SkipPrevious';
import LastPageIcon from '@material-ui/icons/SkipNext';
import PrevPageIcon from '@material-ui/icons/ChevronLeft';
import NextPageIcon from '@material-ui/icons/ChevronRight';

const AdminLogs = ({ defaultExpanded }) => {
    const [auditLogs, updateAuditLogs] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [pagination, setPagination] = useState({ page: 0, pageSize: 10 });
    const [filterType, setFilterType] = useState('');
    const [filterAction, setFilterAction] = useState('');
    const [filterAuthor, setFilterAuthor] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchData();
    }, [pagination, filterType, filterAction, filterAuthor, startDate, endDate]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await internal_fetch.all_audits(pagination.page, pagination.pageSize, filterType, filterAction, filterAuthor, startDate, endDate);
            if (response.allAudits) {
                setTotalItems(response.allAudits.totalCount)
                updateAuditLogs(response.allAudits.audits);
            }
            await new Promise(resolve => setTimeout(resolve, 100));
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (event, newPage) => {
        setPagination({ ...pagination, page: newPage });
    };

    const handleRowsPerPageChange = (event) => {
        setPagination({ ...pagination, pageSize: parseInt(event.target.value, 10), page: 0 });
    };

    const auditColumns = [
        {
            Header: 'ok',
            columns: [
                {
                    Header: 'Type',
                    accessor: 'action_type',
                    Filter: ({ column }) => (
                        <DefaultColumnFilter
                            filterValue={filterType}
                            columnId='action_type'
                            onFilterChange={setFilterType}
                        />
                    ),
                },
                {
                    Header: 'Action',
                    accessor: 'actioned_object',
                    Filter: ({ column }) => (
                        <DefaultColumnFilter
                            filterValue={filterAction}
                            columnId='actioned_object'
                            onFilterChange={setFilterAction}
                        />
                    ),
                    Cell: ({ row }) => (<div className="post_table_title">{row.original.actioned_object}</div>),
                },
                {
                    Header: 'Author',
                    accessor: row => row.author ? row.author.username : "deleted",
                    Filter: ({ column }) => (
                        <DefaultColumnFilter
                            filterValue={filterAuthor}
                            columnId='username'
                            onFilterChange={setFilterAuthor}
                        />
                    ),
                },
                {
                    Header: "Date",
                    accessor: row => new Date(row.creation_date).toLocaleDateString(),
                    Filter: ({ column }) => (
                        <DateColumnFilter
                            startDate={startDate}
                            endDate={endDate}
                            onStartDateChange={setStartDate}
                            onEndDateChange={setEndDate}
                        />
                    ),
                }
            ],
        }
    ];


    const darkTheme = createTheme({
        palette: {
            type: 'dark',
        },
    });

    return (
        <ExpandableControl
            className="admin_flags"
            header="Audit Log"
            defaultExpanded={defaultExpanded}
        >
            <ThemeProvider theme={darkTheme}>
                <TableContainer component={Paper}>
                    <Styles>
                        <TableComponent
                            columns={auditColumns}
                            data={auditLogs}
                            pagination={pagination}
                            loading={loading} />
                    </Styles>
                </TableContainer>
                <div>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={totalItems}
                        rowsPerPage={pagination.pageSize}
                        page={pagination.page}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={(event) => handleRowsPerPageChange(event)}
                        disabled={loading}
                        labelDisplayedRows={({ from, to, count }) => {
                            return `${pagination.page + 1} of ${Math.ceil(totalItems / pagination.pageSize)} pages`;
                        }}
                        ActionsComponent={({ page }) => (
                            <div style={{ display: "flex" }}>
                                <IconButton onClick={() => setPagination({ page: 0, pageSize: pagination.pageSize })} disabled={pagination.page === 0}>
                                    <FirstPageIcon />
                                </IconButton>
                                <IconButton onClick={() => setPagination({ page: pagination.page - 1, pageSize: pagination.pageSize })} disabled={pagination.page === 0}>
                                    <PrevPageIcon />
                                </IconButton>
                                <IconButton onClick={() => setPagination({ page: pagination.page + 1, pageSize: pagination.pageSize })} disabled={pagination.page === Math.ceil(totalItems / pagination.pageSize) - 1}>
                                    <NextPageIcon />
                                </IconButton>
                                <IconButton onClick={() => setPagination({ page: Math.ceil(totalItems / pagination.pageSize) - 1, pageSize: pagination.pageSize })} disabled={pagination.page === Math.ceil(totalItems / pagination.pageSize) - 1}>
                                    <LastPageIcon />

                                </IconButton>
                            </div>
                        )}
                    />
                    <PaginationInput totalPages={Math.ceil(totalItems / pagination.pageSize)} onPageChange={(page) => setPagination({ ...pagination, page })} />
                </div>
            </ThemeProvider>
        </ExpandableControl>
    );
};

export default AdminLogs;

const Styles = styled.div`
  padding: 1rem;
  overflow: auto;
  height: 100%;
  width: 100%;
`;

function TableComponent({ columns, data, loading, pagination, classes }) {
    const defaultColumn = React.useMemo(
        () => ({
            Filter: DefaultColumnFilter,
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page, // Use 'page' from useTable
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState: { pageIndex: pagination.page, pageSize: pagination.pageSize }, // Set initial page index and size
            manualPagination: true, // Enable manual pagination
            pageCount: Math.ceil(data.length / 10), // Calculate page count based on data length
            useControlledState: state => React.useMemo(() => ({
                ...state,
                pageIndex: pagination.page,
                pageSize: pagination.pageSize,
            }), [state, pagination]), // eslint-disable-line react-hooks/exhaustive-deps
        },
        useFilters,
        usePagination
    );

    return (
        <>
            <Typography variant="h6" id="tableTitle" component="div">
                Audit Logs
            </Typography>
            <Table {...getTableProps()} size="small">
                <TableHead>
                    {headerGroups.map((headerGroup, index) => (
                        index === 0 ?
                            <TableRow key='empty'></TableRow> : (
                                <TableRow {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <TableCell key={column.id}>
                                            <div className="pre_header">{column.render('Header')}</div>
                                            <div>{column.canFilter ? column.render('Filter') : null}</div>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            )
                    ))}
                </TableHead>
                <TableBody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <TableRow {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <TableCell key={cell.id} {...cell.getCellProps()}>
                                            {loading ? (
                                                <Skeleton animation="wave" height={20} width={"100%"} />
                                            ) : (
                                                    cell.render('Cell')
                                            )}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </>
    )
}

