import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const MinMaxInput = styled.input`
    width: 45px;
    margin-left: 5px;
    margin-right: 5px;
    background-color: #333;
    color: white;
    border: 1px solid #555;
    padding: 5px;
    border-radius: 5px;
    outline: none;
    /* Remove default appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    /* Style the calendar icon */
    &::-webkit-calendar-picker-indicator {
        filter: invert(1); /* Invert the color to white */
    }
`;

function MinMaxColumnFilter({
    minFilter,
    maxFilter,
    onminFilterChange,
    onmaxFilterChange,
    columnId,
}) {
    const [startValue, setStartValue] = useState(minFilter);
    const [endValue, setEndValue] = useState(maxFilter);

    const handleminFilterChange = (e) => {
        setStartValue(e.target.value);
    };
    const handlemaxFilterChange = (e) => {
        setEndValue(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            onminFilterChange(startValue);
            onmaxFilterChange(endValue);
        }
    };


    useEffect(() => {
        setStartValue(minFilter);
        setEndValue(maxFilter);
    }, [minFilter, maxFilter]);

    return (
        <>
            <MinMaxInput
                id={`${columnId}-min`}
                type="number"
                value={startValue}
                onChange={handleminFilterChange}
                onKeyDown={handleKeyDown}
                placeholder="Min"
            />
            to
            <MinMaxInput
                id={`${columnId}-max`}
                type="number"
                value={endValue}
                onChange={handlemaxFilterChange}
                onKeyDown={handleKeyDown}
                placeholder="Max"
            />
        </>
    );
}

export default MinMaxColumnFilter;
