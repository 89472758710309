import React, { useEffect } from 'react';
import "./Leaderboard.css";
import { Link } from 'react-router-dom';
const LeaderboardList = ({ data, highlightName, updateHighlightName }) => {

    useEffect(() => {

        if (highlightName) {
            const ele = document.getElementById("user_" + highlightName);
            if (ele)
                ele.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="leaderboard_list">
            <table>
                <colgroup>
                    <col span="1" style={{ width: "70%" }} />
                    <col span="1" style={{ width: "30%" }} />
                </colgroup>
                <tbody>
                    {data.map((u, i) => (
                        <tr id={"user_" + u.username.toLowerCase()} key={i} className={highlightName === u.username.toLowerCase() ? 'user_table_item_selected' : 'user_table_item'}
                            onClick={() => {
                                if (u.username.toLowerCase() === highlightName) {
                                    updateHighlightName("")
                                }
                                else {
                                    updateHighlightName(u.username.toLowerCase())
                                }
                            }}>
                            <td >
                                <Link to={`/user/${u.username}`}>{u.username}</Link>
                            </td>
                            <td>{u.points}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default LeaderboardList;