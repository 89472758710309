import React, { useState, useEffect } from 'react';
import FancyButton from '../Shared/FancyButton';
import EmailsTable from './CustomAdminTable';

const NewTextAreaWithList = ({ title, rowLabel, text, reload, fetchedData, updateData, updateDataHandler, deleteDataHandler, sortEnabled = true }) => {
    const [newList, setNewList] = useState('');


    const setNewItems = (event) => {
        updateData(`${newList}\n${fetchedData}`);
        updateDataHandler(`${newList}\n${fetchedData}`);
    };

    const deleteItems = (value) => {
        const emailsArray = fetchedData.split('\n');
        const resultArray = emailsArray.filter(email => !value.includes(email));
        const result = resultArray.join('\n');
        if (window.confirm(`Really delete ${value.length} items?`)) {
            updateData(result);
            deleteDataHandler(result);
        }
    };

    const saveItems = () => {
        updateDataHandler(fetchedData);
    }

    useEffect(() => {
        setNewList('');
    }, [fetchedData]);

    return (
        <div >
            <div className='NewTextAreaWithList' >
                <div className="admin_IP_details admin_section_details">
                    <div className='header_textarea'>
                        <label>{text}</label>
                        <label style={{ opacity: "0.7" }}> (Newline Seperated) </label>
                    </div>
                    <textarea
                        value={newList}
                        onChange={e => setNewList(e.target.value)}
                    />
                    <FancyButton
                        disabled={newList === ""}
                        onClick={setNewItems}
                        text="Save"
                    />
                </div>
                <EmailsTable
                    sortEnabled={sortEnabled}
                    title={title}
                    rowlabel={rowLabel}
                    rows={fetchedData.trim().split("\n").map((x, index) => Object({ id: x, order: index }))}
                    handleDelete={deleteItems}
                    saveItems={saveItems}
                    reload={reload}
                    updateDataHandler={updateData} // Pasamos la función de actualización de datos al componente hijo
                />
            </div>
        </div>
    );
};

export default NewTextAreaWithList;
