import React, { useState, useEffect } from "react";
import "./ExpandableControl.css";

const ExpandableControl = ({ className, header, height, children, defaultExpanded, isExpanded }) => {
  const [expanded, updateExpanded] = useState(false);

  useEffect(() => {
    if (defaultExpanded)
      updateExpanded(true);
  }, [defaultExpanded]);

  const toggleExpanded = (value) => {
    updateExpanded(value)
    if (isExpanded) {
      isExpanded(value)
    }
  }

  return (
    <div
      className={`control_block ${className ? className : ""}`}
      style={expanded ? { height } : { height: "60px" }}
    >
      <div className="header" style={defaultExpanded ? { display: "none" } : { display: "flex" }} onClick={() => {
        toggleExpanded(!expanded);
      }}>
        {header}
        <div
          className={!expanded ? "arrow" : "arrow arrow_flip"}
          onClick={() => {
            toggleExpanded(!expanded);
          }}
        >
          <div className="arrow-top"></div>
          <div className="arrow-bottom"></div>
        </div>
      </div>
      {expanded ? children : null}
    </div>
  );
};

export default ExpandableControl;
