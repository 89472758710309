import React from 'react';
import "./ResolvedPost.css"
import ReactTimeAgo from 'react-time-ago/commonjs/ReactTimeAgo';
import Categories from "../Shared/Categories";
import { NavLink as Link } from "react-router-dom"
import ToolTip from "../Shared/ToolTip"
import ArrowDropUpIcon from '@material-ui/icons/Message';
import { truncateText } from '../../util/misc';

const ResolvedPost = ({ post }) => {

    return <div className="resolved_post_answered">
        <div className="resolved_post_contents">
            <div className="resolved_post_title">
                {!["open"].includes(post.status) ? <ToolTip
                    Icon={"div"}
                    text={post.status}
                    color="rgb(230, 147, 68)"
                /> : null}
                <div className='post_title_div' style={{ width: "100%" }}>
                <Link to={`/post/${post.id}`}>{<h1>{post.title}</h1>} </Link>
                </div>
                <div className='resolved_post_list_controls_div'>
                    <div className="date_time_by"> Closed <ReactTimeAgo date={+post.lastEdit} />  </div>
                </div>
            </div>
            <div className="version_number">{post.version_number}</div>
            <div className="resolved_posted_by"> {post.author ? <Link to={`/user/${post.author.username}`}> {truncateText(post.author.username, 30)} </Link> : <b>deleted</b>}</div>
            <div style={{}}>
                <div className="resolved_post_content_body"> <p>{post.contents.replace(/(<([^>]+)>)/gi, "")}</p></div>
            </div>
            <Categories categories={post.categories} />
        </div>
        <div className='resolved_post_list_controls_div'>
            <div className="resolved_post">
                <h2><em>{post.status.replace("_", " ")}</em></h2>
            </div>
        </div>
        <Link className="comments_number" style={{ textAlign: "center" }} to={`/post/${post.id}`}><span>{post.countComments}</span> <ArrowDropUpIcon style={{ fontSize: "20px", marginBottom: "-5px" }} /></Link>
    </div>;
}

export default ResolvedPost;