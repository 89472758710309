// EnhancedTableToolbar.js
import React from 'react';
import clsx from 'clsx';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import SaveChangesIcon from '@material-ui/icons/Save';
import CancelChangesIcon from '@material-ui/icons/Cancel';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import { useToolbarStyles } from './styles';
import NuclearIcon from '@material-ui/icons/Security'
import VisibilityIcon from '@material-ui/icons/Visibility'


export const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { itemsSelected, title, onSearch, handleDelete, handleSave, showSave, permaBan, setSelected, username } = props;
    const [searchBarVisible, setSearchBarVisible] = React.useState(false);
    const numSelected = itemsSelected.length;

    const handleSearchChange = (event) => {
        onSearch(event.target.value);
    };

    const toggleSearchBar = () => {
        setSearchBarVisible(!searchBarVisible);
    };

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : searchBarVisible ? (
                <div className={classes.search}>
                    <Tooltip title="Search">
                        <IconButton aria-label="search">
                            <SearchIcon />
                        </IconButton>
                    </Tooltip>
                    <InputBase
                        placeholder="Search…"
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        inputProps={{ 'aria-label': 'search' }}
                        onChange={handleSearchChange}
                    />
                </div>
            ) : (
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    {title}
                </Typography>
            )}

            {numSelected > 0 ? (
                <>
                    {username ?
                        <Tooltip title="See user">
                            <IconButton aria-label="filter list" onClick={() => window.open(`/user/${username}`, '_blank')}>
                                <VisibilityIcon />
                            </IconButton>
                        </Tooltip> : <></>}
                    <Tooltip title="Delete">
                        <IconButton aria-label="delete" onClick={() => {
                            setSelected([]);
                            handleDelete(itemsSelected);
                        }}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Perma ban">
                        <IconButton aria-label="filter list" onClick={permaBan}>
                            <NuclearIcon />
                        </IconButton>
                    </Tooltip>
                    {showSave ? <></> : <Tooltip title="Discard selection">
                        <IconButton aria-label="filter list" onClick={() => setSelected([])}>
                            <CancelChangesIcon />
                        </IconButton>
                    </Tooltip>}
                </>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton aria-label="filter list" onClick={toggleSearchBar}>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )}

            {showSave ? (
                <>
                    <Tooltip title="Save changes">
                        <IconButton aria-label="filter list" onClick={() => handleSave(true)}>
                            <SaveChangesIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Discard changes">
                        <IconButton aria-label="filter list" onClick={() => handleSave(false)}>
                            <CancelChangesIcon />
                        </IconButton>
                    </Tooltip>
                </>
            ) : null}

        </Toolbar>
    );
};
