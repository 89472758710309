import React, { useState, useEffect } from 'react';
import Spinner from '../Shared/Spinner';
import Banner from "./Banner";
import PostControls from "./PostControls.js";

import ResolvedPosts from "./ResolvedPosts.js";
import UsePagination from './../PostList/Paginator.js';


const LatestResolved = ({ user, updateUser, windowState, functions: { updateLeftBar } }) => {
    const pathName = `paginator-last-resolved-list`;
    const value = sessionStorage.getItem(pathName) ? parseInt(sessionStorage.getItem(pathName)) : 1;

    const [posts, updatePosts] = useState([]);
    const [isFetching, updateIsFetching] = useState(false);
    const [totalPages, updateTotalPages] = useState(0);
    const [selectedPage, updateSelectedPage] = useState(value);

    //On Mount
    useEffect(() => { updateLeftBar(true) }, []); // eslint-disable-line react-hooks/exhaustive-deps
    //On Unmount
    useEffect(() => () => updateLeftBar(false), []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        sessionStorage.setItem(pathName, selectedPage)
    }, [selectedPage, pathName]); 

    return (
        <div className="post_list">
            <PostControls user={user} updateUser={updateUser} functions={{ updatePosts, updateIsFetching, updateTotalPages, updateSelectedPage }} selectedPage={selectedPage} isFetching={isFetching} windowState={windowState} />
            <Banner />
            {
                <>
                    {totalPages === 0 || totalPages === 1 || posts.length <= 0 ? <></> : <UsePagination pages={totalPages} selectedPage={selectedPage} functions={{ updateSelectedPage }} ></UsePagination>}
                    {!isFetching ? <ResolvedPosts posts={posts} /> : <Spinner />}
                    {totalPages === 0 || totalPages === 1 || posts.length <= 0 ? <></> : <UsePagination pages={totalPages} selectedPage={selectedPage} functions={{ updateSelectedPage }} ></UsePagination>}
                </>
            }

        </div>
    );
}

export default LatestResolved;