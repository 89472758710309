import React from "react";
import ReactHtmlParser from "react-html-parser";

export default function PostPageRichContent({ post, hideclass }) {
    const handleLink = text => {
        let result = text;
        const youtubeRegex = /<a[^>]+href=["'](https?:\/\/(?:www\.)?(?:youtube\.com\/(?:embed\/|watch\?v=)|youtu\.be\/)[^\s"']+)[^>]*>.*?<\/a>/gmi;
        const imgurRegex = /<a[^>]+href=["'](https?:\/\/(?:www\.)?imgur\.com\/[^/]+(?:\.(?:jpeg|jpg|gif|png))?)["'][^>]*?>(https?:\/\/(?:www\.)?imgur\.com\/[^/]+(?:\.(?:jpeg|jpg|gif|png))?)<\/a>/gmi;
        const linkRegex = /\[([^\]]+)\]\((https?:\/\/[^)]+)\)/gmi;

        // Replace YouTube links
        result = result.replace(youtubeRegex, (match, url) => {
            return `<iframe src="${url.replace("youtu.be/", "www.youtube.com/watch?v=").replace("watch?v=", "embed/").replace(/&[^&]+/gm, '')}" height="315" alt="Preview" > </iframe>`;
        });

        // Replace Imgur links
        result = result.replace(imgurRegex, (match, url) => {
            const link = url.split("\"")[0].includes('.jpeg') ? url.split("\"")[0] : url.split("\"")[0] + '.jpeg';
            return `<img src="${link}" height="315" alt="Preview" />`;
        });

        // Replace markdown links
        result = result.replace(linkRegex, (match, linkText, linkHref) => {
            return `<a href="${linkHref}" target="_blank">${linkText}</a>`;
        });

        return result;
    };

    const handlePlainLinks = text => {
        let result = text;
        const plainLinkRegex = /(https?:\/\/[^\s<"]+[^<">\s])/gm;

        // Replace plain links with anchor tags only if not inside an <a> tag
        result = result.replace(plainLinkRegex, (url) => {
            if (result.includes("](")) return url;
            if (result.includes(`<a href="${url}`)) return url; // If URL is already inside an <a> tag, don't replace
            if (result.includes(`src="${url}`)) return url; // If URL is already inside an <a> tag, don't replace
            if (result.includes(`>${url}`)) return url; // If URL is already inside an <a> tag, don't replace
            // if (url.includes("youtube") || url.includes("youtu")) { // if URL is a youtube video
            //     return `<iframe src="${url.replace("youtu.be/", "www.youtube.com/watch?v=").replace("watch?v=", "embed/").replace(/&[^&]+/gm, '')}" height="315" alt="Preview" > </iframe>`;
            // }
            if (url.includes("imgur") && !url.includes("/a/")) { // if URL is a imgur image but not an album
                return url.replace(/(https?:\/\/[^\s]+)/gm, (match, url, text) => {
                    const imgurLink = url.endsWith(".jpeg") || url.endsWith(".jpg") || url.endsWith(".gif") || url.endsWith(".png") ? url : url + ".jpeg";
                    return `<img src="${imgurLink}" height="315" alt="Preview" />`;
                }
                )
            }
            return `<a key="${url}" href="${url}" rel="noopener noreferrer" target="_blank">${url}</a>`;
        });


        return ReactHtmlParser(result);
    };

    const processedContent = handleLink(post.contents || post.comment || "");
    const renderedContent = handlePlainLinks(processedContent);

    return (
        <div className={hideclass ? "" : "post_main_body"}>
            {renderedContent}
        </div>
    );
}
